<template>
  <div class="page-wrapper">
    <div class="first-section">
      <div class="text">
        <h1 class="section-title">¿Qué es Nerix?</h1>
        <p>
          Nerix es una plataforma que concentra en un solo lugar todas las
          experiencias para personas que emprenden y buscan promover el espíritu
          emprendedor, creación de empresas y emprendimiento de base
          científico-tecnlógica. Estas experiencias provienen de todas las
          Escuelas Nacionales y áreas del Tecnológico de Monterrey, Región Monterrey.
        </p>
        <p>
          Encuentra experiencias según tu etapa de desarrollo de empresa, área
          de emprendimiento y necesidad específica.
        </p>
        <p>
          Si eres parte de la familia Tec e impulsas alguna experiencia
          de emprendimiento, esta plataforma te servirá para difundirla.
        </p>
        <div class="categories-container">
          <p class="bold">Encuentra oportunidades como:</p>
          <ul class="categories">
            <li>Concursos y premios</li>
            <li>Charlas y conferencias</li>
            <li>Fondos o programas de inversión</li>
            <li>Espacios físicos</li>
            <li>Networking</li>
            <li>Programas de aceleración o incubación</li>
            <li>Mentoreo o asesoría</li>
            <li>Servicios de backoffice</li>
            <li>Transferencia de tecnología</li>
            <li>Vinculación con industria</li>
            <li>Talleres</li>
            <li>Proyectos académicos de emprendimiento</li>
          </ul>
        </div>
        <p style="margin-top: 35px;">
          ¿Porqué el nombre de NERiX?
          La primer parte del nombre, NERi, es llamada así, debido a que Rodolfo Neri Vela un doctor mexicano en Electromagnetismo Aplicado, fue el primer astronauta mexicano en ir al espacio en su primera misión. Agregando una X al final, para referirnos a las experiencias de emprendimiento que la plataforma ofrece.
        </p>
        <p style="margin-bottom: -15px;">
          Con esa referencia, queremos tomar ese logro como inspiración para que ustedes como navegadores de la plataforma, sean los primeros en explorar las experiencias de emprendimiento que el ecosistema tiene para ustedes.
        </p>
      </div>
    </div>
    <div class="faq">
      <div class="text">
        <h2 class="faq-title">Preguntas frecuentes</h2>
        <h3 class="faq-subtitle">Resumen</h3>
        <div class="summary-links">
          <a href="/acerca-de-nerix/#pregunta1"
            >1. ¿Nerix pertenece al Tec de Monterrey?</a
          >
          <a href="/acerca-de-nerix/#pregunta2"
            >2. ¿Si no soy parte de la comunidad Tec puedo utilizar la plataforma?</a
          >
          <a href="/acerca-de-nerix/#pregunta3"
            >3. ¿Tengo que crear una cuenta de usuario para tener acceso a la plataforma?</a
          >
          <a href="/acerca-de-nerix/#pregunta4"
            >4. ¿Para qué se crea una cuenta de usuario en Nerix?</a
          >
          <a href="/acerca-de-nerix/#pregunta5"
            >5. ¿Tengo que usar mi cuenta de correo del Tec para crear una cuenta de usuario?</a
          >
          <a href="/acerca-de-nerix/#pregunta6"
            >6. ¿Puedo crear una cuenta de usuario si no tengo una cuenta de correo perteneciente al Tec?</a
          >
          <a href="/acerca-de-nerix/#pregunta7"
            >7. No he recibido el correo de confirmación de mi inicio de sesión</a
          >
          <a href="/acerca-de-nerix/#pregunta8"
            >8. ¿Qué requisitos necesita tener una experiencia de emprendimiento para poder registrarla en Nerix?</a
          >
          <a href="/acerca-de-nerix/#pregunta9"
            >9. ¿Qué es una experiencia?</a
          >
          <a href="/acerca-de-nerix/#pregunta10"
            >10. ¿Quién puede registrar una experiencia en Nerix?</a
          >
          <a href="/acerca-de-nerix/#pregunta11"
            >11. ¿Cómo puedo registrar una experiencia?</a
          >
          <a href="/acerca-de-nerix/#pregunta12"
            >12. ¿Puedo registrar más de una experiencia en la plataforma?</a
          >
          <a href="/acerca-de-nerix/#pregunta13"
            >13. ¿En cuanto tiempo puedo ver mi experiencia activa en la plataforma?</a
          >
          <a href="/acerca-de-nerix/#pregunta14"
            >14. ¿Puedo registrar una experiencia de corto plazo?</a
          >
          <a href="/acerca-de-nerix/#pregunta15"
            >15. ¿Por qué ya no puedo ver mi experiencia en la plataforma?</a
          >
        </div>
        <h3 id="pregunta1" class="question">
          <span>1. </span>¿Nerix pertenece al Tec de Monterrey?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Sí, Nerix es una iniciativa
          de las diferentes Escuelas y áreas del Tec que impulsan el
          emprendimiento.
        </p>
        <h3 id="pregunta2" class="question">
          <span>2. </span>
          ¿Si no soy parte de la comunidad Tec puedo utilizar la plataforma?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Sí, solo accede a través del
          perfil “Comunidad”
        </p>
        <h3 id="pregunta3" class="question">
          <span>3. </span>
          ¿Tengo que crear una cuenta de usuario para tener acceso a la
          plataforma?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>No es necesario hacer una
          cuenta de usuario para explorar las experiencias dentro de la
          plataforma. Para el registro de iniciativas si es necesario crear un
          usuario, para más detalle consulta la primera pregunta.
        </p>
        <h3 id="pregunta4" class="question">
          <span>4. </span>
          ¿Para qué se crea una cuenta de usuario en Nerix?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Las cuentas de usuario se
          crean únicamente para quienes son Líderes de Experiencia y desean
          registrar una experiencia.
        </p>
        <h3 id="pregunta5" class="question">
          <span>5. </span>
          ¿Tengo que usar mi cuenta de correo del Tec para crear una cuenta de
          usuario?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Sí, para poder crear una
          cuenta de usuario válida es necesario usar un correo electrónico del
          Tec.
        </p>
        <h3 id="pregunta6" class="question">
          <span>6. </span>
          ¿Puedo crear una cuenta de usuario si no tengo una cuenta de correo
          perteneciente al Tec?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>No, para poder crear una
          cuenta de usuario válida es necesario usar un correo electrónico del
          Tec.
        </p>
        <h3 id="pregunta7" class="question">
          <span>7. </span>
          No he recibido el correo de confirmación de mi inicio de sesión
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Si no has recibido el correo
          de confirmación: Revisa si la dirección de correo es correcta y revisa
          tu bandeja de correos no deseados. Si aún no recibes la confirmación,
          mándanos un correo con tu solicitud a nerix@servicios.tec.mx y lo
          revisaremos.
        </p>
        <h3 id="pregunta8" class="question">
          <span>8. </span>
          ¿Qué requisitos necesita tener una experiencia para poder registrarla
          en Nerix?
        </h3>
        <div class="answer">
          <span class="respuesta">Respuesta: </span>Puedes registrar cualquier
          experiencia que cumpla con los siguientes requisitos:
          <ul>
            <li>
              Las experiencia debe ser un acontecimiento periódico y/o que
              tengan seguimiento
            </li>
            <li>
              Debe tener la característica de ser un espacio que genere y/o
              promueva la innovación
            </li>
            <li>Usualmente tienen una página a la que la podemos ligar</li>
            <li>No pueden ser eventos de una sola ocasión</li>
          </ul>
        </div>
        <h3 id="pregunta9" class="question">
          <span>9. </span>
          ¿Qué es una experiencia?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Una experiencia emprendedora
          es toda aquella actividad que se genera con el objetivo de conectar a nuestras y
          nuestros emprendedores con diferentes recursos para lograr el
          desarrollo de su emprendimiento y de su perfil emprendedor. Las
          experiencias emprendedoras deben ser eventos liderados o co-liderados
          por colaboradores del Tec y se pueden enfocar en diferentes etapas y
          temáticas de emprendimiento sin importar su público. Pueden ser de
          cualquier extensión de tiempo, siempre y cuando estas experiencias sean
          o tengan planes de ser recurrentes y/o que cuenten con un seguimiento
        </p>
        <h3 id="pregunta10" class="question">
          <span>10. </span>
          ¿Quién puede registrar una experiencia en Nerix?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Necesitas subir tu propuesta
          por medio de este formulario y esperar a que el equipo de Nerix apruebe
          tu petición. Recibirás un correo de confirmación cuando este proceso
          finalice.
        </p>
        <h3 id="pregunta11" class="question">
          <span>11. </span>¿Cómo puedo registrar una experiencia?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Necesitas subir tu propuesta
          por medio de este formulario y esperar a que el equipo de Nerix apruebe
          tu petición. Recibirás un correo de confirmación cuando este proceso
          finalice.
        </p>
        <h3 id="pregunta12" class="question">
          <span>12. </span>
          ¿Puedo registrar más de una experiencia en la plataforma?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Sí, una vez que hayas hecho
          una cuenta de usuario puedes registrar más de una experiencia.
        </p>
        <h3 id="pregunta13" class="question">
          <span>13. </span>
          ¿En cuanto tiempo puedo ver mi experiencia activa en la plataforma?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Al momento de registrar tu
          experiencia esta debe ser aprobada por el equipo administrativo de la
          plataforma, si tu experiencia no ha sido aprobada escribenos a este
          correo nerix@servicios.tec.mx con tu nombre completo y el nombre de la
          experiencia.
        </p>
        <h3 id="pregunta14" class="question">
          <span>14. </span>
          ¿Puedo registrar una experiencia de corto plazo?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Dentro de los requisitos
          establecidos es necesario que los eventos sean periódicos y/o que
          tengan seguimiento. No pueden ser eventos de una sola ocasión.
        </p>
        <h3 id="pregunta15" class="question">
          <span>15. </span>
          ¿Por qué ya no puedo ver mi experiencia en la plataforma?
        </h3>
        <p class="answer">
          <span class="respuesta">Respuesta: </span>Si han pasado más de 6 meses
          y no ha habido actualización de la experiencia esta se considera de
          inactividad y se retira de la plataforma. Si quieres activarlo envía
          un correo a nerix@servicios.tec.mx
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.first-section {
  padding: 4rem;
  background-image: url(".././assets/noise.png"),
    linear-gradient(#b01cb8, #333399),
    linear-gradient(to top left, #333399, black);
  background-repeat: repeat;
  background-blend-mode: screen;
}

.first-section {
  color: white;
}

.first-section p {
  line-height: 1.5;
}

.text {
  max-width: 1300px;
  margin: 0 auto;
}

.section-title {
  font-size: 2.5rem;
}

.categories-container {
  margin-top: 40px;
  border: 1px solid white;
  padding: 1rem 2rem;
  border-radius: 30px;
}

.categories-container p {
  margin-bottom: 0px;
}

.categories {
  list-style: none;
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0;
  margin-top: 0px;
  list-style: disc;
  list-style-position: outside;
  margin-left: 15px;
}

.categories li {
  margin-top: 20px;
  margin-right: 15px;
}

.faq {
  padding: 4rem;
}

.faq-title {
  font-size: 2rem;
  color: #333399;
}

.summary-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.summary-links a {
  margin-bottom: 10px;
  color: #333399;
  font-weight: 500;
  line-height: 1.5;
}

.summary-links a:hover {
  transform: translateX(15px);
  transition: transform 0.2s ease-out;
}

.answer {
  font-style: italic;
  line-height: 1.5;
  margin-bottom: 50px;
}

.answer ul {
  margin-top: 5px;
}

.respuesta {
  font-weight: 800;
}

.bold {
  font-weight: 600;
}

.question {
  display: flex;
  align-items: flex-start;
}

.question span {
  color: #c31bcc;
  font-size: inherit;
  margin-right: 10px;
}

@media (max-width: 50rem) {
  .first-section,
  .faq {
    padding: 1.5rem;
  }

  .categories {
    grid-template-columns: 100%;
  }

  .summary-links a {
    margin-bottom: 20px;
  }
}
</style>