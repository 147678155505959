<template>
  <div class="footer-container">
    <div class="logos-container">
      <img
        class="logo-tec"
        src="../../assets/branding/logotecnegro.png"
        alt=""
      />
      <img
        class="logo-emprendimiento"
        src="..\..\assets\branding\Logo-Emprendimiento.png"
      />
    </div>
    <div class="emprendimiento-container">
      <h2 class="block-title">
        <span>EMPRENDIMIENTO</span>
      </h2>
      <div class="block-content">
        <ul class="ul-list">
          <li class="list-item">
            <a href="https://emprendimiento.tec.mx/es/inicio">Inicio</a>
          </li>
          <li class="list-item">
            <a href="https://nerix.com.mx">Experiencias y Eventos</a>
          </li>
          <li class="list-item">
            <a href="https://emprendimiento.tec.mx/es/contactanos"
              >¡Contáctanos!</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="academia-container">
      <h2 class="block-title">
        <span>ACADEMIA</span>
      </h2>
      <div class="block-content">
        <ul class="ul-list">
          <li class="list-item">
            <a href="https://tec.mx/es/negocios/licenciado-en-emprendimiento"
              >Licenciatura en Emprendimiento</a
            >
          </li>
          <li class="list-item">
            <a
              href="http://ieegl.itesm.mx/certificacion/"
              target="_blank"
              rel="noopener"
              >Certificación en Educación Emprendedora</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="investigacion-container">
      <h2 class="block-title">
        <span>INVESTIGACIÓN</span>
      </h2>
      <div class="block-content">
        <ul class="ul-list">
          <li class="list-item">
            <a
              href="https://emprendimiento.tec.mx/es/observatorio"
              target="_blank"
              rel="noopener"
              >Observatorio de Emprendimiento</a
            >
          </li>
          <li class="list-item">
            <a
              href="http://ieegl.itesm.mx/EIIE/memorias/"
              target="_blank"
              rel="noopener"
              >Encuentro de investigación en Emprendimiento</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="nerix-container">
      <h2 class="block-title">
        <span>NERIX</span>
      </h2>
      <div class="block-content">
        <ul class="ul-list">
          <li class="list-item">
            <router-link to="/">Inicio</router-link>
          </li>
          <li class="list-item">
            <router-link to="/acerca-de-nerix">¿Qué es Nerix?</router-link>
          </li>
          <li class="list-item">
            <a
              href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento"
              target="_blank"
              rel="noopener"
              >Aviso de privacidad</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- Logo nerix
        <div class="nerix-container">
            <img class="nerix-logo" src="../../assets/branding/NAVi_BW-01.png">
            <h4 class="nerix-slogan">Tu guía al emprendimiento</h4>
        </div>-->
    <!-- Aviso de privacidad viejo
        <ul>
            <a href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento" target="_blanck">
            <li>Aviso de privacidad</li>
            </a>
            <router-link to="/acerca-de-nerix">
            <li>¿Qué es Nerix?</li>
            </router-link>
            <router-link to="/">
            <li>Inicio</li>
            </router-link>
        </ul> -->
  </div>
</template>

<style scoped>
.footer-container {
  position: relative;
  box-shadow: -3px 1px 5px 3px rgb(27 26 26 / 20%);
  height: fit-content;
  z-index: -1;
  display: grid;
  grid-template-areas: 'logos nerix emprendimiento academia investigacion';
  grid-template-columns: 20% 20% 20% 20% 20%;
  /* align-items: center; */
  width: 100%;
  padding: 40px 80px;
}

a {
  color: #6e6e6e;
  text-decoration: none;
  background-color: transparent;
}

.ul-list {
  list-style: none;
  padding-left: 0;
}

ul,
li {
  color: inherit;
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  font-size: 16px;
  line-height: 1.6;
  text-indent: 0;
}
.emprendimiento-container {
  display: flex;
  align-items: top;
  flex-direction: column;
  grid-area: emprendimiento;
}

.academia-container {
  display: flex;
  align-items: top;
  flex-direction: column;
  grid-area: academia;
}

.investigacion-container {
  display: flex;
  align-items: top;
  flex-direction: column;
  grid-area: investigacion;
}

.nerix-container {
  display: flex;
  align-items: top;
  flex-direction: column;
  grid-area: nerix;
}

.logos-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  grid-area: logos;
}

.block .block-title {
  background: none;
  position: relative;
  margin: 0 0 10px;
  padding: 0 0 10px;
}

/* .block-title::after {
    content: '';
    width: 60px;
    height: 3px;
    background: #0039a6;
    color: #0039a6;
    bottom: 80;
    left: 80;
    position: absolute;
} */

.nerix-logo {
  height: 40px;
  margin-right: 0px;
}

.nerix-slogan {
  margin-top: 10px;
}

.logo-tec {
  height: 50px;
  margin-right: auto;
}

.logo-emprendimiento {
  height: 50px;
  margin-top: 10px;
  margin-right: auto;
}

/* ul {
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    list-style-position: outside;
    grid-area: tabs;
    padding: 0;
    margin-left: auto;
}

li {
    color: #919191;
    text-decoration: underline;
} */

@media (max-width: 1100px) {
  .footer-container {
    grid-template-areas:
      'logos emprendimiento'
      'academia investigacion'
      'nerix nerix';
    grid-template-columns: 50% 50%;
    justify-content: space-between;
    align-items: flex-start;
    display: grid;
  }
  ul {
    justify-content: flex-end;
    width: 100%;
  }

  li {
    margin-left: 30px;
  }
}

@media (max-width: 800px) {
  .footer-container {
    grid-template-areas:
      'logos emprendimiento'
      'academia investigacion'
      'nerix nerix';
    padding: 1rem 1rem;
  }

  .nerix-logo {
    height: 30px;
  }

  .nerix-slogan {
    display: none;
  }

  /* .logo-tec {
     height: 50px;
 }

 .logo-emprendimiento{
     height: 30px;
 } */

  ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px auto;
  }

  li {
    margin-top: 10px;
    margin-left: 0;
  }
}
</style>
