<template>
  <div class="form-wrapper">
    <button id="contact-button" v-if="!sendingStatus" @click="toggleForm">
      Contactar
    </button>

    <!-- <form action="" @submit.prevent="enviarMail" v-if="!isSending && !sendingStatus && false"> -->
    <form
      action=""
      @submit.prevent="enviarMail"
      v-if="!isSending && !sendingStatus"
    >
      <div class="field-wrapper" v-if="showForm">
        <label for="">Nombre Completo</label>

        <p class="mensaje-error" v-if="!isNombresValid">
          Asegurate de llenar ambos campos
        </p>
        <div class="full-name-fields">
          <input
            id="name"
            type="text"
            placeholder="Nombres"
            v-model="nombres"
          />
          <input
            id="lastname"
            type="text"
            placeholder="Apellidos"
            v-model="apellidos"
          />
        </div>

        <label for="">Correo electrónico</label>
        <p class="mensaje-error" v-if="!isCorreoValid">
          Asegurate de ingresar un correo válido
        </p>
        <input type="text" v-model="correo" />

        <label for="">Asunto</label>
        <p class="mensaje-error" v-if="!isAsuntoValid">
          Asegurate de ingresar un asunto
        </p>
        <input type="text" v-model="asunto" />

        <label for="">Mensaje</label>
        <p class="mensaje-error" v-if="!isMensajeValid">
          Asegurate de ingresar un mensaje
        </p>
        <textarea
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Escribe aquí tu mensaje"
          spellcheck="true"
          v-model="mensaje"
        ></textarea>

        <button type="submit">Enviar</button>
      </div>
    </form>

    <div class="mensaje-enviado" v-if="isSending">
      Enviando...
    </div>

    <div class="mensaje-enviado" v-if="!isSending && sendingStatus === 'ok'">
      <span class="material-icons">task_alt</span> Mensaje enviado con éxito
    </div>

    <div class="mensaje-enviado" v-if="!isSending && sendingStatus === 'error'">
      Hubo un error al intentar enviar el correo, inténtalo de nuevo más tarde
    </div>
  </div>
</template>

<script>
// import { useGtm } from "vue-gtm";

export default {
  props: ['contacto', 'iniciativa'],
  data() {
    return {
      showForm: false,
      nombres: '',
      apellidos: '',
      correo: '',
      asunto: '',
      mensaje: '',

      isFormValid: true,
      isNombresValid: true,
      isAsuntoValid: true,
      isMensajeValid: true,
      isCorreoValid: true,

      isSending: false,
      sendingStatus: null,
    };
  },

  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    triggerEvent() {
      this.$gtag.event('contactar', {
        event_category: 'contacto',
        event_label: `se contactó la experiencia: ${this.iniciativa}`,
      });
      this.showForm = !this.showForm;
    },
    validarForm() {
      if (this.nombres === '' || this.apellidos === '') {
        this.isNombresValid = false;
        this.isFormValid = false;
      } else {
        this.isNombresValid = true;
      }

      if (this.asunto === '') {
        this.isAsuntoValid = false;
        this.isFormValid = false;
      } else {
        this.isAsuntoValid = true;
      }

      if (this.mensaje === '') {
        this.isMensajeValid = false;
        this.isFormValid = false;
      } else {
        this.isMensajeValid = true;
      }

      this.correo = this.correo.toLowerCase();
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.correo)) {
        this.isCorreoValid = false;
        this.isFormValid = false;
      } else {
        this.isCorreoValid = true;
      }

      if (
        this.isNombresValid &&
        this.isAsuntoValid &&
        this.isMensajeValid &&
        this.isCorreoValid
      ) {
        this.isFormValid = true;
      }
    },
    enviarMail() {
      this.isSending = true;

      this.validarForm();

      if (!this.isFormValid) {
        this.isSending = false;
        return;
      }

      fetch(process.env.VUE_APP_API_ENDPOINT + '/contact', {
        method: 'POST',
        body: JSON.stringify({
          nombres: this.nombres,
          apellidos: this.apellidos,
          correo: this.correo,
          asunto: this.asunto,
          mensaje: this.mensaje,
          contacto: this.contacto,
          iniciativa: this.iniciativa,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => res.json())
        .then(response => {
          this.isSending = false;
          if (response.ok) {
            this.triggerEvent();
            this.sendingStatus = 'ok';
          } else {
            this.sendingStatus = 'error';
          }
        });
    },
  },
};
</script>

<style scoped>
.mensaje-error {
  margin-bottom: 5px;
  color: red;
}

.mensaje-enviado {
  text-align: center;
  margin: 6rem 0;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mensaje-enviado span {
  font-size: 1.6rem;
  margin-right: 10px;
  color: #c31bcc;
}

.form-wrapper {
  margin: 1rem 4rem;
  text-align: left;
}

button {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(195, 27, 204, 1) 0%,
    rgba(51, 51, 153, 1) 100%
  );
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

button:hover,
button:active {
  color: white;
  background: #c31bcc;
  cursor: pointer;
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.field-wrapper label {
  font-size: 1rem;
  font-weight: 500;
}

.field-wrapper input,
.field-wrapper textarea {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border: none;
  border-radius: 8px;
  padding: 0.7rem;
}

.field-wrapper input:focus,
.field-wrapper textarea:focus {
  outline: none;
}

.full-name-fields {
  display: flex;
  justify-content: space-between;
}

#name,
#lastname {
  width: 45%;
}

#message {
  min-height: 200px;
}

textarea {
  resize: none;
}

@media (max-width: 50rem) {
  .form-wrapper {
    margin: 1.2rem;
  }
}
</style>
