<template>
  <div class="privacy-page-wrapper">
    <div class="container-fluid fourth-l-header wow fadeIn">
      <div class="container">
        <div class="title">
          <div class="sg-pattern title_container">
            <h1>
              Aviso de Privacidad Plataforma Nerix
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="field_paragraph--cuarto_nivel">
        <!-- columnas -->

        <div class="columnas_texto">
          <div class="columnas_texto__row">
            <div class="columnas_texto__columna">
              <p><b>Identidad y domicilio del Responsable</b></p>

              <p>
                El Responsable del tratamiento de los datos personales que usted
                proporcione, es el Instituto Tecnológico y de Estudios
                Superiores de Monterrey (en lo sucesivo "ITESM") con domicilio
                ubicado en Av. Eugenio Garza Sada Sur No. 2501, colonia
                Tecnológico en Monterrey, Nuevo León. C.P. 64849.
              </p>

              <p>
                Para cualquier duda relacionada con la protección de sus datos
                personales podrá contactarnos en la dirección de correo
                electrónico
                <a href="mailto:datospersonales@itesm.mx"
                  >datospersonales@itesm.mx</a
                >.
              </p>

              <p>
                <b
                  >Datos personales y datos personales sensibles tratados por el
                  ITESM</b
                >
              </p>

              <p>
                Para cumplir con las finalidades del tratamiento señaladas en el
                presente Aviso de Privacidad, es necesario que el ITESM trate
                las siguientes categorías de datos personales.<br />
                - Datos de identificación, incluyendo su imagen personal;<br />
                - Datos de autenticación;<br />
                - Datos de contacto;<br />
                - Datos de ubicación;<br />
                - Datos relacionados a su experiencia y ocupación actual;<br />
                - Datos laborales;<br />
                - Datos relacionados con el proyecto con el que Usted se
                postula;<br />
                - Datos relacionados a la actividad económica que realiza, así
                como aquellos relacionados con el impacto del proyecto;<br />
                - Datos patrimoniales y/o financieros, y<br />
                - Datos de contacto de terceros.<br />
                <br />
                Le informamos que podremos obtener datos personales de terceros
                en caso de que usted nos proporcione esta información. Esta
                información podrá ser tratada para cumplir con las finalidades
                previstas en el presente aviso de privacidad por lo que al
                proporcionarnos esta información, usted reconoce haber informado
                a dichos terceros sobre el uso de sus datos y haber obtenido de
                forma previa el consentimiento de éstos para que el ITESM pueda
                utilizar los datos.
              </p>

              <p>
                Asimismo, le informamos que, para cumplir con las finalidades
                señaladas en el presente Aviso, ITESM no recabará ni tratará
                datos personales sensibles.
              </p>

              <p><b>Finalidades primarias</b></p>

              <p>
                El ITESM podrá tratar sus datos personales para cumplir con las
                siguientes finalidades primarias:
              </p>

              <ul>
                <li>
                  Para atender, registrar y dar seguimiento a la(s)
                  solicitud(es) de información que realice, independientemente
                  de si es Usted o no miembro de la comunidad de emprendimiento
                  del Instituto.
                </li>
                <li>
                  Cuando nos lo solicite, para registrarle como miembro de la
                  comunidad de emprendimiento del Instituto de Emprendimiento y
                  mantenerle informado sobre las actividades, convocatorias,
                  eventos, redes, programas, concursos, premios, cursos,
                  talleres, independientemente de la denominación que se les
                  asigne, que realiza, organiza o colabora el Instituto.
                </li>
                <li>
                  Para atender, registrar y dar seguimiento a las quejas que en
                  su caso realice.
                </li>
                <li>
                  En caso de que aplique, para gestionar y administrar el acceso
                  electrónico a los sistemas e infraestructura tecnológica del
                  ITESM, así como para atender, registrar y dar seguimiento a
                  los reportes y/o solicitudes de apoyo que realice con motivo
                  del uso del sistema e infraestructura tecnológica.
                </li>
                <li>
                  Para compartir su información a la entidad del Gobierno
                  Federal, Estatal o Municipal y/o organismo intermediario, en
                  caso de que el programa, convocatoria, evento o actividad en
                  el que participe reciba apoyo económico proveniente de estos
                  para fines de comprobación del cumplimiento de las
                  obligaciones contraídas.
                </li>
                <li>
                  Cuando se registra, postula y/o es elegido para participar en
                  algún programa, convocatoria, evento, actividad, taller,
                  curso, certificación, independientemente del nombre con el que
                  se identifique.
                </li>
                <li>
                  Para atender, registrar y dar seguimiento a su solicitud de
                  participación, así como mantenerle informado de cualquier
                  información y para conocer su opinión respecto a la atención
                  recibida y a su participación.
                </li>
                <li>
                  Para dar cumplimiento y seguimiento a los Términos y
                  Condiciones establecidos y validar la veracidad y la calidad
                  de la información que Usted proporcione.
                </li>
                <li>
                  Para gestionar, en caso necesario, la elaboración y firma de
                  los documentos necesarios para la formalización de su
                  participación.
                </li>
                <li>
                  En caso de que aplique, para proporcionarle y gestionar los
                  servicios y beneficios establecidos según el programa,
                  convocatoria, evento o actividad en la que participe.
                </li>
                <li>
                  En caso de que aplique, para la elaboración y entrega de la
                  constancia que acredita su participación.
                </li>
                <li>
                  Para confirmar su asistencia al los eventos o actividades en
                  las que participe.
                </li>
                <li>Para gestionar acceso físico a las instalaciones.</li>
                <li>
                  Para identificarle, cuando los eventos o actividades en las
                  que participe, sean transmitidos en vivo (streaming) a través
                  de redes sociales, herramientas digitales y plataformas que
                  así lo permitan, incluidos aquellos que permiten su
                  reproducción posterior.
                </li>
                <li>
                  Para las gestiones de facturación y procesos de cobranza
                  correspondientes y, en los casos que lo requiera, para enviar
                  la base de datos al buró de crédito en caso de que Usted
                  incumpla con la obligación de pago derivado de la relación
                  jurídica, en cuyo caso, el uso de su información se regirá de
                  conformidad con lo establecido por la Ley para Regular las
                  Sociedades de Información Crediticia.
                </li>
                <li>
                  Para realizarle estudios socioeconómicos en el supuesto de
                  solicitar apoyos económicos, becas y/o préstamos.
                </li>
                <li>
                  Para vincularlo con patrocinadores o posibles socios que
                  apoyen la consolidación de su proyecto, cuando aplique.
                </li>
                <li>
                  Para utilizar su imagen personal (ya sea en formato de
                  fotografía, video o cualquier otro medio similar) para hacer
                  publicaciones internas y externas sobre su participación en
                  eventos o actividades, así como promocionar al ITESM.
                </li>
              </ul>

              <p><b>Finalidades secundarias</b></p>

              <p>
                En caso de que usted lo consienta, utilizaremos sus datos
                personales para las siguientes finalidades:
              </p>

              <ul>
                <li>
                  Para invitarle a participar en encuestas, eventos, rifas,
                  trivias y concursos.
                </li>
                <li>
                  Para realizar reportes estadísticos y estudios de
                  investigación, previa aplicación de un mecanismo de
                  disociación de los datos personales.
                </li>
                <li>
                  Para contactarle por cualquier medio físico y electrónico con
                  la finalidad de enviarle información de carácter promocional
                  propia y de terceros que pueda resultar de su interés.
                </li>
              </ul>

              <p>
                Las anteriores finalidades secundarias tienen como base de
                legitimación su consentimiento. Lo anterior quiere decir que en
                cualquier momento puede oponerse a estas, o bien revocar su
                consentimiento.
              </p>

              <p>
                En caso de que no desee que sus datos personales sean tratados
                para alguna o todas las finalidades adicionales desde este
                momento nos puede comunicar lo anterior al correo electrónico
                <a href="mailto:datospersonales@itesm.mx"
                  >datospersonales@itesm.mx</a
                >.
              </p>

              <p><b>Transferencias</b></p>

              <p>
                Con base en lo establecido en el artículo 37 de la LFPDPPP, el
                ITESM podrá transferir sus datos personales sin requerir de su
                consentimiento en los siguientes supuestos:<br />
                <br />
                - A sociedades controladoras, subsidiarias o afiliadas bajo el
                control común del responsable, o a una sociedad matriz o
                cualquier sociedad del mismo grupo del responsable que opere
                bajo los mismos procesos y políticas internas;<br />
                - Autoridades competentes en los casos previstos por la
                normatividad aplicable, en el caso que recibiéramos un
                requerimiento de obligado cumplimiento.<br />
                - Cuando sea precisa para el mantenimiento o cumplimiento de la
                relación jurídica entre Usted y el ITESM, como lo es el caso de:
              </p>

              <p>&nbsp;</p>

               <div class="table-container">
              <table
                class="table"
                align="center"
              >
                <tbody>
                  <tr>
                    <th class="text-align-center">
                      Tercero receptor de los datos personales
                    </th>
                    <th class="text-align-center">Finalidad</th>
                    <th class="text-align-center">Consentimiento</th>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Aseguradoras, Agencias de Seguros y/o empresas, nacionales
                      o extranjeras
                    </td>
                    <td class="text-align-center">
                      Para realizar las gestiones necesarias para la
                      contratación y cancelación de seguros y/o reembolsos
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Personas físicas o morales, nacionales o extranjeras, que
                      se dedican a la gestión de Viajes
                    </td>
                    <td class="text-align-center">
                      Para gestionar la transportación y hospedaje cuando
                      participe en actividades que lo requieran
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Personas físicas o morales, nacionales o extranjeras, que
                      se dedican a la transportación de personas
                    </td>
                    <td class="text-align-center">
                      Para gestionar la transportación cuando participe en
                      actividades que lo requieran
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Personas Físicas o morales, nacionales o extranjeras, que
                      se dedican a ofrecer servicios de hospedaje
                    </td>
                    <td class="text-align-center">
                      Para gestionar su hospedaje cuando participe en
                      actividades del Programa que lo requieran
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Instituciones educativas o no educativas nacionales o
                      extranjeras
                    </td>
                    <td class="text-align-center">
                      Para gestionar su participación en cursos,
                      certificaciones, diplomados o programas de capacitación
                      cuando participe en actividades que lo requieran
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                  <tr>
                    <td class="text-align-center">
                      Entidades de Gobierno Federal, Estatal o Municipal y/o
                      Organismos intermediarios
                    </td>
                    <td class="text-align-center">
                      Para fines de comprobación del cumplimiento de las
                      obligaciones contraídas
                    </td>
                    <td class="text-align-center">No necesario</td>
                  </tr>
                </tbody>
              </table>
              </div> 

              <p>&nbsp;</p>

              <p>
                Si usted no desea que ITESM transfiera sus datos personales para
                aquellas transferencias para las cuales es necesario su
                consentimiento le pedimos que envíe un correo electrónico a la
                dirección
                <a href="mailto:datospersonales@itesm.mx"
                  >datospersonales@itesm.mx</a
                >.
              </p>

              <p><b>Derechos ARCO y/o revocación del consentimiento</b></p>

              <p>
                Usted o su representante legal podrá ejercer cualquiera de los
                derechos de acceso, rectificación, cancelación u oposición (en
                lo sucesivo “derechos arco”), así como revocar su consentimiento
                para el tratamiento de sus datos personales a través del correo
                electrónico y siguiendo el procedimiento, requisitos y plazos
                para el ejercicio de sus derechos ARCO y/o revocación del
                consentimiento de nuestra página de internet:
                <a
                  href="https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento"
                  >https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento</a
                >.
              </p>

              <p>
                Es importante que tenga en cuenta que no en todos los casos
                podremos atender su solicitud o concluir el uso de forma
                inmediata, ya que es posible que por alguna obligación legal
                requeríamos seguir tratando sus datos personales.
              </p>

              <p>
                <b
                  >Opciones y medios para limitar el uso o divulgación de sus
                  Datos Personales</b
                >
              </p>

              <p>
                Usted podrá limitar el uso o divulgación de sus datos personales
                en los eventos o actividades que se llevan a cabo a través de
                herramientas y plataformas digitales, no activando la cámara, no
                utilizando su imagen personal en la cuenta con la que se conecte
                y/o no registrándose con su perfil (dependerá de la plataforma
                que se utilice para llevar a cabo el evento o actividad).
              </p>

              <p>
                En los demás casos, Usted podrá limitar el uso o divulgación de
                sus datos personales enviando su solicitud a
                <a href="mailto:datospersonales@itesm.mx"
                  >datospersonales@itesm.mx</a
                >.
              </p>

              <p>
                Los requisitos para acreditar su identidad, así como
                procedimiento para atender su solicitud se regirán por los
                mismos criterios señalados en nuestra página de internet
                <a
                  href="https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento"
                  >https://tec.mx/es/derechos-arco-yo-revocacion-del-consentimiento</a
                >. En caso de que resulte procedente, se le registrará en el
                listado de exclusión propio de ITESM.
              </p>

              <p><b>Uso de Cookies</b></p>

              <p>
                El ITESM utiliza varias tecnologías para mejorar la eficiencia
                del la Plataforma incluyendo su experiencia cuando navega por el
                sitio. Las cookies son pequeñas cantidades de información que se
                almacenan en el navegador utilizado por cada usuario para que el
                servidor recuerde cierta información que posteriormente pueda
                utilizar. Esta información permite identificarle y guardar sus
                preferencias personales para brindarle una mejor experiencia de
                navegación.
              </p>

              <p>
                Le recordamos que usted puede desactivar, deshabilitar o ajustar
                el uso de cookies y otras tecnologías siguiendo los
                procedimientos del navegador de internet que utiliza.
              </p>

              <ul>
                <li>
                  Seleccione la opción de “configuración” o herramientas de
                  acuerdo con su explorador.
                </li>
                <li>Seleccione la opción desactivar cookies.</li>
                <li>
                  Este proceso no solo deshabilitará las cookies de nuestra
                  Plataforma, sino las de todo su explorador.
                </li>
              </ul>

              <p>
                Los datos personales que se pueden obtener a través del uso de
                estas tecnologías son los siguientes: Identificadores, nombre de
                usuario y contraseñas de una sesión; región en la que se
                encuentra; tipo de navegador; tipo de sistema operativo; fecha y
                hora del inicio y final de una sesión; páginas web visitadas;
                búsquedas realizadas y publicidad revisada. Estas tecnologías
                podrán deshabilitarse siguiendo los procedimientos del navegador
                de internet que utiliza.
              </p>

              <p><b>Cambios al Aviso de Privacidad</b></p>

              <p>
                El ITESM se reserva el derecho, bajo su exclusiva discreción, de
                cambiar, modificar, agregar o eliminar partes del presente Aviso
                de Privacidad en cualquier momento. Derivado de novedades
                legislativas, políticas internas o nuevos requerimientos para la
                prestación u ofrecimiento de nuestros servicios, el presente
                Aviso de Privacidad estará sujeto a modificaciones o
                actualizaciones. En tal caso, el ITESM le informará de los
                cambios mediante comunicado en la página oficial de internet:
                <a
                  href="https://tec.mx/es/aviso-privacidad-instituto-emprendimiento"
                  >https://tec.mx/es/aviso-privacidad-instituto-emprendimiento</a
                >.
              </p>

              <p align="right">Fecha de última actualización: Octubre 2020</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Footer from "../components/layout/Footer.vue";

export default {
  components: {
    Footer,
  },
};
</script>


<style scoped>
.privacy-page-wrapper {
  padding: 4rem;
  max-width: 1300px;
  margin: 0 auto;
  line-height: 1.5;
}

.table {
    width: 80%;
}

th {
    font-weight: 600;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

 td {
    padding: 15px;
    border-bottom: 1px solid #ddd;

}

h1 {
    font-size: 2.5rem;
    color: #333399;
}

a {
    color: #333399;
}

@media (max-width: 30rem) {
  .privacy-page-wrapper {
  padding: 2rem 1rem;
  max-width: 100%;
  overflow-y: visible;
}

.table {
    width: 100%;
    margin: 0 auto;
}

.table-container {
   overflow-x: scroll;
}

}

</style>