// import axios from 'axios';

export default {
    userId(state) {
      return state.userId;
    },
    token(state) {
      return state.token;
    },
    // isAuthenticated(state) {
      

    //   // if(state.userId && state.token) {
        
    //   // } else {
    //   //   return false
    //   // }
    // },
    didAutoLogout(state) {
      return state.didAutoLogout;
    },
    userName(state) {
      return state.name + ' ' + state.lastname;
    },
  };
  