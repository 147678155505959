<template>
  <div class="page-container">
    <h2>Constelaciones de Valor</h2>
    <p>
      Las constelaciones de valor tienen cómo objetivo generar una comunidad de
      innovación conectada, en la que los líderes de las experiencias de
      emprendimiento e innovación del Tec de Monterrey Región Monterrey se
      enlacen entre sí, partiendo de sus liderazgos e iniciativas y trabajen en
      conjunto, con objetivos y propósitos en común, dando inicio a la
      habilitación de un distrito de innovación.
    </p>
    <p>
      Al formar parte de la constelación tendrás acceso a los siguientes
      beneficios ofertados por el Instituto de Emprendimiento Eugenio Garza
      Lagüera:
    </p>
    <div class="keywords-wrapper">
      <ul class="keywords">
        <li>Apoyo en búsqueda de mentores</li>
        <li>Apoyo en búsqueda de evaluadores</li>
        <li>Apoyo en el diseño de experiencias</li>
        <li>Apoyo en difusión</li>
        <li>Carta para clasificación Docente</li>
        <li>Reconocimiento en SuccessFactors por colaboración</li>
      </ul>
    </div>

    <div class="cards-container"></div>

    <div class="constelation-wrapper">
      <h2 class="constelation-title">Big Bang</h2>
      <p class="constelation-subtitle">
        Explora los detalles de la constelación
      </p>
      <div class="plus-button" @click="showDetails = !showDetails">
        <span class="material-icons md-light md-48">add</span>
      </div>
      <div class="constelation-details" v-if="showDetails">
        <div class="details-wrapper">
          <p>
            Es la constelación origen, generadora de las primeras interacciones
            dentro de la comunidad de líderes de experiencias. Tiene como
            objetivo la culturización y alineación de sus liderazgos y
            experiencias al DistritoTec como Distrito de innovación.
          </p>
          <p>
            Únete como explorador nivel 1 y vive las distintas dinámicas y
            actividades que tienen como objetivo el intercambio de conocimiento,
            ideas y recursos de apoyo que generen una comunidad activa y
            vibrante.
          </p>
          <p>
            Con tu participación podrás ascender de nivel y si llegas al podium,
            obtendrás un premio.
          </p>
          <p>
            La siguiente fecha de inicio del programa es el 10 de enero del
            2022. Da clic en el botón, para enviarnos tu solicitud y nosotros te
            compartiremos las indicaciones y la liga para unirte al grupo de
            WhatsApp, mediante correo.
          </p>
        </div>

        <!-- <button class="join-button" @click="unirse('Big Bang')" v-if="!loading && !sendingStatus">QUIERO UNIRME</button> -->
        <button
          class="join-button"
          @click="triggerEvent"
          v-if="!loading && !sendingStatus"
        >
          <a
            class="join-link"
            href="https://forms.gle/L5R7t4WToTzcWf9S7"
            target="_blank"
            >QUIERO UNIRME</a
          >
        </button>
        <button class="join-button" disabled v-if="loading">Enviando...</button>
        <div v-if="sendingStatus">
          <p
            class="mensaje-envio"
            style="color: white"
            v-if="sendingStatus == 'error'"
          >
            Ocurrión un error por favor contacta al administrador.
          </p>
          <p
            class="mensaje-envio"
            style="color: white"
            v-if="sendingStatus == 'ok'"
          >
            Tu solicitud ha sido enviada, por favor espera la invitación en tu
            correo.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDetails: false,
      sendingStatus: null,
      loading: false,
    };
  },
  methods: {
    triggerEvent() {
      this.$gtag.event("contactar", {
        event_category: "unirse constelación",
        event_label: "alguien quiso unirse a la constelación Big Bang",
      });
    },

    unirse(constelacion) {
      this.loading = true;
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("token");

      fetch(
        process.env.VUE_APP_API_ENDPOINT + "/getUser/" + userId + "/" + token
      )
        .then((res) => res.json())
        .then((user) => {
          fetch(process.env.VUE_APP_API_ENDPOINT + "/solicitudConstelacion", {
            method: "POST",
            body: JSON.stringify({
              lider: user.nombre,
              constelacion: constelacion,
              correo: user.correo,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .then((response) => {
              this.loading = false;
              if (response.ok) {
                this.sendingStatus = "ok";
              } else {
                this.sendingStatus = "error";
              }
            });
        });
    },
  },
};
</script>

<style scoped>
.page-container {
  margin: 40px;
  padding: 0;
}

.cards-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  flex-wrap: wrap;
}

.keywords-wrapper {
  border: solid 2px #333399;
  width: 100%;
  border-radius: 15px;
  padding: 20px;
}

.keywords {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px 30px;
}

.highlighted-letter {
  color: #333399;
}

.constelation-wrapper {
  background-color: #333399;
  background-image: url("../.././assets/Galaxies/Andromeda.jpg");
  border-radius: 15px;
  padding: 20px;
  display: grid;
  grid-template-areas:
    "title plus"
    "subtitle plus";
  grid-template-columns: auto 20%;
  align-items: center;
  max-width: 600px;
  cursor: pointer;
}

.constelation-title {
  color: white;
  grid-area: title;
  font-size: 2.5rem;
  margin: 0;
}

.constelation-subtitle {
  color: white;
  grid-area: subtitle;
  font-weight: 600;
}

.mensaje-envio {
  color: white;
  font-weight: 600;
}

.plus-button {
  grid-area: plus;
  justify-self: flex-end;
}

.constelation-wrapper:hover .plus-button span {
  color: #c31bcc;
  transition: all 0.5s;
}

.constelation-details p {
  color: black;
}

.details-wrapper {
  background-color: rgba(245, 245, 245, 1);
  padding: 10px;
  border-radius: 15px;
}

.join-button {
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.join-button:hover {
  background-color: #c31bcc;
  transition: all 0.3s;
}

.join-link {
  color: white;
  text-decoration: none;
}
</style>
