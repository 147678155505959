<template>
  <div class="page-container">
    <div class="backdrop" @click="closeModal()"></div>
    <div class="details-container">
      <div v-if="isLoading">Loading...</div>
      <div class="form-container">
        <div class="title-header">
          <h2 class="registro-title">Agregar Manual de Experiencia</h2>
        </div>
        <div class="form-fields-container" v-if="isUploading">
          <h1>Enviando datos</h1>
        </div>
        <div class="form-fields-container" v-if="!isUploading">
          <div class="field-wrapper">
            <label for="name"><b>Nombre del manual de experiencia</b></label>
            <input
              type="text"
              placeholder="Escribe el nombre del manual de experiencia"
              name="name"
              required
              v-model="name"
            />
          </div>
          <div class="field-wrapper">
            <label for="manualImg" class="field-title bottom-space"
              >Selecciona la imagen del manual
              <div class="file-button">
                <span class="material-icons md-dark md-36 file-icon"
                  >file_upload</span
                >
                <p class="file-text">Seleccionar archivo</p>
              </div>
              <input
                @change="seleccionarImage"
                class="file-input"
                type="file"
                id="manualImg"
                name="manualImg"
                accept=".jpg, .jpeg, .png"
              />
              <p v-if="manualImg" class="file-name">
                Imagen seleccionada: {{ manualImg.name }}
              </p>
            </label>
          </div>
          <div class="field-wrapper">
            <label for="url"
              ><b>Link del manual (Empezando con https://)</b></label
            >
            <input
              type="text"
              placeholder="Ingresa el link del manual de la experiencia"
              name="url"
              required
              v-model="url"
            />
          </div>
          <div class="field-wrapper">
            <input
              @click="addManual()"
              id="submit-button"
              type="button"
              value="Agregar Manual"
            />
            <input
              @click="closeModal()"
              id="cancel-button"
              type="button"
              value="Cancelar"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['closeModal'],
  data() {
    return {
      name: '',
      manualImg: null,
      url: '',
      isUploading: false,
    };
  },
  methods: {
    seleccionarImage(event) {
      this.manualImg = event.target.files[0];
    },
    addManual() {
      const fd = new FormData();
      fd.append('name', this.name);
      fd.append('url', this.url);
      fd.append(
        'manualImg',
        this.manualImg,
        new Date().getTime() + '-' + this.manualImg.name
      );

      this.isUploading = true;

      const userId = this.$store.getters.userId;
      const token = this.$store.getters.token;

      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
            '/addManual/' +
            userId +
            '/' +
            token,
          fd
        )
        .then(() => {
          this.isUploading = false;
          this.closeModal();
          history.go(0);
        });
    },
  },
};
</script>

<style scoped>
.page-container {
  /* display: flex;
  justify-content: center; */
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(141, 141, 141, 0.219);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
}
.backdrop:hover {
  cursor: pointer;
}

.details-container {
  z-index: 101;
  position: absolute;
  width: 60%;
  background: rgb(253, 253, 253);
  border-radius: 13px;
  overflow: visible;
}
.registro-title {
  font-weight: 600;
  font-size: 1.3rem;
}
.title-header {
  border-bottom: 1px solid #e0e2e3;
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
}
.form-fields-container {
  width: 100%;
  padding: 2rem 5rem 5rem 5rem;
  height: 100%;
  margin-bottom: 5px;
}
.field-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.field-wrapper label b {
  font-size: 1rem;
  font-weight: 500;
}
.field-wrapper input {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
}
.field-wrapper input:focus {
  outline: none;
}
.field-title {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  margin: 5px 0;
}

.bottom-space {
  margin-bottom: 2.5rem;
}
.file-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
  /* margin-bottom: 2.5rem; */
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
}

.file-button:hover {
  cursor: pointer;
  background: #c31bcc;
}

.file-button:hover .file-icon,
.file-button:hover .file-text {
  color: white;
}

.file-icon {
  color: #c31bcc;
  margin-bottom: 0px;
}

.file-text {
  margin: 10px;
  color: black;
}

.file-input {
  display: none;
}
#submit-button {
  background-size: 200%;
  background-image: linear-gradient(
    to right,
    rgba(195, 27, 204, 1) 30%,
    rgba(51, 51, 153, 1) 70%
  );
  transition: all ease 1s;
  border: none;
  padding: 1.5rem;
  font-weight: bold;
  font-size: 1.75rem;
  color: white;
  background-position: center;
}

#submit-button:hover {
  background-size: 250%;
  background-position: left;
  cursor: pointer;
}
#cancel-button {
  width: 200px;
  color: white;
  background: black;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border: none;
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
}

#cancel-button:hover {
  background: white;
  color: black;
}
</style>
