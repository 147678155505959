<template>
  <div class="experience-row" :class="{ results }">
    <h1 class="experience-row__title">{{ title }}</h1>
    <div class="experience-row__cards-container">
      <div
        class="izquierda"
        v-if="
          showArrows && ((results && experiencias.length !== 0) || !results)
        "
      >
        <button id="left-button" @click="swipeLeft">
          <span class="material-icons md-dark md-36">chevron_left</span>
        </button>
      </div>
      <div id="content" ref="content" class="center">
        <experience-card-item
          v-for="experiencia in experiencias"
          :key="experiencia.id"
          :experienciaData="experiencia"
          :temas="temas"
        ></experience-card-item>
        <p v-if="results && experiencias.length === 0">
          No hay resultados para tu búsqueda
        </p>
      </div>
      <div
        class="derecha"
        v-if="
          showArrows && ((results && experiencias.length !== 0) || !results)
        "
      >
        <button id="right-button" @click="swipeRight">
          <span class="material-icons md-dark md-36">chevron_right</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// :perfil="perfil"
import ExperienceCardItem from '../ExperiencesUI/ExperienceCardItem';

export default {
  components: {
    ExperienceCardItem,
  },
  props: ['title', 'experiencias', 'temas', 'results'],
  data() {
    return {
      showArrows: null,
      // perfil: this.$route.query.perfil.replaceAll(' ', '+'),
    };
  },
  methods: {
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          'now' in window.performance
            ? performance.now()
            : new Date().getTime();

        let scroll = timestamp => {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        };
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    },
    swipeLeft() {
      const content = this.$refs.content;
      this.scrollTo(content, -300, 400);
    },
    swipeRight() {
      const content = this.$refs.content;
      this.scrollTo(content, 300, 400);
    },
    checkIfShowArrows() {
      let row = this.$refs.content;
      let cards = [];
      for (let i = 0; i < row.children.length; i++) {
        cards.push(row.children[i]);
      }
      let rowWidth = row.offsetWidth;
      let cardsWidth = 0;

      cards.forEach(card => {
        cardsWidth += card.offsetWidth;
      });

      if (!(rowWidth < cardsWidth)) {
        this.showArrows = false;
      } else {
        this.showArrows = true;
      }
    },
  },
  unmounted() {
    window.removeEventListener('resize', this.checkIfShowArrows);
  },
  mounted() {
    window.addEventListener('resize', this.checkIfShowArrows);
    this.checkIfShowArrows();
  },
  updated() {
    this.checkIfShowArrows();
  },
};
</script>

<style scoped>
.experience-row {
  margin: 1.25rem 0rem;
  padding: 1.25rem 5.5rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.results {
  background-color: #333399;
  color: white;
  margin-top: 3rem;
  box-shadow: 3px 3px 5px 5px rgb(27 26 26 / 20%);
}

.experience-row__title {
  font-size: 1.25rem;
}

.experience-row__cards-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.izquierda {
  position: absolute;
  left: 0;
  z-index: 10;
  margin: 4.3rem;
}

.center {
  padding: 10px 0;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow-x: auto;
}

.center::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.derecha {
  position: absolute;
  right: 0;
  z-index: 10;
  margin: 4.3rem;
}

#left-button,
#right-button {
  border: none;
  font-size: 2.25rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  outline: none;
  color: #c31bcc;
  background: white;
  box-shadow: 1px 2px 2px 1px rgba(54, 54, 54, 0.432);
}

#left-button:hover,
#left-button:active,
#right-button:hover,
#right-button:active {
  cursor: pointer;
  background: #c31bcc;
  color: white;
}

@media (max-width: 50rem) {
  #left-button,
  #right-button {
    height: 100%;
  }

  .experience-row {
    margin-top: 0.5rem;
    padding: 0.5rem 3rem;
  }

  .experience-row__title {
    font-size: 1rem;
    font-weight: 600;
  }

  .izquierda,
  .derecha {
    margin: 2rem;
  }
}

@media (max-width: 30rem) {
  #left-button,
  #right-button {
    display: none;
  }

  .experience-row {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
  }

  .experience-row__title {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
