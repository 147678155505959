<template>
  <router-link :to="'/experiencias/' + experienciaData.id">
    <div class="experience-card__container">
      <img :src="urlImagen" alt="" class="experience-card__image" />
      <div class="experience-card__info">
        <h3 class="card-info__title">{{ experienciaData.nombre }}</h3>
        <p class="card-info__date">
          {{
            experienciaData.id_estatus != 4
              ? 'Próxima edición'
              : 'Cierre convocatoria'
          }}: {{ proximaEdicion }}
        </p>
        <p class="card-info__date_extra" v-if="isSucediendoAhora">
          {{ '(Sucediendo ahora)' }}
        </p>
        <h6 class="card-info__category">
          {{ temaExperiencia }}
        </h6>
      </div>
    </div>
  </router-link>
</template>
<script>
// + '?perfil=' + perfil
const dayjs = require('dayjs');
require('dayjs/locale/es');
dayjs.locale('es');
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

export default {
  props: ['experienciaData', 'temas'],
  // , 'perfil'
  data() {
    return {
      isLoading: true,
      urlImagen: '',
      isSucediendoAhora: false,
    };
  },
  computed: {
    temaExperiencia() {
      if (this.experienciaData.temasIniciativa.length > 1) {
        return 'Multidisciplinaria';
      } else {
        const temaExperiencia = this.experienciaData.temasIniciativa[0];
        const temaString = this.temas.find(tem => tem.id == temaExperiencia);
        return temaString.tema;
      }
    },
    proximaEdicion() {
      if (this.experienciaData.id_estatus == 1) {
        return 'Siempre activa';
      } else if (this.experienciaData.id_estatus == 3) {
        return 'Por confirmar';
      } else if (this.experienciaData.id_estatus == 4) {
        let fecha = dayjs(this.experienciaData.fecha_fin)
          .add(6, 'hours')
          .locale('es')
          .format('MMM D, YYYY');
        fecha = fecha.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
        return fecha;
      } else {
        let fecha = dayjs(this.experienciaData.fecha_inicio)
          .add(6, 'hours')
          .locale('es')
          .format('MMM D, YYYY');
        fecha = fecha.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
          return g1.toUpperCase() + g2.toLowerCase();
        });
        return fecha;
      }
    },
  },
  created() {
    const baseLogosUrl = process.env.VUE_APP_IMAGES_ENDPOINT + '/logos/';

    this.urlImagen = baseLogosUrl + this.experienciaData.img_logo;

    // Revisar si esta sucediendo ahora
    const fecha_inicio = new Date(this.experienciaData.fecha_inicio);
    if (this.experienciaData.id_estatus === 2) {
      if (fecha_inicio < new Date()) {
        this.isSucediendoAhora = true;
      } else {
        this.isSucediendoAhora = false;
      }
    }
  },
};
</script>

<style scoped>
.imgFondoBlanco {
  background: #333399;
}

a {
  color: black;
}

.experience-card__container {
  background-color: white;
  width: 17rem;
  height: 14rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border-radius: 13px;
  margin-right: 1.25rem;
  align-items: center;
}

.experience-card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;

  /*width: 100%;
  display: flex;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);*/
}

.experience-card__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  background: white;
  transform: translateY(100%);
  padding: 0.5rem;
}

.experience-card__container:hover > .experience-card__info,
.experience-card__container:active > .experience-card__into {
  transform: translateY(0%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.experience-card__container:hover,
.experience-card__container:active {
  cursor: pointer;
}

.card-info__title {
  font-size: 1.25rem;
  margin: 0.1rem 0;
  white-space: pre-wrap;
}

.card-info__date {
  font-size: 1rem;
  margin-top: 0.125rem;
}

.card-info__date_extra {
  font-size: 1rem;
  margin-top: -1rem;
}

.card-info__category {
  font-size: 0.8rem;
  color: #c31bbc;
  margin-bottom: 0.1rem;
}

@media (max-width: 75rem) {
  .experience-card__container {
    width: 13rem;
    height: 11rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }

  .card-info__date {
    font-size: 0.8rem;
  }

  .card-info__category {
    font-size: 0.7rem;
  }
}

@media (max-width: 50rem) {
  .experience-card__container {
    width: 13rem;
    height: 11rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }

  .card-info__date {
    font-size: 0.8rem;
  }

  .card-info__category {
    font-size: 0.7rem;
  }
}

@media (max-width: 40rem) {
  .experience-card__container {
    width: 11rem;
    height: 9rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }

  .card-info__date {
    font-size: 0.8rem;
  }

  .card-info__category {
    font-size: 0.7rem;
  }
}
</style>
