<template>
  <div class="page-container">
    <h2>Mi Perfil</h2>
    <h3>¡Bienvenido! <span> Nombre de Perfil</span></h3>
    <p>En esta sección podrás modificar la contraseña de tu usuario. Recuerda, tiene que tener por lo menos 8 caracteres, 1 mayúscula, 1 minúscula y un número.</p>

    <h3 class="window-title">Cambio de contraseña</h3>
    <form @submit.prevent="changePassword" class="rows-window">

      <label for="oldPassword">Antigua contraseña</label>
      <input type="password" name="oldPassword" v-model="lastPass" placeholder="Escribe tu antigua contraseña">

      <label for="newPassword">Nueva contraseña</label>
      <input type="password" name="newPassword" v-model="newPass" placeholder="Escribe tu nueva contraseña">
      
      <label for="confirmNewPass">Confirmar contraseña</label>
      <input type="password" name="confirmNewPass" v-model="newPassConfirm" placeholder="Confirma tu nueva contraseña">

      <p class="success-msg" v-if="success">{{success}}</p>
      <p class="error-msg" v-if="error">{{error}}</p>

      <button type="submit" class="submit-button">Guardar Cambios</button>
    </form>
  </div>
</template>

<script>
export default {
    data() {
        return {
          lastPass: '',
          newPass: '',
          newPassConfirm: '',
          error: null,
          loading: false,
          success: null,
        }
    },
    methods: {
        changePassword() {
          if(this.newPass === this.newPassConfirm) {
            // Revisar si la contraseña cumple con los requisitos
            if(this.newPass.length >= 8 && this.newPass.match(/[A-Z]/) && this.newPass.match(/[a-z]/) && this.newPass.match(/[0-9]/)) {
              // Enviar la nueva contraseña al servidor
              this.loading = true;
              const userId = localStorage.getItem('userId');
              const token = localStorage.getItem('token');

              fetch(process.env.VUE_APP_API_ENDPOINT + "/changePassword/" + userId + "/" + token, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({
                  lastPass: this.lastPass,
                  newPass: this.newPass
                })
              })
              .then(response => response.json())
              .then(response => {
                if(response.success) {
                  this.loading = false;
                  this.error = null;
                  this.success = response.success;
                } else {
                  this.loading = false;
                  this.error = response.error;
                }
              })
            } else {
              this.error = 'La contraseña debe tener por lo menos 8 caracteres, 1 mayúscula, 1 minúscula y un número.';
            }
          } else {
            this.error = 'Las nuevas contraseñas no coinciden';
          }
        }
    }
}
</script>


<style scoped>
  
.page-container {
  margin: 40px;
  padding:0;
}

.window-title {
  margin-top: 40px;
  color: #c31bcc;
}

.rows-window {
  max-width: 500px;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
  padding-top: 30px;
  border-radius: 13px;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  overflow: auto;
}

label {
  font-weight: 600;
  margin-top: 45px;
  padding-left: 5px;
}

input {
  border: none;
  width: 100%;
  right: 250px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #919191;
  padding: 5px;
  margin-bottom: 40px;
}

input:focus {
  outline: none;
}

span {
  color: #c31bcc;
}

.submit-button {
    margin-bottom: 20px;
    width: 200px;
    border-radius: 8px;
    padding: 0.7rem 2rem;
    color: white;
    background-image: linear-gradient(to right, rgba(195,27,204,1) 30%, rgba(51,51,153,1) 70%);
    background-size: 200%;
    background-position: center;
    transition: all ease 0.5s;
    border: none;
    font-weight: bold;
    font-size: 1rem;
}

.submit-button:hover,
.submit-button:active{
    color: white;
    background-size:250%;
    background-position: left;
    cursor: pointer;
}

.error-msg {
  color: red;
}

.success-msg {
  color: green;
}

</style>