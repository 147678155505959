import { createStore } from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import authModule from './modules/auth/index.js'

const store = createStore({
  modules: {
    auth: authModule
  },
  state() {
    return {
      profile: '',
    };
  },
  plugins: [createPersistedState()],
  getters: {
    profile(state) {
      return state.profile;
    },
  },
  actions: {
    chooseProfile(context, data) {
      context.commit('setProfile', data);
    },
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = payload;
    },
  },
});

export default store;
