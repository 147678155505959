<template>
  <div class="rows-container">
    <div class="row">
      <span class="material-icons md-36 mdlightgray icon">event</span>
      <div class="text-container">
        <h3 class="title">
          {{ id_estatus != 4 ? 'Próxima edición' : 'Apertura de convocatoria' }}
        </h3>
        <!-- <p class="description">{{estatus.id ? id_estatus === 1 ? estatus.estatus : fechaInicioF : id_estatus === 1 ? estatus : fechaInicioF}}</p> -->
        <p class="description">
          {{
            id_estatus === 1
              ? estatus
              : id_estatus === 3
              ? 'Por confirmar'
              : fechaInicioF
          }}
          {{ isSucediendoAhora ? '(Sucediendo ahora)' : '' }}
        </p>
        <h3 class="title" v-if="id_estatus == 4">
          Cierre de convocatoria
        </h3>
        <!-- <p class="description">{{estatus.id ? id_estatus === 1 ? estatus.estatus : fechaInicioF : id_estatus === 1 ? estatus : fechaInicioF}}</p> -->
        <p class="description" v-if="id_estatus == 4">
          {{ fechaFinF }}
        </p>
      </div>
    </div>

    <div class="row">
      <span class="material-icons md-36 mdlightgray icon">business</span>
      <div class="text-container">
        <h3 class="title">Campus</h3>
        <p class="description">{{ campus }}</p>
      </div>
    </div>

    <div class="row">
      <span class="material-icons md-36 mdlightgray icon">people_outline</span>
      <div class="text-container">
        <h3 class="title">Modalidad</h3>
        <p class="description">{{ modalidad }}</p>
      </div>
    </div>

    <div class="row">
      <span class="material-icons md-36 mdlightgray icon">emoji_events</span>
      <div class="text-container">
        <h3 class="title">Premio</h3>
        <p class="description">{{ premio }}</p>
      </div>
    </div>

    <div class="row">
      <span class="material-icons md-36 mdlightgray icon">attach_money</span>
      <div class="text-container">
        <h3 class="title">Precio</h3>
        <p class="description">
          {{ precio === 'N' ? 'Sin costo' : 'Con costo' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs');
require('dayjs/locale/es');
dayjs.locale('es');
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

export default {
  props: [
    'modalidad',
    'premio',
    'precio',
    'id_estatus',
    'estatus',
    'fechaInicio',
    'fechaFin',
    'duracion',
    'campus',
  ],
  data() {
    return {
      estatusFormated: '',
      isSucediendoAhora: false,
    };
  },
  computed: {
    fechaInicioF() {
      // let fecha = new Date(this.fechaInicio)
      let fecha = dayjs(this.fechaInicio)
        .add(6, 'hours')
        .locale('es')
        .format('MMM D, YYYY');
      fecha = fecha.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
      return fecha;
    },
    fechaFinF() {
      let fecha = dayjs(this.fechaFin)
        .add(6, 'hours')
        .locale('es')
        .format('MMM D, YYYY');
      fecha = fecha.replace(/(\w)(\w*)/g, function(g0, g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
      return fecha;
    },
  },
  created() {
    // Revisar si esta sucediendo ahora
    const fecha_inicio = new Date(this.fecha_inicio);

    if (this.id_estatus === 2) {
      if (fecha_inicio < new Date()) {
        this.isSucediendoAhora = true;
      } else {
        this.isSucediendoAhora = false;
      }
    }
  },
};
</script>

<style scoped>
.rows-container {
  padding: 0 0 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 1.3rem;
}

.row {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 1.2rem;
}

.text-container {
  margin: 0.5rem 0;
}

.title {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1rem;
  color: #414141;
  font-weight: 500;
  white-space: nowrap;
}

.description {
  margin-top: 0.1rem;
  font-size: 0.9rem;
  margin-bottom: 0;
  color: #919191;
}

@media (max-width: 75rem) {
  .rows-container {
    padding-left: 1rem;
  }
}

@media (max-width: 50rem) {
  .rows-container {
    border-left: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 1rem;
    width: 100%;
  }

  .row {
    margin-right: 1rem;
  }
}
</style>
