<template>
  <div class="page-container">
    <h2>Estadísticas</h2>
    <p>
      En esta pestaña encontrarás el enlace al reporte de datos recopilados por
      la plataforma.
    </p>
    <p>
      El equipo de NERiX, ha creado para tí un reporte en el que puedes conocer
      el alcance de tus experiencias y las de los demás líderes que ya se
      encuentran en la plataforma.
    </p>
    <a
      class="morado"
      href="https://datastudio.google.com/reporting/faa0a21c-9098-4c17-ac79-abb06802e0ac"
    >
      https://datastudio.google.com/reporting/faa0a21c-9098-4c17-ac79-abb06802e0ac
    </a>
    <p>
      Si deseas concactar al equipo de Business Intelligence de NERiX, para el
      desarrollo de un reporte más especializado, no dudes en escribir a
      <span class="morado">nerix@servicios.tec.mx</span> con asunto:
      <span class="morado">Estadísticas especializadas</span>
    </p>
  </div>
</template>

<style scoped>
.page-container {
  margin: 40px;
  padding: 0;
}

.morado {
  color: #c31bcc;
}
</style>
