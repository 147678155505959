<template>
  <!-- Este es el div de la parte de la imagen y sus botones -->
  <div class="image-container">
    <img class="details__image" :src="urlImagen" alt="" />
    <div class="image-overlay"></div>
    <div class="exit-button" @click="popPage">
      <span class="material-icons md-dark md-24">close</span>
    </div>
    <!-- <div class="image-elements-container">
      <h1 class="details-title">
        {{ iniciativaData.nombre }}
      </h1>
      <div class="bottom-buttons-container">
        <a :href="urlConHttp" target="_blank"
          ><button class="visit-page-button">Visita su página</button></a
        >
        <div class="tooltip">
          <button
            @click="copiarLink"
            @mouseleave="resetTooltipTxt"
            class="share-button"
          >
            <span class="material-icons md-dark md-18">share</span>
          </button>
          <span class="tooltiptext">{{ tooltipTxt }}</span>
        </div>
      </div>
    </div> -->
  </div>

  <!-- Este es el div del texto y todo lo de abajo -->
  <div class="text-container">
    <div>
      <h1 class="details-title">
        {{ iniciativaData.nombre }}
      </h1>

      <div class="bottom-buttons-container">
        <a v-if="hasUrl" :href="urlConHttp" target="_blank"
          ><button class="visit-page-button">Visita su página</button></a
        >
        <a v-if="hasLinkRegistro" :href="linkRegistroConHttp" target="_blank"
          ><button class="visit-page-button">Regístrate aquí</button></a
        >
        <div class="tooltip">
          <button
            @click="copiarLink"
            @mouseleave="resetTooltipTxt"
            class="share-button"
          >
            <span class="material-icons md-dark md-18">share</span>
          </button>
          <span class="tooltiptext">{{ tooltipTxt }}</span>
        </div>
      </div>

      <h2 class="details-topic">
        {{ iniciativaData.enfoque }}
      </h2>
      <ul class="details-keyword-list">
        <li
          class="details-keyword"
          v-for="palabra in palabrasClave.slice(0, 4)"
          :key="palabra"
        >
          {{ palabra }}
        </li>
        <!-- <li class="details-keyword">Keyword 2</li>
        <li class="details-keyword">Keyword 3</li>
        <li class="details-keyword">Keyword 4</li> -->
      </ul>
    </div>

    <div class="short-description-menu-container">
      <div>
        <p class="short-description">
          {{ iniciativaData.desc_corta }}
        </p>
        <p class="long-description">
          {{ iniciativaData.desc_larga }}
        </p>
      </div>
      <experience-details-menu
        :modalidad="iniciativaData.modalidad"
        :premio="iniciativaData.premio"
        :precio="iniciativaData.precio"
        :estatus="iniciativaData.estatus"
        :id_estatus="iniciativaData.id_estatus"
        :fechaInicio="iniciativaData.fecha_inicio"
        :fechaFin="iniciativaData.fecha_fin"
        :duracion="iniciativaData.duracion"
        :campus="iniciativaData.campus"
      ></experience-details-menu>
    </div>
  </div>

  <experience-contact-form
    :contacto="iniciativaData.contacto"
    :iniciativa="iniciativaData.nombre"
  ></experience-contact-form>
</template>

<script>
import ExperienceDetailsMenu from '../../UI/ExperiencesUI/ExperienceDetailsMenu.vue';
import ExperienceContactForm from '../../UI/ExperiencesUI/ExperienceContactForm.vue';

export default {
  components: {
    ExperienceDetailsMenu,
    ExperienceContactForm,
  },
  props: ['iniciativaData'],
  emits: ['closePreview'],
  data() {
    return {
      isLoading: true,
      urlImagen: null,
      tooltipTxt: 'Copiar URL de la página.',
    };
  },
  computed: {
    palabrasClave() {
      const palabras = this.iniciativaData.palabras_clave;
      const listaPalabras = palabras.split(', ');
      return listaPalabras;
    },
    urlConHttp() {
      if (
        this.iniciativaData.url.includes('http://') ||
        this.iniciativaData.url.includes('https://')
      ) {
        return this.iniciativaData.url;
      } else {
        return 'http://' + this.iniciativaData.url;
      }
      // return '#'
    },
    linkRegistroConHttp() {
      if (
        this.iniciativaData.link_registro.includes('http://') ||
        this.iniciativaData.link_registro.includes('https://')
      ) {
        return this.iniciativaData.link_registro;
      } else {
        return 'http://' + this.iniciativaData.link_registro;
      }
    },
    hasUrl() {
      if (this.iniciativaData.url != null && this.iniciativaData.url != '') {
        return true;
      } else {
        return false;
      }
    },
    hasLinkRegistro() {
      if (
        this.iniciativaData.link_registro != null &&
        this.iniciativaData.link_registro != ''
      ) {
        return true;
      } else {
        return false;
      }
    },
    // return this.iniciativaData.url
  },
  methods: {
    popPage() {
      if (!this.iniciativaData.preview) {
        window.location.replace(
          'http://nerix.com.mx/experiencias' // ?perfil=Comunidad+Tec
        );
        // if (this.$route.query.share) {
        //   // !this.$route.query.perfil ||
        // } else {
        //   this.$router.go(-1);
        // }
      } else {
        this.$emit('closePreview');
      }
    },
    existeImagen(url) {
      let http = new XMLHttpRequest();
      http.open('GET', url, false);

      http.send();

      return http.status != 404;
    },
    copiarLink() {
      if (!this.iniciativaData.preview) {
        const currentURL = window.location.href;
        const urlWithoutQuerys = currentURL.split('?')[0];
        navigator.clipboard.writeText(urlWithoutQuerys);
        this.tooltipTxt = '¡URL copiado al portapapeles!';
      } else {
        navigator.clipboard.writeText('Preview');
        this.tooltipTxt = 'No se puede copiar link durante Preview';
      }
    },
    resetTooltipTxt() {
      this.tooltipTxt = 'Copiar URL de la página.';
    },
  },
  created() {
    if (!this.iniciativaData.img_background.name) {
      const baseRepreUrl =
        process.env.VUE_APP_IMAGES_ENDPOINT + '/backgrounds/';

      this.urlImagen = baseRepreUrl + this.iniciativaData.img_background;
    } else {
      this.urlImagen = URL.createObjectURL(this.iniciativaData.img_background);
    }
  },
};
</script>

<style scoped>
.image-container {
  width: 100%;
  position: relative;
  max-height: 27rem;
  min-height: 20rem;
  overflow: hidden;
  background: white;
  border-radius: 13px 13px 0 0;
  display: block;
  text-align: center;
  z-index: -2;
}

.details__image {
  position: absolute;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -2;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-color: black;
}

.exit-button {
  position: absolute;
  top: 2rem;
  right: 1rem;
  color: #414141;
  background: whitesmoke;
  border: none;
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 5;
}

.image-elements-container {
  position: absolute;
  bottom: 2rem;
  left: 4rem;
}

.details-title {
  color: #414141;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: -2rem;
  display: block;
  text-align: left;
}
/* 
.details-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 0.4rem;
  display: block;
  text-align: left;
} */

.bottom-buttons-container {
  display: flex;
  justify-content: baseline;
}

/* .visit-page-button {
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
  padding: 0.75rem 1.5rem;
  color: #414141;
  background: whitesmoke;
  border: none;
  border-radius: 13px;
  display: inline-block;
} */
.visit-page-button {
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1rem;
  padding: 0.75rem 1.5rem;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(195, 27, 204, 1) 0%,
    rgba(51, 51, 153, 1) 100%
  );
  border: none;
  border-radius: 13px;
  display: inline-block;
}

/* .share-button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #414141;
  background: whitesmoke;
  border: none;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  display: inline-block;
} */
.share-button {
  font-size: 1rem;
  font-weight: bold;
  padding: 0.75rem;
  color: #ffffff;
  background: linear-gradient(
    90deg,
    rgba(195, 27, 204, 1) 0%,
    rgba(51, 51, 153, 1) 100%
  );
  border: none;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 50%;
  display: inline-block;
}

/* button {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  color: white;
  background: linear-gradient(
    90deg,
    rgba(195, 27, 204, 1) 0%,
    rgba(51, 51, 153, 1) 100%
  );
  border: none;
  font-weight: bold;
  font-size: 1rem;
} */

/* button:hover,
button:active {
  color: white;
  background: #c31bcc;
  cursor: pointer;
} */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 7px 2px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.visit-page-button:focus,
.share-button:focus {
  outline: none;
}

.visit-page-button:hover,
.share-button:hover,
.exit-button:hover {
  cursor: pointer;
  background: #c31bcc;
  /* transition: all 0.3s; */
  color: white;
}

.text-container {
  margin: 4rem;
  margin-bottom: 0;
}

.details-topic {
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
}

.short-description-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;
}

p.long-description {
  white-space: pre-line;
}

.short-description {
  font-weight: 600;
  margin-bottom: 2rem;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
  padding: 1rem 0;
}

.closing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dbdbdb;
}

.closing-text {
  margin-bottom: 0;
}

.follow-description {
  margin-top: 0.5rem;
}

.social-network-container {
  display: flex;
  justify-content: center;
}

svg {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 0.3rem;
}

.details-keyword-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  list-style-position: inside;
}

.details-keyword {
  color: #414141;
  margin-right: 1rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  margin-left: 0;
}

.details-keyword:hover {
  color: #1f1f1f;
  /* text-decoration: underline; */
  /* cursor: pointer; */
}

.details-keyword::marker {
  color: #c31bcc;
}

.details-keyword-list .details-keyword:first-child {
  list-style-type: none;
}

@media (max-width: 50rem) {
  .image-container {
    text-align: center;
    border-radius: 0;
    margin: 0;
  }

  .details__image {
    z-index: -1;
    opacity: 0.7;
  }

  .image-elements-container {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }

  .details-title {
    font-size: 1.5rem;
  }

  .text-container {
    margin: 1.2rem;
  }

  .image-container {
    border-radius: 0;
  }

  .short-description-menu-container {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin-bottom: 2.5rem;
  }
}
</style>
