<template>
  <div class="page-container">
    <h2>Administración de Experiencias</h2>
    <p class="section-description">
      Administra las experiencias de emprendimiento que existen en la plataforma, gestiona su fecha de vencimiento e información, y mantén a fecha a los líderes para la actualización de sus experiencias.
    </p>


   <h3 class="window-title">Aprobacion de Experiencias</h3>
    <div class="rows-window__new-experiences" v-if="isLoading">
      <div class="loading-container">
        <loading-spinner></loading-spinner>
      </div>
    </div>
    <div class="rows-window__new-experiences" v-if="!isLoading">
      <div v-if="iniciativasPendientes.length > 0">
        <new-experience-row v-for="iniciativa in iniciativasPendientes" :key="iniciativa.id" :iniciativaData="iniciativa"></new-experience-row>
      </div>
      <div v-else class="no-items-message">
        <span class="material-icons md-24 md-dark">task_alt</span> <p>No hay experiencias por aprobar</p>
      </div>
    </div>

    <h3 class="window-title">Experiencias de Emprendimiento</h3>
    <div class="rows-window">
      <div class="searchbar">
        <span class="material-icons md-24 md-dark">search</span>
        <input class="searchbar-input" type="text" placeholder="Buscar..." v-model="keywords" />
      </div>

      <div class="order-toggles">
          <!-- <button class="toggle"><span class="material-icons md.24 toggle-icon">sync_alt</span></button>
          <button class="toggle">Orden Alfabético<span class="material-icons md.24 toggle-icon">sort_by_alpha</span></button>
          <button class="toggle">Ordenar por fecha<span class="material-icons md.24 toggle-icon">date_range</span></button> -->
          <h3 class="results-number" v-if="!isLoading">Número de resultados: {{iniciativas.length}}</h3>
      </div>

      <div class="rows" v-if="!isLoading && !iniciativasBuscadas">
        <existing-experience v-for="iniciativa in iniciativas" :key="iniciativa.id" :iniciativaData="iniciativa"></existing-experience>
      </div>

      <div class="rows" v-if="!isLoading && iniciativasBuscadas">
        <existing-experience v-for="iniciativa in iniciativasBuscadas" :key="iniciativa.id" :iniciativaData="iniciativa"></existing-experience>
      </div>
      
    </div>
  </div>
</template>

<script>

import ExistingExperience from '../../components/UI/ControlPanelUI/ManageExperiencesUI/ExistingExperience.vue'
import NewExperienceRow from '../../components/UI/ControlPanelUI/ManageExperiencesUI/NewExperienceRow.vue';

export default {
  components: {
      ExistingExperience,
      NewExperienceRow,
  },
  data() {
    return {
      iniciativasPendientes: null,
      iniciativas:null,
      iniciativasBuscadas: null,
      isLoading: false,
      keywords: ''
    }
  },
  watch: {
    keywords(value) {
        if(value === '') {
          this.iniciativasBuscadas = null;
          return;
        }

        let search_results = this.iniciativas
        .filter(iniciativa => {
            // Filter results by doing case insensitive match on name here
            return iniciativa.nombre.toLowerCase().includes(value.toLowerCase());
        })
        .sort((a, b) => {
            // Sort results by matching nombre with value position in nombre
            if(a.nombre.toLowerCase().indexOf(value.toLowerCase()) > b.nombre.toLowerCase().indexOf(value.toLowerCase())) {
                return 1;
            } else if (a.nombre.toLowerCase().indexOf(value.toLowerCase()) < b.nombre.toLowerCase().indexOf(value.toLowerCase())) {
                return -1;
            } else {
                if(a.nombre > b.nombre)
                    return 1;
                else
                    return -1;
            }
        });

        this.iniciativasBuscadas = search_results;
    }
  },
  created() {
    this.isLoading = true;

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    fetch(process.env.VUE_APP_API_ENDPOINT + "/getIniciativasPendientes/" + userId + "/" + token)
      .then((response) => response.json())
      .then((data) => {
        this.iniciativasPendientes = data
        fetch(process.env.VUE_APP_API_ENDPOINT + "/getIniciativasAdmin/" + userId + "/" + token)
          .then((response) => response.json())
          .then((data) => {
            this.iniciativas = data
            this.isLoading = false
          });
      });
  }
};
</script>


<style scoped>
.loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-items-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no-items-message p {
  font-size: 1.8rem;
}

.no-items-message span {
  margin-right: 6px;
  color: #c31bcc;
  font-size: 2rem;
}

.page-container {
  margin: 40px;
  padding: 0;
}

.section-description {
  margin-bottom: 60px;
}

.window-title {
  color: #c31bcc;
}

.rows-window__new-experiences {
  height: 500px;
  width: 100%;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 13px;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  overflow: auto;
}

.rows-window {
  height: 700px;
  width: 100%;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 13px;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  overflow:hidden;
}

.rows {
    overflow: auto;
    width: 100%;
    height: 530px;
    margin-top: 20px;
}

.searchbar-input {
  border: none;
  width: 100%;
}

.searchbar-input:focus {
  outline: none;
}

.searchbar {
  right: 250px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #919191;
  padding: 5px;
  width: 100%;
  margin-bottom: 10px;
}

.results-number{
    display: inline-block;
    font-weight: 600;
}

.order-toggles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.toggle {
    background: black;
    color: white;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    font-weight: 600;
    font-size: 0.9rem;
}

.toggle:hover,
.toggle:active {
    cursor:pointer;
    background: #c31bcc;
}

.toggle-icon {
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle;
}

</style>