<template>
<div class="form-container">
   <div class="title-header">
       <h2 class="registro-title">Registro de experiencias de emprendimiento</h2>
    </div> 
    <div class="form-fields-container">
        <form-1></form-1>
    </div>
    
</div>    
<div class="blankspace">

</div>
</template>

<script>
import Form1 from "../../components/UI/ControlPanelUI/ExperienceFormUI/Form1.vue";

export default {
components: {
    Form1
  },
}
</script>

<style scoped>

.form-container{
    background: white;
    box-shadow: 2px 5px 10px 4px rgba(27, 26, 26, 0.158);
    border-radius: 13px;
    margin: 4rem auto;
    display: flex;
    flex-grow: 0;
    max-width: 1200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    /* height: 100%; */
}

.registro-title{
    font-weight: 600;
    font-size: 1.3rem;
}

.title-header {
    border-bottom: 1px solid #e0e2e3 ;
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.form-fields-container {
    width: 100%;
    padding: 2rem 5rem 5rem 5rem;
    height: 100%;
    margin-bottom: 5px;
}

.blankspace {
    height: 40px;
}

@media (max-width: 1200px) {
    .form-container {
        max-width: 1000px;
    }

    .form-fields-container {
    padding: 3rem;
    }
}

@media (max-width: 1000px) {
    .form-container {
        max-width: 700px;
    }

    .form-fields-container {
    padding: 2rem;
    }
}

</style>