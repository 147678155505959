import { createRouter, createWebHistory } from 'vue-router';

// import HomePage from './pages/HomePage.vue';
import ExperiencesList from './pages/experiences/ExperiencesList.vue';
import ExperienceDetails from './pages/experiences/ExperienceDetails.vue';
import AdminPanel from './pages/admin/AdminPanel.vue';
import AdminExperiences from './pages/admin/AdminExperiences.vue';
import AdminUsers from './pages/admin/AdminUsers.vue';
import AdminManageExperiences from './pages/admin/AdminManageExperiences.vue';
import NotFound from './pages/NotFound.vue';
import SignIn from './pages/admin/SignIn.vue';
import ChangePassword from './pages/admin/ChangePassword.vue';
import StartPage from './pages/StartPage.vue';
import ExperienceForm from './pages/admin/ExperienceForm.vue';
import AdminProfile from './pages/admin/AdminProfile.vue';
import VerifyEmail from './pages/VerifyEmail.vue';
import PrivacyPage from './pages/PrivacyPage.vue';
import About from './pages/About.vue';
import AdminConstelations from './pages/admin/AdminConstelations.vue';
import AdminStatistics from './pages/admin/AdminStatistics.vue';
import AdminReport from './pages/admin/AdminReport.vue';
import AdminManuals from './pages/admin/AdminManuals.vue';
// import Form1 from './components/UI/ControlPanelUI/ExperienceFormUI/Form1.vue';

import { testExperiencesIds } from './constants';

import store from './store/index';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      // name: 'HomePage',
      redirect: '/experiencias',
      // component: HomePage,
    },
    {
      path: '/acerca-de-nerix',
      name: 'About',
      component: About,
    },
    {
      path: '/aviso-de-privacidad',
      name: 'PrivacyPage',
      component: PrivacyPage,
    },
    {
      path: '/experiencias',
      component: ExperiencesList,
      children: [
        {
          path: ':id',
          name: 'Experiencia detalle',
          component: ExperienceDetails,
        },
      ],
    },
    {
      path: '/sign-in',
      component: SignIn,
      name: 'Iniciar Sesión',
      meta: { requiresUnauth: true },
    },
    {
      path: '/password-change',
      component: ChangePassword,
      name: 'Cambio de contraseña',
    },
    {
      path: '/comenzar',
      component: StartPage,
      name: 'Página comenzar',
    },
    {
      path: '/experience-form',
      component: ExperienceForm,
      name: 'Formulario de registro',
      // redirect:'/experience-form',
      // children: [
      //   {
      //     path: '1',
      //     component: Form1,
      //   },
      // ],
    },
    {
      path: '/admin',
      component: AdminPanel,
      redirect: '/admin/iniciativas',
      meta: { requiresAuth: true },
      children: [
        {
          path: 'iniciativas',
          component: AdminExperiences,
          name: 'Mis iniciativas',
        },
        {
          path: 'mi-perfil',
          component: AdminProfile,
          name: 'Mi perfil',
        },
        {
          path: 'usuarios',
          component: AdminUsers,
          meta: { requiresAdmin: true },
          name: 'Administraición de usuarios',
        },
        {
          path: 'administrar-iniciativas',
          component: AdminManageExperiences,
          meta: { requiresEditor: true },
          name: 'Administración de iniciativas',
        },
        {
          path: 'constelaciones',
          component: AdminConstelations,
          name: 'Constelaciones',
        },
        {
          path: 'estadisticas',
          component: AdminStatistics,
          name: 'Estadísticas',
        },
        {
          path: 'reporte',
          component: AdminReport,
          name: 'Reporte',
        },
        {
          path: 'manuales',
          component: AdminManuals,
          name: 'Manuales',
        },
      ],
    },
    {
      path: '/verify',
      component: VerifyEmail,
    },
    {
      path: '/:notFound(.*)',
      component: NotFound,
    },
  ],
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async function(to, _, next) {
  const userId = store.getters.userId;
  const token = store.getters.token;

  let isAuth = await store.dispatch('isAuth', { userId, token });

  // Evitar que el usuario pueda acceder a las experiencias test
  if (to.matched.some(record => record.path === '/experiencias')) {
    if (testExperiencesIds.includes(to.params.id)) {
      // Check if the id is in the list of test ids
      if (isAuth && isAuth.tipo === 1) {
        next();
      } else {
        next('/notFound');
      }
    } else {
      next();
    }
  }

  if (to.meta.requiresAuth && !isAuth) {
    // Si no esta autenticado
    next('/sign-in');
  } else if (to.meta.requiresUnauth && isAuth) {
    // Si esta atenticado y quiere acceder al login
    next('/admin');
  } else if (to.meta.requiresEditor && isAuth.tipo !== 2 && isAuth.tipo !== 1) {
    next('/admin');
  } else if (to.meta.requiresAdmin && isAuth.tipo !== 1) {
    // Si requiere permisos de admin y no los tiene
    next('/admin');
  } else {
    next();
  }
});

export default router;
