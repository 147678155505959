<template>
  <div class="page-container" v-if="!isLoading">
    <add-manual-modal
      v-if="isAddModalOpen"
      :closeModal="closeModal"
    ></add-manual-modal>

    <h2>Manuales de experiencias</h2>
    <p>
      En esta sección ...
    </p>

    <!-- <a class="open-modal-button">+ Agregar Manual</a> -->

    <div class="cards-container">
      <manual-add-card
        @click="openModal()"
        v-if="user.tipo == 1"
      ></manual-add-card>

      <manual-card
        v-for="manual in manuals"
        :key="manual.id"
        :id="manual.id"
        :nombre="manual.nombre"
        :url="manual.url"
        :imagen="manual.imagen"
        :user="user"
      ></manual-card>
    </div>
  </div>
</template>

<script>
import ManualCard from '../../components/UI/ControlPanelUI/ManualsUI/ManualCard.vue';
import ManualAddCard from '../../components/UI/ControlPanelUI/ManualsUI/ManualAddCard.vue';
import AddManualModal from '../../components/UI/ControlPanelUI/ManualsUI/AddManualModal.vue';

export default {
  components: {
    ManualCard,
    ManualAddCard,
    AddManualModal,
  },
  data() {
    return {
      isLoading: false,
      iniciativas: null,
      user: null,
      isAddModalOpen: false,
      manuals: null,
    };
  },
  methods: {
    openModal() {
      this.isAddModalOpen = true;
    },
    closeModal() {
      this.isAddModalOpen = false;
    },
  },
  created() {
    this.isLoading = true;

    const userId = this.$store.getters.userId;
    const token = this.$store.getters.token;

    fetch(process.env.VUE_APP_API_ENDPOINT + '/getUser/' + userId + '/' + token)
      .then(response => response.json())
      .then(userData => {
        this.user = userData;
        this.isLoading = false;
      });

    fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getManuals/' + userId + '/' + token
    )
      .then(response => response.json())
      .then(data => {
        this.manuals = data;
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
.page-container {
  margin: 40px;
  padding: 0;
}

.open-modal-button {
  padding: 0.5rem;
  background: white;
  color: #c31bcc;
  text-decoration: none;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.205);
  display: inline-block;
}

.open-modal-button:hover {
  background: #c31bcc;
  color: white;
}

.cards-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  flex-wrap: wrap;
}
</style>
