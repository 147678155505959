<template>
  <div class="page-wrapper">
    <div>
      <img class="navi" src="../../assets/branding/NAVi_RGB-01.png" alt="" />
      <h4 class="mobile-phrase">Tu guía al emprendimiento</h4>
    </div>
    <div class="login-container">
      <transition name="slide">
        <div v-show="registrarse" class="warning">
          <p>
            Para obtener un usuario y contraseña debes de haber registrado una
            experiencia de emprendimiento. Si aún no lo has hecho,
            <router-link to="/comenzar"> regístrate aquí. </router-link>
          </p>
        </div>
      </transition>

      <h3 class="login-title">Inicio de Sesión para líderes de experiencias</h3>
      <label for="uname"><b>Correo electrónico</b></label>
      <input
        :class="{ errorInput: !isEmailValid }"
        type="text"
        placeholder="Escribe tu correo"
        name="uname"
        required
        v-model.trim="email"
        @blur="validarEmail"
      />

      <label for="psw"><b>Contraseña</b></label>
      <input
        type="password"
        placeholder="Escribe tu contraseña"
        name="psw"
        required
        v-model.trim="password"
      />

      <!-- <router-link class="button-router" to="admin"> -->
      <button @click="login">Entrar</button>
      <!-- </router-link> -->

      <p class="error" v-if="!isFormValid">
        Asegurate de ingresar valores válidos
      </p>
      <p class="error" v-if="error">
        {{ error }}
      </p>
      <router-link
        to="/experience-form"
        class="register-link"
        v-if="showRegisterLink"
        >Registra tu primera experiencia</router-link
      >
    </div>

    <a @click="registrarse = !registrarse" class="password-forgot">
      Registrarse
    </a>

    <router-link class="password-forgot margin-bottom" to="password-change">
      ¿Olvidaste tu contraseña?
    </router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      error: null,
      isLoading: false,
      isEmailValid: true,
      isFormValid: true,
      registrarse: false,
      showRegisterLink: false,
    };
  },
  methods: {
    validarEmail() {
      this.email = this.email.toLowerCase();
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.email)) {
        this.isEmailValid = false;
      } else {
        this.isEmailValid = true;
      }
    },
    validarForm() {
      this.validarEmail();
    },
    async login() {
      this.isLoading = true;
      this.isFormValid = true;

      this.validarForm();

      if (!this.isEmailValid) {
        this.isFormValid = false;
        this.isLoading = false;
        return;
      }

      fetch(process.env.VUE_APP_API_ENDPOINT + '/userExists', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.email,
        }),
      })
        .then(res => res.json())
        .then(async data => {
          if (data.exists) {
            const actionPayload = {
              email: this.email,
              password: this.password,
            };

            try {
              await this.$store.dispatch('login', actionPayload);
              this.$router.push({ path: '/admin/iniciativas' });
              this.$gtag.event('usuario', {
                event_category: 'inicio de sesión',
                event_label: 'un usuario inició sesión',
              });
            } catch (err) {
              this.error = 'El correo o la contraseña son incorrectos.';
            }

            this.isLoading = false;
          } else {
            this.error =
              'El correo ingresado no tiene una cuenta asociada, favor de registrar al menos una experiencia haciendo click en el siguiente enlace.';
            this.isLoading = false;
            this.showRegisterLink = true;
          }
        });
    },
  },
};
</script>

<style scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 4rem;
}

.welcome-page__phrase {
  font-size: 6rem;
  font-weight: bold;
  padding-top: 2.5rem;
  padding-bottom: 0.25rem;
  margin: 0.75rem;
}

.navi {
  height: 120px;
  margin-top: 60px;
}

.mobile-phrase {
  margin: 25px auto;
  font-size: 2rem;
  color: #333399;
}

.login-container {
  max-width: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-content: center;
  margin-top: 2rem;
  padding: 1rem 3rem;
  border-radius: 13px;
  background: #333399;
  box-shadow: 1px 2px 3px 2px rgba(27, 26, 26, 0.425);
}

.login-title {
  color: white;
  align-self: center;
  text-align: center;
  font-size: 1.8rem;
  max-width: 300px;
}

.login-container label {
  color: white;
  margin-top: 1rem;
}

.login-container input {
  margin-top: 0.3rem;
  border-radius: 3px;
  border: none;
  padding: 0.5rem;
}

.login-container input:focus {
  outline: none;
}

.login-container button {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  padding: 0.7rem;
  color: white;
  background: #c31bcc;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.login-container button:hover,
.login-container button:active {
  color: #c31bcc;
  background: white;
  cursor: pointer;
}

.button-router {
  width: 100%;
}

.error {
  color: white;
}

.register-link {
  color: white;
  text-align: center;
  margin: 0.5rem;
}

.errorInput {
  border: 1px solid red;
  color: red;
}

.password-forgot {
  margin-top: 1rem;
  color: #333399;
  text-decoration: underline;
  cursor: pointer;
}

.margin-bottom {
  margin-bottom: 3rem;
}

.warning {
  position: absolute;
  top: 0;
  left: 98%;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  padding: 10px 15px;
  height: 100%;
  width: 70%;
  text-align: left;
}

.warning p {
  color: #333399;
}

.warning a {
  color: #c31bcc;
}

.slide-enter-active {
  transition: all 0.3s ease-out;
  z-index: -1;
}

.slide-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  z-index: -1;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  z-index: -1;
}
</style>
