<template>
  <div class="not-found__content">
    <div class="not-found__description">
      <h3>Error 404</h3>
      <h1>La pagina que buscas no existe.</h1>
    </div>
    <div class="not-found__description">
      <router-link to="/">
        <h3 class="not-found__return-link">
          Presiona aquí para regresar al menú principal.
        </h3>
      </router-link>
    </div>
  </div>
</template>


<style scoped>
.not-found__content {
  margin: 3rem;
  padding: 1.25rem;
}

.not-found__description {
  margin: 2.5rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  width: 100%;
}

.not-found__description h1 {
  font-weight: bold;
  margin: 0.25rem 0;
  font-size: 2.25rem;
  display: inline-block;
}

.not-found__description h3 {
  margin: 0.25rem 0px;
  display: inline-block;
}

.not-found__return-link {
  font-size: 1.25rem;
  color: #c31bbc;
  text-decoration: underline;
  display: inline-block;
  position: absolute;
}

.not-found__return-link:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
</style>