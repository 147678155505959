<template>
  <div class="experience-card__container">
    <span class="material-icons md-light md-48">add</span>
    <div class="experience-card__info">
      <h2>Agregar Nuevo Manual</h2>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: null,
      token: null,
    };
  },
  created() {
    this.userId = this.$store.getters.userId;
    this.token = this.$store.getters.token;
  },
};
</script>

<style scoped>
a {
  color: black;
}

.experience-card__container {
  background: #c31bcc;
  width: 17rem;
  height: 14rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border-radius: 13px;
  margin-right: 1.25rem;
  align-items: center;
}

.experience-card__image {
  width: 100%;
  /* max-height: inherit; */
  display: block;
}

.experience-card__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  background: white;
  transform: translateY(100%);
  padding: 0.5rem;
}

.experience-card__container:hover > .experience-card__info,
.experience-card__container:active > .experience-card__into {
  transform: translateY(0%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

.experience-card__container:hover,
.experience-card__container:active {
  cursor: pointer;
}
</style>
