<template>
  <div class="row-container">
    <div class="name">
      <p><span class="bold">Nombre de experiencia: </span>{{iniciativaData.nombre}}</p>
      <p><span class="bold">Contacto: </span>{{iniciativaData.contacto}}</p>
    </div>
    <div class="name">
      <p><span class="bold">Fecha de vencimiento: </span>{{fechaVencimiento()}}<span v-if="vencida" class="material-icons md-dark md-24 warning icon">warning</span></p>
      <p><span class="bold">Tema: </span>{{selectTema(iniciativaData.id_tema)}}</p>
    </div>
    <div class="details">
      <!-- :to="'/experience-form?id=' + id + '&token=' + token" -->
      <router-link class="details-link" :to="'/experiencias/' + iniciativaData.id">
        <h5>Ver detalles</h5>
        <span class="material-icons md-dark md-24 icon">info</span>
      </router-link>
      <router-link class="details-link" :to="'/experience-form?id=' + iniciativaData.id + '&token=' + token">
        <h5>Editar</h5>
        <span class="material-icons md-dark md-24 icon">edit</span>
      </router-link>
      <a class="delete-link" @click="eliminarIniciativa(iniciativaData.id)">
        <h5>Eliminar</h5>
        <span class="material-icons md-dark md-24 icon">delete</span>
      </a>
    </div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  props: ["iniciativaData"],
  data() {
    return {
      vencida: false,
      token: null
    }
  },
  methods: {
    fechaVencimiento() {
      if(this.iniciativaData.id_estatus == 1) {
        return 'Siempre activa'
      } else {
        let fecha = dayjs(this.iniciativaData.fecha_inicio).locale('es').format('MMM D, YYYY')
        fecha = fecha.replace(/(\w)(\w*)/g,
          function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
        if(dayjs().diff(dayjs(this.iniciativaData.fecha_inicio)) > 0) {
          this.vencida = true;
        }
        return fecha
      }
      // this.experienciaData.id_estatus === 1 ? 'Siempre activa' : 'fecha'
    },
    selectTema(tema) {
      const temas = ['', 'Salud', 'Industria 4.0', 'Industria Creativa', 'Bioingenierías y Alimentos', 'Emprendimiento Público', 'Innovación Educativa', 'Multidisciplinaria']

      return temas[tema]
    },
    eliminarIniciativa(id) {
      if(confirm('Estas seguro que quieres eliminar la iniciativa')) {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        fetch(process.env.VUE_APP_API_ENDPOINT + "/aprobarIniciativa/" + userId + "/" + token + "/" + id + "/3")
          .then((response) => response.json())
          .then((res) => {
            if(res.ok) {
              alert('Eliminada con éxito')
              this.$router.go()
            } else {
              alert('Ocurrió un error por favor contacte al administrador')
            }
          });
      }
    },
  },
  created() {
    this.token = localStorage.getItem("token")
  }
}
</script>

<style scoped>
.row-container {
  display: grid;
  grid-template-columns: 0.5fr 0.3fr 0.2fr;
  column-gap: 20px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdbdb;
  padding: 15px;
  width: 100%;
}

.name {
    place-self: center left;
}

.bold {
  font-weight: 600;
}

.details {
    display: flex;
    flex-direction: column;
    place-self: center right;
    align-items: flex-end;
}

.details-link {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1.5rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.details-link h5{
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
  text-decoration: underline;
}

.details-link:hover h5,
.details-link:hover .icon{
  color: #c31bcc;
}

.delete-link {
  text-decoration: none;
  color: red;
  padding: 0.5rem 1.5rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;
}

.delete-link h5{
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
  text-decoration: underline;
}

.icon {
  margin-left: 10px;
}

.warning {
    color: rgb(221, 0, 0);
    vertical-align: bottom;
}

@media (max-width: 1300px) {
  .row-container {
  grid-template-columns: auto auto 200px;
  }

  .icon {
      margin-left: 5px;
  }

}

</style>