<template>
  <div class="row-container">
    <div class="name">
      <p><span class="bold">Nombre de líder: </span>Eugenio Garza Sada</p>
      <p><span class="bold">Contacto: </span>contacto@tec.mx</p>
    </div>
    <div class="name">
      <p><span class="bold">Número de iniciativas: </span>4</p>
      <p><span class="bold">Área: </span>Negocios</p>
    </div>
    <div class="details">
      <router-link class="details-link" to="/">
        <h5>Ver información</h5>
        <span class="material-icons md-dark md-24 icon">info</span>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
.row-container {
  display: grid;
  grid-template-columns: auto auto 300px;
  column-gap: 20px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdbdb;
  padding: 15px;
  width: 100%;
}

.name {
    place-self: center left;
}

.bold {
  font-weight: 600;
}

.details {
    display: flex;
    place-self: center right;
    align-items: center;
}

.details-link {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1.5rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.details-link h5{
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
  text-decoration: underline;
}

.icon {
  margin-left: 10px;
}

@media (max-width: 1300px) {
  .row-container {
  grid-template-columns: auto auto 200px;
  }

  .icon {
      margin-left: 5px;
  }

}

</style>