<template>
  <div class="page-container">
    <div class="backdrop" @click="popPage"></div>
    <div class="details-container">
      <div v-if="isLoading">Loading...</div>
      <experience-details-card
        :iniciativaData="iniciativa"
        v-else
      ></experience-details-card>
    </div>
  </div>
</template>

<script>
import ExperienceDetailsCard from '../../components/UI/ExperiencesUI/ExperienceDetailsCard.vue';

export default {
  components: {
    ExperienceDetailsCard,
  },
  data() {
    return {
      isLoading: true,
      iniciativa: null,
    };
  },
  methods: {
    popPage() {
      window.location.replace(
        'http://nerix.com.mx/experiencias' // ?perfil=Comunidad+Tec
      );
      // if (this.$route.query.share) {
      //   // !this.$route.query.perfil ||
      // } else {
      //   this.$router.go(-1);
      // }
    },
  },

  created() {
    const experienciaId = this.$route.params.id;

    fetch(process.env.VUE_APP_API_ENDPOINT + '/getIniciativa/' + experienciaId)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.iniciativa = data[0];
        // Registrar evento de navegación en google analytics
        this.$gtag.event('visita', {
          event_category: 'visita de experiencia',
          event_label: `se visitó la experiencia: ${data[0].nombre}`,
        });
        this.isLoading = false;
      });
  },
};
</script>

<style scoped>
html {
  height: 100%;
}

body {
  height: 100%;
}

.page-container {
  display: flex;
  justify-content: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(141, 141, 141, 0.219);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.2); */
  backdrop-filter: blur(2px);
}

.backdrop:hover {
  cursor: pointer;
  /* background: rgba(0, 0, 0, 0.15); */
}

.details-container {
  z-index: 101;
  position: absolute;
  width: 60%;
  background: whitesmoke;
  border-radius: 13px;
  overflow: visible;
}

@media (max-width: 75rem) {
  .details-container {
    width: 80%;
  }
}

@media (max-width: 50rem) {
  .details-container {
    width: 100%;
    border-radius: 0;
    margin: 0;
    top: 0;
    left: 0;
  }
}
</style>
