<template>
  <register-experience-tab class="register-tab"></register-experience-tab>
  <router-view></router-view>
  <main class="experiences-list__page-container">
    <filters @updateFilter="setFilter"></filters>
    <div v-if="isLoading">Loading...</div>
    <div v-else>
      <transition-group name="fade">
        <experience-card-row
          title="Para ti"
          class="para-ti"
          :experiencias="iniciativasParaTi"
          :temas="temas"
          :results="busquedaParaTi"
          v-if="busquedaParaTi"
        />
      </transition-group>
      <!-- :experiencias="shuffle(temas[tema.tema-1].tema + new Date().getUTCDate(), tema.experiencias)" -->
      <experience-card-row
        :title="'Próximas a suceder'"
        :experiencias="experienciasProximas"
        v-if="experienciasProximas.length > 0"
        :temas="temas"
      />
      <experience-card-row
        :title="'Convocatorias abiertas'"
        :experiencias="experienciasConvocatorias"
        :temas="temas"
        v-if="filter == 'tipo'"
      />
      <experience-card-row
        v-for="titulo in experiencias"
        :key="titulo.tituloId"
        :title="titulos[titulo.tituloId - 1].nombre"
        :experiencias="titulo.experiencias"
        :temas="temas"
        class="main-rows"
      />
    </div>
    <div class="bottom-space"></div>
  </main>
  <Footer class="footer"></Footer>
</template>

<script>
// :class="{
//         disabled:
//           temas[tema.tema - 1].id == 8 &&
//           perfil != 'Profesores y colaboradores',
//       }"
import ExperienceCardRow from '../../components/UI/ExperiencesUI/ExperienceCardRow.vue';
import Filters from '../../components/UI/ExperiencesUI/Filters.vue';
import Footer from '../../components/layout/Footer.vue';
import RegisterExperienceTab from '../../components/UI/ExperiencesUI/RegisterExperienceTab.vue';

const shuffleSeed = require('shuffle-seed');
const _ = require('lodash');

export default {
  components: {
    ExperienceCardRow,
    Filters,
    Footer,
    RegisterExperienceTab,
  },
  data() {
    return {
      isLoading: true,
      experiencias: [],
      allIniciativas: [],
      temas: [],
      categorias: [],
      etapas: [],
      estados: [],
      temasParaFiltros: [],
      filtros: { etapas: [], categorias: [], campuses: [] },
      filtrosSeleccionados: {
        etapa: 'null',
        categoria: 'null',
        campus: 'null',
      },
      filter: 'tipo',
      iniciativasParaTi: null,
      busquedaParaTi: false,
      etapasIniciativa: null,
      categoriasIniciativa: null,
      temasIniciativa: null,
      experienciasProximas: null,
      // perfil: null,
      experienciasConvocatorias: [],
      titulos: [],
    };
  },
  methods: {
    shuffle(seed, array) {
      // let date = new Date();
      let semilla = `${seed}`;
      let iniciativasShuffled = shuffleSeed.shuffle(array, semilla);
      return iniciativasShuffled;
    },
    setFilter(filt) {
      this.filter = filt;
    },
    setExperienciasByFilter(filter) {
      let newIniciativasPorTitulo = {};

      if (filter == 'tema') {
        // Setup de array de títulos para las rows
        let newTitulos = [];

        this.temas.forEach(titulo => {
          let newTituloEntry = {
            id: titulo.id,
            nombre: titulo.tema,
          };
          newTitulos.push(newTituloEntry);
        });
        this.titulos = newTitulos;

        this.titulos.forEach(titulo => {
          newIniciativasPorTitulo[titulo.id] = {
            tituloId: titulo.id,
            experiencias: [],
          };
        });

        this.allIniciativas.forEach(iniciativa => {
          let temasIniciativa = this.temasIniciativa.filter(
            tema => tema.id_iniciativa == iniciativa.id
          );
          iniciativa.temas = 0;
          temasIniciativa.forEach(tema => {
            iniciativa.temas++;
            newIniciativasPorTitulo[tema.id_tema].experiencias.push(iniciativa);
          });
        });

        for (const [, value] of Object.entries(newIniciativasPorTitulo)) {
          value.experiencias.sort(function(a, b) {
            if (a.temas > b.temas) {
              return 1;
            }
            if (a.temas < b.temas) {
              return -1;
            }
            return 0;
          });
        }
      } else if (filter == 'etapa') {
        // Setup de array de títulos para las rows
        let newTitulos = [];

        this.etapas.forEach(titulo => {
          let newTituloEntry = {
            id: titulo.id,
            nombre: titulo.etapa,
          };
          newTitulos.push(newTituloEntry);
        });
        this.titulos = newTitulos;

        this.titulos.forEach(titulo => {
          newIniciativasPorTitulo[titulo.id] = {
            tituloId: titulo.id,
            experiencias: [],
          };
        });

        this.allIniciativas.forEach(iniciativa => {
          let etapasIniciativa = this.etapasIniciativa.filter(
            etapa => etapa.id_iniciativa == iniciativa.id
          );
          iniciativa.etapas = 0;
          etapasIniciativa.forEach(etapa => {
            iniciativa.etapas++;
            newIniciativasPorTitulo[etapa.id_etapa].experiencias.push(
              iniciativa
            );
          });
        });

        for (const [, value] of Object.entries(newIniciativasPorTitulo)) {
          value.experiencias.sort(function(a, b) {
            if (a.etapas > b.etapas) {
              return 1;
            }
            if (a.etapas < b.etapas) {
              return -1;
            }
            return 0;
          });
        }
      } else if (filter == 'tipo') {
        // Setup de array de títulos para las rows
        let newTitulos = [];

        this.categorias.forEach(titulo => {
          let newTituloEntry = {
            id: titulo.id,
            nombre: titulo.categoria,
          };
          newTitulos.push(newTituloEntry);
        });
        this.titulos = newTitulos;

        this.titulos.forEach(titulo => {
          newIniciativasPorTitulo[titulo.id] = {
            tituloId: titulo.id,
            experiencias: [],
          };
        });

        this.allIniciativas.forEach(iniciativa => {
          let categoriasIniciativa = this.categoriasIniciativa.filter(
            categoria => categoria.id_iniciativa == iniciativa.id
          );
          iniciativa.categorias = 0;
          categoriasIniciativa.forEach(categoria => {
            iniciativa.categorias++;
            newIniciativasPorTitulo[categoria.id_categoria].experiencias.push(
              iniciativa
            );
          });
        });

        for (const [, value] of Object.entries(newIniciativasPorTitulo)) {
          value.experiencias.sort(function(a, b) {
            if (a.categorias > b.categorias) {
              return 1;
            }
            if (a.categorias < b.categorias) {
              return -1;
            }
            return 0;
          });
        }
      } else if (filter == 'estado') {
        // Setup de array de títulos para las rows
        let newTitulos = [];

        this.estados.forEach(titulo => {
          let newTituloEntry = {
            id: titulo.id,
            nombre: titulo.estado,
          };
          newTitulos.push(newTituloEntry);
        });
        this.titulos = newTitulos;

        this.titulos.forEach(titulo => {
          newIniciativasPorTitulo[titulo.id] = {
            tituloId: titulo.id,
            experiencias: [],
          };
        });

        this.allIniciativas.forEach(iniciativa => {
          console.log(iniciativa);
          if (iniciativa.id_estado) {
            newIniciativasPorTitulo[iniciativa.id_estado].experiencias.push(
              iniciativa
            );
          } else {
            newIniciativasPorTitulo[33].experiencias.push(iniciativa);
          }
        });
      } else {
        // Setup de array de títulos para las rows
        let newTitulos = [];

        this.categorias.forEach(titulo => {
          let newTituloEntry = {
            id: titulo.id,
            nombre: titulo.categoria,
          };
          newTitulos.push(newTituloEntry);
        });
        this.titulos = newTitulos;

        this.titulos.forEach(titulo => {
          newIniciativasPorTitulo[titulo.id] = {
            tituloId: titulo.id,
            experiencias: [],
          };
        });

        this.allIniciativas.forEach(iniciativa => {
          let categoriasIniciativa = this.categoriasIniciativa.filter(
            categoria => categoria.id_iniciativa == iniciativa.id
          );
          iniciativa.categorias = 0;
          categoriasIniciativa.forEach(categoria => {
            iniciativa.categorias++;
            newIniciativasPorTitulo[categoria.id_categoria].experiencias.push(
              iniciativa
            );
          });
        });

        for (const [, value] of Object.entries(newIniciativasPorTitulo)) {
          value.experiencias.sort(function(a, b) {
            if (a.categorias > b.categorias) {
              return 1;
            }
            if (a.categorias < b.categorias) {
              return -1;
            }
            return 0;
          });
        }
      }

      // Omite las rows que no cuentan con ninguna experiencia
      for (const row in newIniciativasPorTitulo) {
        if (newIniciativasPorTitulo[row].experiencias.length == 0) {
          newIniciativasPorTitulo = _.omit(newIniciativasPorTitulo, [row]);
        }
      }

      // Ordenar el objeto por rows con mas experiencias
      const arrayIniciativasPorTitulo = _.values(newIniciativasPorTitulo);
      arrayIniciativasPorTitulo.sort(function(a, b) {
        if (a.experiencias.length > b.experiencias.length) {
          return -1;
        }
        if (a.experiencias.length < b.experiencias.length) {
          return 1;
        }
        // a must be equal to b
        return 0;
      });

      console.log(arrayIniciativasPorTitulo);

      this.experiencias = arrayIniciativasPorTitulo;
    },
  },
  watch: {
    filtrosSeleccionados: {
      handler(value) {
        const filterArray = Object.values(value);
        if (!filterArray.some(val => val != 'null')) {
          // Checo que al menos un filtro ha sido seleccionado
          this.busquedaParaTi = false;
        } else {
          this.busquedaParaTi = true;

          const filtros = _.pickBy(value, val => {
            return val != 'null';
          });

          const iniciativasFiltradas = _.pickBy(this.allIniciativas, val => {
            let verifFiltros = { ...filtros };
            Object.keys(verifFiltros).forEach(function(key) {
              verifFiltros[key] = false;
            });

            for (const filtro in filtros) {
              const valor = filtros[filtro];
              let incluye = false;
              if (filtro == 'etapa') {
                incluye = val.etapasIniciativa.includes(valor);
              }
              if (filtro == 'categoria') {
                incluye = val.categoriasIniciativa.includes(valor);
              }
              if (filtro == 'tema') {
                incluye = val.temasIniciativa.includes(valor);
              }
              if (filtro == 'campus') {
                val.id_campus == valor || val.id_campus == 37
                  ? (incluye = true)
                  : (incluye = false);
              }
              verifFiltros[filtro] = incluye;
            }

            const valoresFinales = _.values(verifFiltros);

            return !valoresFinales.some(val => val == false);
          });

          this.iniciativasParaTi = _.values(iniciativasFiltradas);
        }
      },
      deep: true,
    },
    filter(newFilter) {
      this.setExperienciasByFilter(newFilter);
    },
  },
  created() {
    let getIniciativasURL =
      process.env.VUE_APP_API_ENDPOINT + '/getAllIniciativas';

    fetch(getIniciativasURL)
      .then(responseIniciativas => responseIniciativas.json())
      .then(dataIniciativas => {
        let iniciativas = dataIniciativas[0];
        this.allIniciativas = dataIniciativas[0];
        this.etapasIniciativa = dataIniciativas[1];
        this.categoriasIniciativa = dataIniciativas[2];
        this.temasIniciativa = dataIniciativas[3];
        this.temas = dataIniciativas[4];
        this.categorias = dataIniciativas[5];
        this.etapas = dataIniciativas[6];
        this.estados = dataIniciativas[7];

        // Acomodo inicial de experiencias (default por tipo)
        this.setExperienciasByFilter(this.filter);

        // Experiencias proximas a suceder
        let proximas = [...dataIniciativas[0]]
          .filter(
            iniciativa =>
              iniciativa.id_estatus != 1 &&
              iniciativa.id_estatus != 3 &&
              iniciativa.id_estatus != 4
          )
          .filter(
            iniciativa =>
              iniciativa.fecha_inicio >
              new Date().toISOString().substring(0, 10)
          )
          .sort((a, b) => {
            return new Date(a.fecha_inicio) - new Date(b.fecha_inicio);
          });

        this.experienciasProximas = proximas;

        // Convocatorias
        // Añadir la primera en la fila
        let convocatorias = [];
        const convocatoriaFija = iniciativas.find(
          iniciativa => iniciativa.id === 256
        );
        convocatorias.push(convocatoriaFija);

        // Añadir el resto de convocatorias
        let convocatoriasActivas = [];
        iniciativas.forEach(iniciativa => {
          if (iniciativa.id_estatus == 4) {
            convocatoriasActivas.push(iniciativa);
          }
        });
        convocatoriasActivas = convocatoriasActivas
          .filter(
            iniciativa =>
              iniciativa.id_estatus != 1 && iniciativa.id_estatus != 3
          )
          .filter(
            iniciativa =>
              iniciativa.fecha_fin > new Date().toISOString().substring(0, 10)
          )
          .sort((a, b) => {
            return new Date(a.fecha_fin) - new Date(b.fecha_fin);
          });
        this.experienciasConvocatorias = convocatorias.concat(
          convocatoriasActivas
        );

        fetch(process.env.VUE_APP_API_ENDPOINT + '/getTemas')
          .then(responseTemas => responseTemas.json())
          .then(dataTemas => {
            this.temas = dataTemas;
            this.temasParaFiltros = [...this.temas];
            this.temasParaFiltros.pop();

            fetch(process.env.VUE_APP_API_ENDPOINT + '/getFiltros')
              .then(responseFiltros => responseFiltros.json())
              .then(dataFiltros => {
                this.filtros.etapas = dataFiltros[1];
                this.filtros.categorias = dataFiltros[0];
                this.filtros.campuses = dataFiltros[2];
                this.filtros.campuses.pop();
                this.isLoading = false;
              });
          });
      });
  },
};
</script>

<style scoped>
.disabled {
  display: none;
}
.experiences-list__page-container {
  margin: 0;
  position: relative;
  /*box-shadow:3px 3px 5px 3px rgb(27 26 26 / 20%);*/
}

.fade-enter-active {
  transition: all 0.5s ease;
  opacity: 0;
}

.fade-leave-active {
  transition: all 0.7s;
}

.fade-enter-to {
  opacity: 1;
}

.fade-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}

.main-rows {
  transition: all 0.5s ease;
}

.bottom-space {
  height: 40px;
}

.footer {
  z-index: -1;
}
</style>
