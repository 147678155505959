<template>
  <div class="page-container">
    <h2>Administración de Usuarios</h2>
    <p class="section-description">
      Gestiona los usuarios que existen en la plataforma y aprueba nuevos
      usuarios para que desplieguen sus experiencias de emprendimiento en Nerix.
    </p>

    <h3 class="window-title">Modificación de Usuarios</h3>
    <div class="rows-window">
      <!-- <div class="searchbar">
        <span class="material-icons md-dark md-24">search</span>
        <input class="searchbar-input" type="text" placeholder="Buscar..." />
      </div> -->
      <existing-user-row v-for="usuario in usuarios" :key="usuario"></existing-user-row>
      <div class="no-items-message">
        <p>No se han registarado usuarios</p>
      </div>
    </div>
  </div>
</template>

<script>
import ExistingUserRow from "../../components/UI/ControlPanelUI/UsersUI/ExistingUserRow.vue";

export default {
  components: {
    ExistingUserRow,
  },
  data() {
    return {
      usuarios: []
    }
  },
};
</script>


<style scoped>
.no-items-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.no-items-message p {
  font-size: 1.8rem;
}

.no-items-message span {
  margin-right: 6px;
  color: #c31bcc;
  font-size: 2rem;
}

.page-container {
  margin: 40px;
  padding: 0;
}

.section-description {
  margin-bottom: 60px;
}

.window-title {
  color: #c31bcc;
}

.rows-window {
  height: 500px;
  width: 100%;
  margin-bottom: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 13px;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  overflow: auto;
}

.searchbar-input {
  border: none;
  width: 100%;
}

.searchbar-input:focus {
  outline: none;
}

.searchbar {
  right: 250px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #919191;
  padding: 5px;
  width: 100%;
  margin-bottom: 20px;
}
</style>