<template>
  <div class="page-container">
    <h2>Mis Experiencias</h2>
    <p>En esta sección podrás registrar o crear experiencias de emprendimiento, así como modificarlas para ajustar sus datos.
      Cualquier registro nuevo de una experiencia o su modificación deberá ser aprobada por los administradores de la plataforma. 
    </p>

    <p>
      Si quieres aprender a utilizar esta sección, te invitamos a ver nuestro videotutorial.
    </p>

    <a class="videotutorial" href="https://drive.google.com/drive/u/2/folders/1WgRBYZ4JQNcOzwqDZwijU1HC6OzEwFRx" target="_blank">Ver videotutorial</a>

    <div class="cards-container">
      <mis-experiencias-add-card></mis-experiencias-add-card>
      <mis-experiencias-edit-card v-for="iniciativa in iniciativas" :key="iniciativa.id" :logo="iniciativa.img_logo" :nombre="iniciativa.nombre" :id="iniciativa.id" :aceptada="iniciativa.aceptada" :duracion="iniciativa.duracion" :fecha_inicio="iniciativa.fecha_inicio" :estatus="iniciativa.id_estatus"></mis-experiencias-edit-card>
      <!-- <mis-experiencias-edit-card></mis-experiencias-edit-card>
      <mis-experiencias-edit-card></mis-experiencias-edit-card>
      <mis-experiencias-edit-card></mis-experiencias-edit-card>
      <mis-experiencias-edit-card></mis-experiencias-edit-card>
      <mis-experiencias-edit-card></mis-experiencias-edit-card> -->
    </div>
  </div>
</template>

<script>
import MisExperienciasEditCard from "../../components/UI/ControlPanelUI/MisExperienciasEditCard.vue"
import MisExperienciasAddCard from "../../components/UI/ControlPanelUI/MisExperienciasAddCard.vue"

export default {
  components: {
      MisExperienciasEditCard,
      MisExperienciasAddCard
  },
  data() {
    return {
      iniciativas: null
    }
  },
  created() {
    this.isLoading = true;

    const userId = this.$store.getters.userId;
    const token = this.$store.getters.token;

    fetch(process.env.VUE_APP_API_ENDPOINT + "/getIniciativasUsuario/" + userId + "/" + token)
      .then((response) => response.json())
      .then((data) => {
        this.iniciativas = data
        this.isLoading = false
      });
  },
}
</script>


<style scoped>
  
.page-container {
  margin: 40px;
  padding:0;
}

.videotutorial{
  padding: 0.5rem;
  background: white;
  color: #c31bcc;
  text-decoration: none;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.205);
  display: inline-block;
}

.videotutorial:hover {
  background: #c31bcc;
  color: white;
}

.cards-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  flex-wrap: wrap;
}

</style>