import router from '../../../router';

let timer;

export default {
  async login(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'login',
    });
  },
  async signup(context, payload) {
    return context.dispatch('auth', {
      ...payload,
      mode: 'signup',
    });
  },
  async auth(context, payload) {
    const mode = payload.mode;
    let url = process.env.VUE_APP_API_ENDPOINT + '/loginUser';

    let response;

    if (mode === 'signup') {
      url = process.env.VUE_APP_API_ENDPOINT + '/signupUser';
      response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: payload.name,
          email: payload.email,
          password: payload.password,
        }),
      });
    } else {
      response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: payload.email,
          password: payload.password,
        }),
      });
    }

    const responseData = await response.json();

    if (responseData.error) {
      const error = new Error(responseData.error);
      throw error;
    }

    if (!response.ok) {
      const error = new Error(
        responseData.message || 'Lo sentimos, hubo un error.'
      );
      throw error;
    }

    const expiresIn = 86400 * 1000;
    const expirationDate = new Date().getTime() + expiresIn;

    localStorage.setItem('token', responseData.token);
    localStorage.setItem('userId', responseData.idUsuario);
    localStorage.setItem('tokenExpiration', expirationDate);

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    context.commit('setUser', {
      token: responseData.token,
      userId: responseData.idUsuario,
    });
  },
  tryLogin(context) {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const tokenExpiration = localStorage.getItem('tokenExpiration');

    const expiresIn = +tokenExpiration - new Date().getTime();

    if (expiresIn < 0) {
      return;
    }

    timer = setTimeout(function() {
      context.dispatch('autoLogout');
    }, expiresIn);

    if (token && userId) {
      context.commit('setUser', {
        token: token,
        userId: userId,
      });
    }
  },
  logout(context) {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('tokenExpiration');

    clearTimeout(timer);

    context.commit('setUser', {
      token: null,
      userId: null,
    });

    router.push({ path: '/' });
  },
  autoLogout(context) {
    context.dispatch('logout');
    context.commit('setAutoLogout');
  },
  setUserName(context, payload) {
    context.commit('setUserName', payload);
  },
  async isAuth(_, payload) {
    const userId = payload.userId;
    const token = payload.token;

    if (userId && token) {
      const url =
        process.env.VUE_APP_API_ENDPOINT + '/getUser/' + userId + '/' + token;

      let response = await fetch(url);

      let responseData = await response.json();

      if (responseData.error) {
        return false;
      } else {
        return responseData;
      }
    } else {
      return false;
    }
  },
};
