<template>
  <div class="row-container">
    <div class="name">
      <p>
        <span class="bold">Nombre de experiencia: </span
        >{{ iniciativaData.nombre }}
      </p>
      <p><span class="bold">Contacto: </span>{{ iniciativaData.contacto }}</p>
    </div>
    <div class="details">
      <router-link
        class="details-link"
        :to="'/experiencias/' + iniciativaData.id"
      >
        <h5>Ver detalles</h5>
        <span class="material-icons md-dark md-24 icon">info</span>
      </router-link>
    </div>
    <div class="approve">
      <div class="aprobar" @click="aprobarIniciativa(iniciativaData.id)">
        <h5>Aprobar</h5>
        <span class="material-icons md-dark md-24 icon">check_circle</span>
      </div>
      <div class="rechazar" @click="rechazarIniciativa(iniciativaData.id)">
        <h5>Rechazar</h5>
        <span class="material-icons md-dark md-24 icon">cancel</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['iniciativaData'],
  methods: {
    aprobarIniciativa(id) {
      if (confirm('Estas seguro que quieres aprobar la iniciativa')) {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        fetch(
          process.env.VUE_APP_API_ENDPOINT +
            '/aprobarIniciativa/' +
            userId +
            '/' +
            token +
            '/' +
            id +
            '/1'
        )
          .then(response => response.json())
          .then(res => {
            if (res.ok) {
              alert('Aprobada con éxito');
              this.$router.go();
            } else {
              alert('Ocurrió un error por favor contacte al administrador');
            }
          });
      }
    },
    rechazarIniciativa(id) {
      if (confirm('Estas seguro que quieres rechazar la iniciativa')) {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token');

        fetch(
          process.env.VUE_APP_API_ENDPOINT +
            '/aprobarIniciativa/' +
            userId +
            '/' +
            token +
            '/' +
            id +
            '/2'
        )
          .then(response => response.json())
          .then(res => {
            if (res.ok) {
              alert('Rechazada con éxito');
              this.$router.go();
            } else {
              alert('Ocurrió un error por favor contacte al administrador');
            }
          });
      }
    },
  },
};
</script>

<style scoped>
.row-container {
  display: grid;
  grid-template-columns: auto 300px 300px;
  vertical-align: middle;
  border-bottom: 1px solid #dbdbdb;
  padding: 15px;
  width: 100%;
}

.name {
  place-self: center left;
}

.bold {
  font-weight: 600;
}

.details {
  display: flex;
  place-self: center left;
  align-items: center;
}

.details-link {
  text-decoration: none;
  color: black;
  padding: 0.5rem 1.5rem;
  display: flex;
  vertical-align: middle;
  align-items: center;
}

.details-link h5 {
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
  text-decoration: underline;
}

.icon {
  margin-left: 10px;
}

.approve {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aprobar,
.rechazar {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  place-self: center left;
  border-radius: 8px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-weight: bold;
  font-size: 1rem;
  margin-left: 10px;
}

.aprobar h5,
.rechazar h5 {
  font-weight: 600;
  font-size: 1rem;
  display: inline-block;
  margin: 0;
}

.aprobar {
  color: #00b818;
}

.rechazar {
  color: rgb(221, 0, 0);
}

.aprobar:hover,
.aprobar:active {
  color: white;
  background: #00b818;
  cursor: pointer;
}

.rechazar:hover,
.rechazar:active {
  color: white;
  background: rgb(221, 0, 0);
  cursor: pointer;
}

@media (max-width: 1300px) {
  .row-container {
    grid-template-columns: auto 150px 100px;
  }

  .approve {
    flex-direction: column;
  }
}
</style>
