<template>
    <div class="searchbar">
        <input class="searchbar-input" type="text" v-model="searchInput" @keypress.enter="realizarBusqueda(searchInput)" @click="showResults = true"/>
        <span class="material-icons md-dark md-24">search</span>
        <div class="dropdown-container" v-if="showResults" v-click-outside="onClose">
        <div class="dropdown-content">
          <div class="result" v-for="iniciativa in results" :key="iniciativa.id" @click="verDetalles(iniciativa.id)">
            <img class="result-img" :src="logoIniciativa(iniciativa.img_logo)" alt="">
            <p>{{iniciativa.nombre}}</p>
          </div>
          <!-- <p v-if="isLoading">Obteniendo resultados...</p> -->
          <p v-if="!isLoading && results.length === 0">No hay resultados para tu búsqueda</p>
        </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      searchInput: "",
      showResults: false,
      isLoading: false,
      results: []
    };
  },
  methods: {
    logoIniciativa(logo) {
      return process.env.VUE_APP_IMAGES_ENDPOINT + "/logos/" + logo;
    },
    verDetalles(id) {
      this.$router.push({path: '/experiencias/' + id})
    },
    realizarBusqueda(value) {
      if (value !== '') {
        this.showResults = true;
        this.isLoading = true;
  
        // Llamada a la base de datos
        fetch(process.env.VUE_APP_API_ENDPOINT + "/searchBar/" + value)
        .then((response) => response.json())
        .then((data) => {
          let resultados = data;

          // let test1 = resultados.findIndex(iniciativa => iniciativa.id === 1)

          // if(test1 !== -1) {
          //   resultados.splice(test1, 1);
          // }

          // let test2 = resultados.findIndex(iniciativa => iniciativa.id === 20)

          // if(test2 !== -1) {
          //   resultados.splice(test2, 1);
          // }

          this.results = resultados;
          this.isLoading = false;
        });
      } else {
        this.showResults = false;
      }
    },
    onClose() {
      this.showResults = false;
    }
  },
  watch: {
    searchInput(value) {
      this.realizarBusqueda(value);
    },
  },
};
</script>


<style scoped>

.searchbar-input {
  border: none;
  width: 100%;
}

.searchbar-input:focus {
  outline: none;
}

.searchbar {
  position: relative;
  display: flex;
  justify-content: center;
  border: 1px solid #e0e2e3;
  border-radius: 15px;
  padding: 5px;
  height: fit-content;
  background-color: white;
}

.dropdown-container {
  display: static;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 17px;
  margin-top: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  max-height: 500px;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  border-radius: 0 0 20px 20px;
  z-index: -1;
  background-color: white;
}

.dropdown-content {
  width: 100%;
  padding: 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 500px;
  background-color: #f9f9f9;
  border-radius: 0 0 20px 20px;
  background-color: white;
}

.result {
  display: flex;
  align-items: center;
  max-height: 18rem;
  padding: 1rem 0;
  text-overflow: ellipsis;
  cursor: pointer;
}

.result-img {
  width: 30%;
  margin-right: 15px;
  cursor: pointer;
}

.result p {
  width: 70%;
}

</style>