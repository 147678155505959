<template>
  <div class="navigation-bar">
    <div class="main-bar">
      <div class="navigation-bar__home-button">
        <router-link to="/">
         <img class="nerix-logo" src="../../assets/branding/NAVi_RGB-01.png" alt="">
         <img class="nerix-logo-mobile" src="../../assets/branding/Icono_RGB-01.png" alt="">
        </router-link>
      </div>    
      <searchbar v-if="mostrarNavBar" class="searchbar"></searchbar>
      <div class="right-icons">
      <router-link to="/acerca-de-nerix" class="what-is">
        ¿Qué es Nerix?
      </router-link>
      <router-link to="/sign-in" class="login-link">
        <span class="material-icons login-icon">account_circle
        </span>
      </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Searchbar from './Searchbar.vue';

export default {
  components: {
    Searchbar,
  },
  data() {
    return {
      mostrarNavBar: true,
    }
  },
  watch: {
    $route (to) {
      if(to.path === "/experiencias") {
        this.mostrarNavBar = true
      } else {
        this.mostrarNavBar = false
      }
    }
  }
};
</script>

<style scoped>
.navigation-bar {
  position: relative;
  margin: 0%;
  padding: 40px;
  height: fit-content;
  width: 100%;
  background: white;
  box-shadow: 0px 10px 21px rgba(224, 226, 227, 0.41);
}

.main-bar {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-areas: "nerix login";
  justify-content: space-between;
  vertical-align: middle;
}

.navigation-bar__home-button {
  width: 197px;
  padding: 0;
  margin: 0;
  grid-area: nerix;
}

.navigation-bar__home-button:hover,
.navigation-bar__home-button:active {
  cursor: pointer;
}

.nerix-logo {
  height: 40px;
}

.nerix-logo-mobile {
  display: none;
}

.searchbar {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 300px;
  grid-area: searchbar;
}

.login-link {
  grid-area: login;
  color: #333399;
}

.login-link:active,
.login-link:focus {
  color: #333399;
  cursor:pointer;
}

.login-icon {
  font-size: 40px;
}

.what-is {
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  color: #333399;
  margin-right: 15px;
}

.right-icons {
  display: flex;
  align-items: center;
}

@media (max-width: 60rem) {
  .searchbar {
    width: 200px;
  }
}

@media (max-width: 40rem) {
  .navigation-bar {
    padding: 20px;
  }
  
  .main-bar {
    grid-template-areas: "nerix login"
                         "searchbar searchbar";
  }

  .searchbar {
    position: relative;
    margin-top: 15px;
    width: 100%;
  }

  .navigation-bar__home-button {
    width: 120px;
  }

  .nerix {
    display: none;
  }

  .nerix-mobile {
    display: block;
    width: 35px;
  }


}
</style>