<template>
  <!-- <router-link :to="'/experiencias/' + id"> -->
  <div class="experience-card__container">
    <div class="expired-overlay" v-if="estatusExperiencia == 'Pendiente'">
      <span class="material-icons warning-icon">schedule</span>
      <p class="warning-message">En proceso de revisión</p>
      <p class="warning-instructions" style="text-align: center">
        Un adminstrador revisará tu experiencia
      </p>
    </div>

    <div class="expired-overlay" v-if="estatusExperiencia == 'Rechazada'">
      <span class="material-icons warning-icon">highlight_off</span>
      <p class="warning-message">Experiencia rechazada</p>
      <p class="warning-instructions" style="text-align: center">
        Contacta al adminstrador para que te asesore
      </p>
    </div>

    <div
      class="expired-overlay"
      v-if="expired && estatusExperiencia == 'Aceptada'"
    >
      <span class="material-icons warning-icon">warning</span>
      <p class="warning-message center mb--1">Tu experiencia ha expirado</p>
      <p class="warning-instructions center mb--2">
        Actualiza la fecha e información de tu experiencia en su siguiente
        convocatoria para que vuelva a aparecer en Nerix.
      </p>
    </div>
    <img :src="urlImagen" alt="" class="experience-card__image" />
    <div class="experience-card__info">
      <h3 class="card-info__title">{{ nombre }}</h3>
      <div class="see-edit-buttons-container">
        <router-link class="edit-button-router" :to="'/experiencias/' + id">
          <div class="edit-button">Ver</div>
        </router-link>
        <router-link
          class="edit-button-router"
          :to="'/experience-form?id=' + id + '&token=' + token"
        >
          <div class="edit-button">Editar</div>
        </router-link>
      </div>
      <div class="attendees-buttons-container">
        <a class="attendees-button add-button" @click="llevarAlGoogleDrive">
          Agregar asistencia
        </a>
        <a
          class="attendees-button download-button"
          @mouseenter="changeIconColorToPurple"
          @mouseleave="changeIconColorToWhite"
          @click="downloadFile"
        >
          <p>Descargar formato asistencia</p>
          <span class="material-icons md-light md-36">download</span>
        </a>
      </div>
    </div>
  </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  props: [
    'id',
    'nombre',
    'logo',
    'aceptada',
    'estatus',
    'fecha_inicio',
    'duracion',
  ],
  data() {
    return {
      userId: null,
      token: null,
      expired: false,
      estatusExperiencia: '',
    };
  },
  computed: {
    urlImagen() {
      const baseLogosUrl = process.env.VUE_APP_IMAGES_ENDPOINT + '/logos/';

      return baseLogosUrl + this.logo;
    },
  },
  methods: {
    llevarAlGoogleDrive() {
      window.open(
        'https://drive.google.com/drive/folders/1Wyf4XRfGgnVgdKgwyKcvKac81tYuMhVT?usp=share_link'
      );
      history.go(0);
    },
    changeIconColorToPurple() {
      const icon = document.querySelector('.attendees-button span');
      icon.style.color = '#c31bbc';
    },
    changeIconColorToWhite() {
      const icon = document.querySelector('.attendees-button span');
      icon.style.color = '#fff';
    },
    downloadFile() {
      fetch(
        process.env.VUE_APP_API_ENDPOINT + '/descargarPlantillaAsistencia'
      ).then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = 'Formato_De_Asistencia_NombreDeLaExperiencia.xlsx';
          a.click();
        });
      });
    },
  },
  created() {
    this.token = this.$store.getters.token;

    // Revisar el estatus de la experiencia
    if (this.aceptada == 0) {
      this.estatusExperiencia = 'Pendiente';
    } else if (this.aceptada == 1) {
      this.estatusExperiencia = 'Aceptada';
    } else if (this.aceptada == 2) {
      this.estatusExperiencia = 'Rechazada';
    } else {
      this.estatusExperiencia = 'Eliminada';
    }

    // Revisar si la fecha de inicio es mayor a la fecha actual mas duracion
    if (this.estatus == 1) {
      this.expired = false;
    } else {
      if (this.fecha_inicio) {
        const fechaInicio = new Date(this.fecha_inicio);
        const fechaActual = new Date();
        const fechaVencimiento = new Date(
          fechaInicio.getTime() + this.duracion * 24 * 60 * 60 * 1000
        );
        if (fechaActual > fechaVencimiento) {
          this.expired = true;
        } else {
          this.expired = false;
        }
      }
    }
  },
};
</script>

<style scoped>
a {
  color: black;
}

.center {
  text-align: center;
}

.mb--1 {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.mb--2 {
  margin-top: -1rem;
}

.experience-card__container {
  width: 17rem;
  height: 14rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border-radius: 13px;
  margin-right: 1.25rem;
  align-items: center;
}

.experience-card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

.experience-card__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: white;
  transform: translateY(100%);
  padding: 0.5rem;
}

.experience-card__container:hover > .experience-card__info,
.experience-card__container:active > .experience-card__into {
  transform: translateY(0%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

/* .experience-card__container:hover,
.experience-card__container:active {
  cursor: pointer;
} */

.card-info__title {
  font-size: 1.25rem;
  margin: 0.1rem 0;
  white-space: pre-wrap;
}

.see-edit-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin: 0 1rem;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.edit-button-router {
  text-decoration: none;
  z-index: 100;
}

.edit-button {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0.5rem 0;
  padding: 0.4rem;
  /* height: 3rem; */
  font-size: 1.1rem;
  border-radius: 7px;
  background: #c31bbc;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.459);
  z-index: 100;
}

.edit-button:hover {
  cursor: pointer;
  color: #c31bbc;
  background: white;
}

.edit-button:focus {
  border-style: none;
}

.attendees-buttons-container {
  /* display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 0.5rem; 
  margin: 0 1rem; */
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.attendees-button {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0.4rem;
  font-size: 1.1rem;
  border-radius: 7px;
  background: #c31bbc;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.459);
  z-index: 100;
}

.attendees-button:hover {
  cursor: pointer;
  color: #c31bbc;
  background: white;
}

.add-button {
  width: 100%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.download-button {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
}

.download-button p {
  margin: 0;
}

.download-button span {
  font-size: 1.7rem;
}

.download-button:hover {
  cursor: pointer;
}

.attendees-button:focus {
  border-style: none;
}

.expired-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
}

.warning-icon {
  color: #fec973;
  font-size: 2rem;
}

.warning-message {
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
}

.warning-instructions {
  text-decoration: underline;
  color: white;
}

@media (max-width: 50rem) {
  .experience-card__container {
    width: 13rem;
    height: 11rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }
}

@media (max-width: 40rem) {
  .experience-card__container {
    width: 11rem;
    height: 9rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }
}
</style>
