<template>
  <!-- <router-link :to="'/experiencias/' + id"> -->
  <div class="manual-card__container">
    <img
      :src="urlImagen"
      alt=""
      class="manual-card__image"
      @click="llevarAlUrl"
    />
    <div class="manual-card__info">
      <h3 class="card-info__title">{{ nombre }}</h3>
      <div class="manual-buttons-container">
        <a
          class="manual-button delete-button"
          @click="deleteManual"
          v-if="user.tipo == 1"
        >
          Eliminar Manual
        </a>
      </div>
    </div>
  </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  props: ['id', 'nombre', 'url', 'imagen', 'user'],
  data() {
    return {};
  },
  computed: {
    urlImagen() {
      const baseLogosUrl = process.env.VUE_APP_IMAGES_ENDPOINT + '/manuals/';

      return baseLogosUrl + this.imagen;
    },
  },
  methods: {
    llevarAlUrl() {
      window.open(this.url);
      history.go(0);
    },
    deleteManual() {
      const userId = this.$store.getters.userId;
      const token = this.$store.getters.token;
      const manualId = this.id;
      console.log(manualId);

      fetch(
        process.env.VUE_APP_API_ENDPOINT +
          '/deleteManual/' +
          userId +
          '/' +
          token +
          '/' +
          manualId
      ).then(() => {
        history.go(0);
      });
    },
  },
};
</script>

<style scoped>
a {
  color: black;
}

.center {
  text-align: center;
}

.mb--1 {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.mb--2 {
  margin-top: -1rem;
}

.manual-card__container {
  width: 17rem;
  height: 14rem;
  display: inline-block;
  overflow: hidden;
  position: relative;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border-radius: 13px;
  margin-right: 1.25rem;
  align-items: center;
}

.manual-card__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;
}

.manual-card__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  background: white;
  transform: translateY(100%);
  padding: 0.5rem;
}

.manual-card__container:hover > .manual-card__info,
.manual-card__container:active > .manual-card__into {
  transform: translateY(0%);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}

/* .manual-card__container:hover,
.manual-card__container:active {
  cursor: pointer;
} */

.card-info__title {
  font-size: 1.25rem;
  margin: 0.1rem 0;
  white-space: pre-wrap;
}

.see-edit-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  margin: 0 1rem;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.edit-button-router {
  text-decoration: none;
  z-index: 100;
}

.edit-button {
  width: 100%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0.5rem 0;
  padding: 0.4rem;
  /* height: 3rem; */
  font-size: 1.1rem;
  border-radius: 7px;
  background: #c31bbc;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.459);
  z-index: 100;
}

.edit-button:hover {
  cursor: pointer;
  color: #c31bbc;
  background: white;
}

.edit-button:focus {
  border-style: none;
}

.manual-buttons-container {
  /* display: grid;
  grid-template-columns: 5fr 1fr;
  grid-gap: 0.5rem; 
  margin: 0 1rem; */
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.manual-button {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0.4rem;
  font-size: 1.1rem;
  border-radius: 7px;
  background: #dc0000;
  color: white;
  font-weight: 600;
  border: none;
  box-shadow: 1px 1px 2px 1px rgba(85, 85, 85, 0.459);
  z-index: 100;
}

.manual-button:hover {
  cursor: pointer;
  color: #dc0000;
  background: white;
}

.delete-button {
  width: 100%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.download-button {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
}

.download-button p {
  margin: 0;
}

.download-button span {
  font-size: 1.7rem;
}

.download-button:hover {
  cursor: pointer;
}

.manual-button:focus {
  border-style: none;
}

.expired-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
}

.warning-icon {
  color: #fec973;
  font-size: 2rem;
}

.warning-message {
  font-weight: 600;
  color: white;
  font-size: 1.2rem;
}

.warning-instructions {
  text-decoration: underline;
  color: white;
}

@media (max-width: 50rem) {
  .manual-card__container {
    width: 13rem;
    height: 11rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }
}

@media (max-width: 40rem) {
  .manual-card__container {
    width: 11rem;
    height: 9rem;
  }

  .card-info__title {
    font-size: 1rem;
    margin: 0.1rem 0;
    white-space: pre-wrap;
  }
}
</style>
