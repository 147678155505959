<template>
    <div v-if="!isHidden" class="register-tab">
        <router-link to="/comenzar" class="tab-link">
         <h5 class="title" id="long-title">Registra tu experiencia de emprendimiento aquí</h5>
         <h5 class="title" id="short-title">Registra tu experiencia aquí</h5>
         </router-link>
         <span v-on:click="isHidden = true" class="material-icons title">close</span>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isHidden: false,
        }
    }

}
</script>


<style scoped>

.register-tab{
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: 0px 10px;
    background-color: white;
    box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    align-items: center;
    align-self: center;
    margin-bottom: 15px;
}

.tab-link {
    text-decoration: none;
   
}

.title {
    font-weight: 600;
    color: #333399;
    font-weight: 1.2rem;
}

.title:hover {
    color: #c31bcc;
}

span {
    cursor: pointer;
    margin-left: 10px;
}

#short-title{
    display: none;
}

@media (max-width:780px) {
    #long-title {
        display: none;
    }

    #short-title {
        display: block;
    }

}

@media (max-width:550px) {

    .title {
        margin: 10px 0;
        gap: 15px;
    }

}

</style>