<template>
  <div v-if="isLoading">Cargando información...</div>

  <div
    class="field-wrapper"
    v-if="
      !isUploading &&
        !(formStatus === 'ok' || formStatus === 'editada') &&
        !isLoading
    "
  >
    <label :class="{ invalid: !isNombreValid }" for="ininame">
      <b>Nombre de la experiencia de emprendimiento</b>
      <p class="error-message" v-if="!isNombreValid">
        No olvides llenar este campo.
      </p>
    </label>
    <input
      type="text"
      placeholder="Escribe el nombre de la experiencia de emprendimiento"
      name="ininame"
      required
      v-model="nombre"
      @blur="validarNombre"
    />

    <span v-if="!addNewFromUserMode">
      <label :class="{ invalid: !isNombreResponsableValid }" for="leadername"
        ><b>Nombre del responsable o líder de la experiencia</b></label
      >
      <p class="error-message" v-if="!isNombreResponsableValid">
        No olvides llenar este campo.
      </p>
      <input
        style="width: 100%"
        type="text"
        placeholder="Escribe el nombre del responsable de la experiencia de emprendimiento"
        name="leadername"
        required
        v-model="nombreResponsable"
        @blur="validarNombreResponsable"
        :disabled="editMode"
      />
    </span>

    <span v-if="!addNewFromUserMode">
      <label :class="{ invalid: !isContactoValid }" for="contact"
        ><b>Contacto (correo electrónico)</b></label
      >
      <p class="details">
        Este correo será utilizado para generar tu usuario. <br />
        Solo de aceptan los siguientes dominios: @tec.mx, @itesm.mx,
        @tecsalud.mx, @tecmilenio.mx, @sorteostec.mx, @exatec.mx,
        @exatecmilenio.mx, @servicios.tec.mx
      </p>
      <p class="error-message" v-if="!isContactoValid && !contactExists">
        No olvides llenar este campo. Introduce un correo válido.
      </p>
      <p class="error-message" v-if="!isContactoValid && contactExists">
        Este correo ya ha sido utilizado para registrar una experiencia de
        emprendimiento. Inicia sesión para añadir más experiencias.
      </p>
      <input
        style="width: 100%"
        type="text"
        placeholder="Escribe el correo para contacto"
        name="contact"
        v-model="contacto"
        required
        @blur="validarContacto"
        :disabled="editMode"
      />
    </span>

    <span v-if="!addNewFromUserMode">
      <label :class="{ invalid: !isCelContactoValid }" for="phone"
        ><b>Teléfono celular de contacto (opcional)</b></label
      >
      <p class="details">
        Esta información es para la administración y el número de celular no
        será publicado abiertamente.
      </p>
      <p class="error-message" v-if="!isCelContactoValid">
        No olvides llenar este campo. Introduce un número celular válido.
      </p>
      <input
        style="width: 100%"
        type="text"
        placeholder="Teléfono celular"
        name="phone"
        required
        v-model="celContacto"
        @blur="validarCelContacto"
        :disabled="editMode"
      />
    </span>

    <label
      :class="{ invalid: !isImgBackgroundValid }"
      for="background"
      class="field-title bottom-space"
      >Selecciona la imagen que mejor represente a tu experiencia
      <p class="details">
        Esta imagen se utilizará como banner en tu página de experiencia,
        incluye una foto en horizontal (modo paisaje). Si no tienes banner,
        puedes subir la imagen de tu logo aquí también. Tamaño recomendado
        (900x320px)
      </p>
      <p class="error-message" v-if="!isImgBackgroundValid">
        No olvides llenar este campo. Solo permitimos formatos .jpg .png y .jpeg
      </p>
      <div class="file-button">
        <span class="material-icons md-dark md-36 file-icon">file_upload</span>
        <p class="file-text">Seleccionar archivo</p>
      </div>
      <input
        @change="seleccionarBackground"
        class="file-input"
        type="file"
        id="background"
        name="background"
        accept=".jpg, .jpeg, .png"
      />
      <p
        v-if="oldImgBackground && oldImgBackground === imgBackground"
        class="file-name"
      >
        Imagen seleccionada: {{ oldImgBackground }}
      </p>
      <p
        v-if="imgBackground && oldImgBackground !== imgBackground"
        class="file-name"
      >
        Imagen seleccionada: {{ imgBackground.name }}
      </p>
      <a :href="imgBackgroundPath" download class="download" v-if="editMode"
        >Descargar imagen</a
      >
    </label>

    <label
      :class="{ invalid: !isImgLogoValid }"
      for="logo"
      class="field-title bottom-space"
      >Selecciona el logo de tu experiencia
      <p class="details">
        Recomendamos fondos de colores. Si subes un PNG transparente, asegúrate
        que tu logo no sea blanco. Si no tienes logo, puedes subir la imagen del
        banner aquí también. Tamaño recomendado (280x230px)
      </p>
      <p class="error-message" v-if="!isImgLogoValid">
        No olvides llenar este campo. Solo permitimos formatos .jpg .png y .jpeg
      </p>
      <div class="file-button">
        <span class="material-icons md-dark md-36 file-icon">file_upload</span>
        <p class="file-text">Seleccionar archivo</p>
      </div>
      <input
        @change="seleccionarLogo"
        class="file-input"
        type="file"
        id="logo"
        name="logo"
        accept=".jpg, .jpeg, .png"
      />
      <p v-if="oldImgLogo && oldImgLogo === imgLogo" class="file-name">
        Imagen seleccionada: {{ oldImgLogo }}
      </p>
      <p v-if="imgLogo && oldImgLogo !== imgLogo" class="file-name">
        Imagen seleccionada: {{ imgLogo.name }}
      </p>
      <a :href="imgLogoPath" download class="download" v-if="editMode"
        >Descargar imagen</a
      >
    </label>

    <!-- <h3 :class="{ invalid: !isTemaValid }" class="field-title">
      Tema de Experiencia
    </h3>
    <p class="error-message" v-if="!isTemaValid">
      No olvides seleccionar una opción.
    </p>
    <div class="radio-form-3">
      <div v-for="formTema in formTemas" :key="formTema.id">
        <input
          :id="'tema' + formTema.tema"
          name="topic"
          type="radio"
          :value="formTema.id"
          v-model="tema"
          @change="validarTema"
        />
        <label :for="'tema' + formTema.tema">{{ formTema.tema }}</label>
      </div>
    </div> -->

    <h3 :class="{ invalid: !isTemasValid }" class="field-title">
      Temas de experiencia
    </h3>
    <p class="details">Selecciona todas las que apliquen</p>
    <p class="error-message" v-if="!isTemasValid">
      No olvides seleccionar una opción.
    </p>
    <div class="checkbox-form-3">
      <div v-for="formTema in formTemas" :key="formTema.id">
        <template v-if="formTema.id !== 7 && formTema.id !== 8">
          <input
            :id="'temas' + formTema.tema"
            name="topic"
            type="checkbox"
            :value="formTema.id"
            v-model="temas"
            @change="validarTemas"
          />
          <label :for="'temas' + formTema.tema">{{ formTema.tema }}</label>
        </template>
      </div>
    </div>

    <h3 :class="{ invalid: !isEnfoquesValid }" class="field-title">Enfoques</h3>
    <p class="details">Selecciona todas las que apliquen</p>
    <p class="error-message" v-if="!isEnfoquesValid">
      Selecciona al menos un enfoque
    </p>
    <div class="checkbox-form">
      <div v-for="formEnfoque in formEnfoques" :key="formEnfoque.id">
        <input
          :id="'enfoque' + formEnfoque.enfoque"
          name="enfoque"
          type="checkbox"
          :value="formEnfoque.id"
          v-model="enfoques"
          @change="validarEnfoques"
        />
        <label :for="'enfoque' + formEnfoque.enfoque">{{
          formEnfoque.enfoque
        }}</label>
      </div>
    </div>

    <h3 :class="{ invalid: !isCategoriasValid }" class="field-title">
      Categorías
    </h3>
    <p class="details">Selecciona todas las que apliquen</p>
    <p class="error-message" v-if="!isCategoriasValid">
      Selecciona al menos una categoría
    </p>
    <div class="checkbox-form-2">
      <div v-for="formCategoria in formCategorias" :key="formCategoria.id">
        <input
          :id="'categoria' + formCategoria.categoria"
          name="category"
          type="checkbox"
          :value="formCategoria.id"
          v-model="categorias"
          @change="validarCategorias"
        />
        <label :for="'categoria' + formCategoria.categoria">{{
          formCategoria.categoria
        }}</label>
      </div>
    </div>

    <label :class="{ invalid: !isDescCortaValid }" for="shortdescription"
      ><b>Descripción Corta (máximo 300 caracteres)</b></label
    >
    <p class="error-message" v-if="!isDescCortaValid">
      No olvides llenar tu descripción corta.
    </p>
    <textarea
      name=""
      id=""
      cols="30"
      rows="5"
      placeholder="Escribe la descripción corta del evento en 300 caracteres"
      spellcheck="true"
      maxlength="300"
      v-model="descCorta"
      @blur="validarDescCorta"
    ></textarea>

    <label :class="{ invalid: !isDescLargaValid }" for="longdescription"
      ><b>Descripción detallada (máximo 2500 caracteres)</b></label
    >
    <p class="error-message" v-if="!isDescLargaValid">
      No olvides llenar tu descripción detallada.
    </p>
    <textarea
      name=""
      id=""
      cols="30"
      rows="10"
      placeholder="Escribe la descripción detallada del evento en 2500 caracteres"
      spellcheck="true"
      maxlength="2500"
      v-model="descLarga"
      @blur="validarDescLarga"
    ></textarea>

    <h3 :class="{ invalid: !isLinksExperienciaValid }" class="field-title">
      Links o URLs de tu experiencia
    </h3>
    <p class="details">
      Selecciona todas las opciones con las que cuente tu experiencia.
    </p>
    <p class="error-message" v-if="!isLinksExperienciaValid">
      Tu experiencia debe contener al menos un link.
    </p>
    <div class="radio-form" id="privacy-container">
      <input
        type="checkbox"
        id="link-pagina"
        name="links"
        value="pagina"
        v-model="linksExperiencia"
        @change="validarLinksExperiencia"
      />
      <label for="link-pagina">Página web o Red social</label>
      <input
        type="checkbox"
        id="link-registro"
        name="links"
        value="registro"
        v-model="linksExperiencia"
        @change="validarLinksExperiencia"
      />
      <label for="link-registro">Página de registro</label>
    </div>

    <label
      :class="{ invalid: !isURLValid }"
      for="webpage"
      v-if="linksExperiencia.includes('pagina')"
    >
      <b>Página web o Red social </b></label
    >
    <p class="details" v-if="linksExperiencia.includes('pagina')">
      Si colocas tu red social, asegúrate de que sea el URL que lleve al perfil
    </p>
    <p
      class="error-message"
      v-if="!isURLValid && linksExperiencia.includes('pagina')"
    >
      No olvides llenar este campo. Asegúrate de que estés introduciendo un URL
      o link.
    </p>
    <input
      type="url"
      placeholder="Escribe el URL de tu página web o perfil de red social"
      name="webpage"
      required
      v-model="url"
      @blur="validarURL"
      v-if="linksExperiencia.includes('pagina')"
    />

    <label
      :class="{ invalid: !isRegistroLinkValid }"
      for="registration"
      v-if="linksExperiencia.includes('registro')"
      ><b>Página de registro</b></label
    >
    <p class="details" v-if="linksExperiencia.includes('registro')">
      Si colocas tu página de registro, asegúrate de que sea el URL que lleve al
      registro
    </p>
    <p
      class="error-message"
      v-if="!isRegistroLinkValid && linksExperiencia.includes('registro')"
    >
      No olvides llenar este campo. Asegúrate de que estés introduciendo un URL
      o link.
    </p>
    <input
      type="url"
      placeholder="Escribe el URL de tu página de registro"
      name="registration"
      required
      v-model="registroLink"
      @blur="validarRegistroLink"
      v-if="linksExperiencia.includes('registro')"
    />

    <h3 :class="{ invalid: !isModalidadValid }" class="field-title">
      Modalidad
    </h3>
    <p class="error-message" v-if="!isModalidadValid">Elige una opción.</p>
    <ul>
      <li
        :class="{ active: modalidad === formModalidad.id }"
        v-for="formModalidad in formModalidades"
        :key="formModalidad.id"
      >
        <button type="button" @click="activate(formModalidad.id, 'modalidad')">
          {{ formModalidad.modalidad }}
        </button>
      </li>
    </ul>

    <h3 :class="{ invalid: !isRegionValid }" class="field-title">Región</h3>
    <p class="details">
      Este campo indica a qué región pertenece tu experiencia.
    </p>
    <p class="error-message" v-if="!isRegionValid">Selecciona una opción.</p>
    <div class="radio-form-3">
      <div v-for="formRegion in formRegiones" :key="formRegion.id">
        <input
          :id="'region' + formRegion.region"
          name="region"
          type="radio"
          :value="formRegion.id"
          v-model="region"
          @change="validarRegion"
        />
        <label :for="'region' + formRegion.region">{{
          formRegion.region
        }}</label>
      </div>
    </div>

    <label :class="{ invalid: !isCampusValid }" for="campus" class="field-title"
      >Campus</label
    >
    <p class="details">
      Campus en donde se puede vivir la experiencia. (Si la experiencia es
      virtual y se puede consumir desde cualquier campus por favor elije
      "Nacional")
    </p>
    <p class="error-message" v-if="!isCampusValid">Escoge un campus.</p>
    <select id="campus" name="campus" v-model="campus" @change="validarCampus">
      <option value="null">-- Selecciona el campus --</option>
      <option
        :value="formCampusItem.id"
        v-for="formCampusItem in formCampus"
        :key="formCampusItem.id"
      >
        {{ formCampusItem.campus }}
      </option>
    </select>

    <label :class="{ invalid: !isEstadoValid }" for="estado" class="field-title"
      >Estado - Ciudad</label
    >
    <p class="details">
      Ciudad en donde se puede vivir la experiencia. (Si la experiencia es
      virtual y se puede consumir desde cualquier estado por favor elije
      "Nacional" o "Internacional")
    </p>
    <p class="error-message" v-if="!isEstadoValid">Escoge un Estado.</p>
    <select
      id="estado"
      name="estado"
      v-model="estado"
      @change="
        event => {
          validarEstado();
          obtenerCiudades(event);
          ciudad = 'null';
          if (event.target.value == '33') {
            ciudad = '2472';
          } else if (event.target.value == '34') {
            ciudad = '2473';
          }
        }
      "
    >
      <option value="null">-- Selecciona el estado --</option>
      <option value="33">Nacional</option>
      <option value="34">Internacional</option>
      <option
        :value="formEstadoItem.id"
        v-for="formEstadoItem in formEstados"
        :key="formEstadoItem.id"
      >
        {{ formEstadoItem.estado }}
      </option>
    </select>

    <p class="error-message" v-if="!isCiudadValid">Escoge una Ciudad.</p>
    <select
      id="ciudad"
      name="ciudad"
      v-model="ciudad"
      @change="
        () => {
          validarCiudad();
        }
      "
    >
      <option value="null">-- Selecciona la ciudad --</option>
      <option
        :value="formCiudadItem.id"
        v-for="formCiudadItem in formCiudades"
        :key="formCiudadItem.id"
      >
        {{ formCiudadItem.ciudad }}
      </option>
    </select>

    <h3 :class="{ invalid: !isAlcanceGeograficoValid }" class="field-title">
      Alance geográfico
    </h3>
    <p class="error-message" v-if="!isAlcanceGeograficoValid">
      Selecciona una opción.
    </p>
    <div class="radio-form-3">
      <div
        v-for="formAlcanceGeografico in formAlcancesGeograficos"
        :key="formAlcanceGeografico.id"
      >
        <input
          :id="'alcance' + formAlcanceGeografico.alcance_geografico"
          name="reach"
          type="radio"
          :value="formAlcanceGeografico.id"
          v-model="alcanceGeografico"
          @change="validarAlcanceGeografico"
        />
        <label :for="'alcance' + formAlcanceGeografico.alcance_geografico">{{
          formAlcanceGeografico.alcance_geografico
        }}</label>
      </div>
    </div>

    <h3 :class="{ invalid: !isEstatusValid }" class="field-title">
      Periodicidad de la experiencia
    </h3>
    <p class="details">
      La periodicidad de experiencia indica al usuario el tiempo que está
      disponible la experiencia de emprendimiento.
    </p>
    <p class="error-message" v-if="!isEstatusValid">Selecciona una opción.</p>
    <div class="radio-form">
      <div>
        <input
          id="status-active"
          name="status"
          type="radio"
          :value="1"
          v-model="estatus"
          @change="validarEstatus"
        />
        <label for="status-active"
          >Siempre activa
          <span class="hint"
            >(aún con esta opción, la experiencia debe renovarse y actualizarse
            al menos cada año)</span
          ></label
        >
      </div>
      <div>
        <input
          id="status-periodic"
          name="status"
          type="radio"
          :value="2"
          v-model="estatus"
          @change="validarEstatus"
        />
        <label for="status-periodic"
          >Con fecha establecida
          <span class="hint"
            >(sucede solo en cierta fecha ej: Bootcamp, Concurso)</span
          ></label
        >
      </div>
      <div>
        <input
          id="status-process"
          name="status"
          type="radio"
          :value="3"
          v-model="estatus"
          @change="validarEstatus"
        />
        <label for="status-process"
          >En proceso de lanzamiento
          <span class="hint"
            >(opción para dar a conocer la experiencia antes de lanzarla,
            aparecerá leyenda "Fecha por confirmar")</span
          ></label
        >
      </div>
      <div>
        <input
          id="status-convo"
          name="status"
          type="radio"
          :value="4"
          v-model="estatus"
          @change="validarEstatus"
        />
        <label for="status-convo"
          >Convocatoria con fecha de inicio y cierre
          <span class="hint"
            >(es una convocatoria con fecha de inicio y cierre ej: Concursos y
            convocatorias)</span
          ></label
        >
      </div>
    </div>

    <label
      :class="{ invalid: !isFechaInicioValid }"
      for="start-date"
      v-if="showOptPeriodicidad"
    >
      <b v-if="estatus != 4">¿En qué fecha empieza tu experiencia?</b>
      <b v-if="estatus == 4">Fecha de apertura de convocatoria</b>
    </label>
    <p class="error-message" v-if="!isFechaInicioValid && showOptPeriodicidad">
      No olvides escoger la fecha de apertura de convocatoria.
    </p>
    <p v-if="editMode && showOptPeriodicidad">
      Fecha anterior: {{ fechaEditMode }}
    </p>
    <input
      type="date"
      placeholder="Selecciona la fecha de inicio"
      name="start-date"
      required
      v-model="fechaInicio"
      @blur="validarFechaInicio"
      v-if="showOptPeriodicidad"
    />

    <label
      :class="{ invalid: !isFechaFinValid }"
      for="end-date"
      v-if="showOptPeriodicidad && estatus == 4"
    >
      <b>Fecha de cierre de convocatoria</b>
    </label>
    <p
      class="error-message"
      v-if="!isFechaFinValid && showOptPeriodicidad && estatus == 4"
    >
      No olvides escoger la fecha de cierre de convocatoria.
    </p>
    <p v-if="editMode && showOptPeriodicidad && estatus == 4">
      Fecha anterior: {{ fechaFinEditMode }}
    </p>
    <input
      type="date"
      placeholder="Selecciona la fecha de cierre"
      name="end-date"
      required
      v-model="fechaFin"
      @blur="validarFechaFin"
      v-if="showOptPeriodicidad && estatus == 4"
    />

    <label
      :class="{ invalid: !isDuracionValid }"
      for="duration"
      v-if="showOptPeriodicidad && !(estatus == 4)"
      ><b>Indica la duración de tu experiencia</b></label
    >
    <p class="details" v-if="showOptPeriodicidad && !(estatus == 4)">
      La duración se indica en días. Si tu evento dura algunas horas, no te
      preocupes, lo puedes detallar en la descripción de tu experiencia.
    </p>
    <p
      class="error-message"
      v-if="!isDuracionValid && showOptPeriodicidad && !(estatus == 4)"
    >
      No olvides llenar este campo. Introduce una duración válida.
    </p>
    <input
      type="number"
      min="1"
      max="365"
      placeholder="Duración"
      name="duration"
      required
      v-model.number="duracion"
      @blur="validarDuracion"
      v-if="showOptPeriodicidad && !(estatus == 4)"
    />

    <h3 :class="{ invalid: !isEscuelasValid }" class="field-title">
      ¿A qué Escuela o Área Tec pertenece tu experiencia?
    </h3>
    <p class="error-message" v-if="!isEscuelasValid">
      Selecciona al menos una opción.
    </p>
    <div class="checkbox-form-3">
      <div v-for="formEscuela in formEscuelas" :key="formEscuela.id">
        <input
          :id="'escuela' + formEscuela.escuela"
          name="area"
          type="checkbox"
          :value="formEscuela.id"
          v-model="escuelas"
          @change="validarEscuelas"
        />
        <label :for="'escuela' + formEscuela.escuela">{{
          formEscuela.escuela
        }}</label>
      </div>
    </div>

    <h3 :class="{ invalid: !isEtapasValid }" class="field-title">
      Etapa de Emprendimiento a la que va dirigida tu experiencia
    </h3>
    <p class="error-message" v-if="!isEtapasValid">
      Selecciona al menos una etapa.
    </p>
    <div class="checkbox-form-3">
      <div v-for="formEtapa in formEtapas" :key="formEtapa.id">
        <input
          :id="'etapa' + formEtapa.etapa"
          name="focus"
          type="checkbox"
          :value="formEtapa.id"
          v-model="etapas"
          @change="validarEtapas"
        />
        <label :for="'etapa' + formEtapa.etapa">{{ formEtapa.etapa }}</label>
      </div>
    </div>

    <h3 :class="{ invalid: !isPublicoValid }" class="field-title">
      Público al que va dirigida tu experiencia
    </h3>
    <p class="details">
      Si deseas seleccionar todos los campos selecciona la opción "Comunidad
      Tec"
    </p>
    <p class="error-message" v-if="!isPublicoValid">
      Selecciona al menos un público.
    </p>
    <div class="checkbox-form" id="publico">
      <div v-for="formPublico in formPublicos" :key="formPublico.id">
        <input
          :id="'publico' + formPublico.publico"
          name="public"
          type="checkbox"
          :value="formPublico.id"
          v-model="publico"
          @change="validarPublico"
        />
        <label :for="'publico' + formPublico.publico">
          {{ formPublico.publico }}
        </label>
      </div>
    </div>

    <h3 :class="{ invalid: !isODSValid }" class="field-title">
      ODS impactados por tu experiencia
    </h3>
    <p class="details">
      Consulta los Objetivos de Desarrollo Sustentable de la ONU
      <a
        class="hint-link"
        href="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/"
        target="_blank"
        >aquí</a
      >
    </p>
    <p class="error-message" v-if="!isODSValid">
      Selecciona al menos una opción. Si seleccionas el campo "ninguno", no
      puedes elegir otras opciones.
    </p>
    <div class="checkbox-form" id="ods">
      <div v-for="formODS in formODSs" :key="formODS.id">
        <input
          :id="'ods' + formODS.ods"
          name="ods"
          type="checkbox"
          :value="formODS.id"
          v-model="ods"
          @change="validarODS"
        />
        <label :for="'ods' + formODS.ods">
          <span v-if="formODS.numero == -1"> Ninguno </span>
          <span v-else> ODS {{ formODS.numero }} - {{ formODS.ods }} </span>
        </label>
      </div>
    </div>

    <label :class="{ invalid: !isPalabrasClaveValid }" for="keywords"
      ><b>Palabras clave</b></label
    >
    <p class="details">
      Lista de palabras separadas por coma (Ej: biología, nanotecnología,
      ciencia).
    </p>
    <p class="error-message" v-if="!isPalabrasClaveValid">
      Introduce al menos una palabra clave.
    </p>
    <input
      type="text"
      placeholder="Escribe las palabras clave que describan a tu evento"
      name="keywords"
      required
      v-model="palabrasClave"
      @blur="validarPalabrasClave"
    />

    <h3 :class="{ invalid: !isAlcanceTRLValid }" class="field-title">
      Alcance TRL
    </h3>
    <p class="details">
      Es la etapa de maduración tecnológica y aplica para experiencias que
      impulsan el desarrollo científico o tecnológico. Consulta más información
      acerca de este rubro
      <a
        class="hint-link"
        href="https://www.cibnor.gob.mx/images/stories/covisti/ott_cepat/Niveles_de_Maduracion_Tecnologica.pdf"
        target="_blank"
        >aquí</a
      >
    </p>
    <p class="error-message" v-if="!isAlcanceTRLValid">
      Selecciona una opción.
    </p>
    <ul class="trl-buttons">
      <li
        :class="{ active: alcance_trl === formAlcanceTRL.id }"
        v-for="formAlcanceTRL in formAlcancesTRL"
        :key="formAlcanceTRL.id"
      >
        <button
          type="button"
          @click="activate(formAlcanceTRL.id, 'alcance_trl')"
        >
          {{ formAlcanceTRL.alcance_trl_irl }}
        </button>
      </li>
    </ul>

    <label :class="{ invalid: !isAliadosValid }" for="partners"
      ><b>Aliados</b></label
    >
    <p class="details">
      Lista de actores externos al TEC que apoyan tu experiencia (Ej: socios
      formadores, instituciones, organizaciones) separados por coma.
    </p>
    <p class="error-message" v-if="!isAliadosValid">
      Introduce al menos un aliado.
    </p>
    <input
      type="text"
      placeholder="Escribe el nombre de los aliados que se relacionen con tu experiencia"
      name="partners"
      v-model="aliados"
      required
      @blur="validarAliados"
    />

    <h3 :class="{ invalid: !isPremioValid }" class="field-title">
      ¿Tu experiencia cuenta con premio?
    </h3>
    <p class="error-message" v-if="!isPremioValid">Selecciona una opción.</p>
    <ul>
      <li :class="{ active: premio === 2 }">
        <button type="button" @click="activate(2, 'premio')">Monetario</button>
      </li>
      <li :class="{ active: premio === 1 }">
        <button type="button" @click="activate(1, 'premio')">En especie</button>
      </li>
      <li :class="{ active: premio === 4 }">
        <button type="button" @click="activate(4, 'premio')">No Aplica</button>
      </li>
    </ul>

    <h3 :class="{ invalid: !isPrecioValid }" class="field-title">
      ¿Tu experiencia tiene precio de entrada?
    </h3>
    <p class="error-message" v-if="!isPrecioValid">Selecciona una opción.</p>
    <ul class="two-buttons">
      <li :class="{ active: precio == 'S' }">
        <button type="button" @click="activate('S', 'precio')">
          Con costo
        </button>
      </li>
      <li :class="{ active: precio == 'N' }">
        <button type="button" @click="activate('N', 'precio')">
          Sin costo
        </button>
      </li>
    </ul>

    <h3 :class="{ invalid: !isInfraestructurasValid }" class="field-title">
      ¿Cuentas con infraestructura para tu experiencia?
    </h3>
    <p class="error-message" v-if="!isInfraestructurasValid">
      Selecciona al menos una opción. Si selecciona el campo "Sin
      infraestructura", no puedes elegir otras opciones.
    </p>
    <div class="checkbox-form">
      <div
        v-for="formInfraestructura in formInfraestructuras"
        :key="formInfraestructura.id"
      >
        <input
          :id="'infra' + formInfraestructura.infraestructura"
          name="infraestructura"
          type="checkbox"
          :value="formInfraestructura.id"
          v-model="infraestructuras"
          @change="validarInfraestructuras"
        />
        <label :for="'infra' + formInfraestructura.infraestructura">{{
          formInfraestructura.infraestructura
        }}</label>
      </div>
    </div>

    <h3 class="field-title">
      ¿Deseas que tu experiencia se promocione en Emprendimiento Tec? (Opcional)
    </h3>
    <div class="radio-form" id="privacy-container">
      <input
        type="checkbox"
        id="difusion-emprendimiento-tec"
        v-model="difusionEmprendimiendoTec"
      />
      <label for="difusion-emprendimiento-tec"
        >Si, deseo que mi experiencia se difunda por las redes sociales de
        Emprendimiento Tec.</label
      >
    </div>

    <label
      :class="{ invalid: !isImgFlyerValid }"
      for="flyer"
      class="field-title bottom-space"
      v-if="difusionEmprendimiendoTec"
      >Sube aquí tu flyer de la experiencia
      <p class="details">
        Esta imagen será con la que se promocionará tu experiencia a través de
        las redes de Emprendimiento Tec.
      </p>
      <p class="error-message" v-if="!isImgFlyerValid">
        No olvides llenar este campo. Solo permitimos formatos .jpg .png y .jpeg
      </p>
      <div class="file-button">
        <span class="material-icons md-dark md-36 file-icon">file_upload</span>
        <p class="file-text">Seleccionar archivo</p>
      </div>
      <input
        @change="seleccionarFlyer"
        class="file-input"
        type="file"
        id="flyer"
        name="flyer"
        accept=".jpg, .jpeg, .png"
      />
      <p v-if="imgFlyer" class="file-name">
        Imagen seleccionada: {{ imgFlyer.name }}
      </p>
    </label>

    <h3 :class="{ invalid: !isAvisoPrivacidadValid }" class="field-title">
      Aviso de Privacidad
    </h3>
    <p class="error-message" v-if="!isAvisoPrivacidadValid">
      Debes aceptar el aviso de privacidad.
    </p>
    <div class="radio-form" id="privacy-container">
      <input
        type="checkbox"
        id="aviso-de-privacidad"
        v-model="avisoPrivacidad"
        @change="validarAvisoPrivacidad"
      />
      <label for="aviso-de-privacidad"
        >Estoy de acuerdo con el
        <a
          href="https://tec.mx/es/aviso-de-privacidad-instituto-de-emprendimiento"
          target="_blank"
          >aviso de privacidad</a
        >
        y uso de mis datos personales.</label
      >
    </div>

    <p class="error-message" v-if="!isFormValid">
      Revisa los campos antes de volver a subir tu formulario.
    </p>
    <div class="preview-cancel-container">
      <input
        @click="preview"
        id="preview-button"
        type="button"
        value="Previsualizar"
      />
      <input
        v-if="editMode"
        @click="volver"
        id="cancel-button"
        type="button"
        value="Cancelar"
      />
    </div>
    <input
      @click="submitForm"
      id="submit-button"
      type="button"
      value="Finalizar"
    />
  </div>

  <div class="field-wrapper" v-if="isUploading">
    <h2 class="loading">Estamos enviando tus datos...</h2>
  </div>

  <div class="field-wrapper" v-if="formStatus === 'ok'">
    <h2 class="loading">La iniciativa se ha registrado con éxito.</h2>
    <a href="/experience-form" v-if="!addNewFromUserMode"
      ><button style="font-size: 1.5rem">Volver a la form</button></a
    >
    <a href="/admin" v-else
      ><button style="font-size: 1.5rem">
        Volver a la página de administración
      </button></a
    >
  </div>

  <div class="field-wrapper" v-if="formStatus === 'editada'">
    <h2 class="loading">
      La experiencia se ha editado con éxito y enviado a revisión, para agilizar
      este proceso ponte en contacto con un adminsitrador.
    </h2>
    <a href="/admin"
      ><button style="font-size: 1.5rem">
        Volver a la página de administración
      </button></a
    >
  </div>

  <div class="preview-container" v-if="showPreview">
    <div class="backdrop" @click="hidePreview"></div>
    <div class="details-container">
      <div v-if="isLoading">Loading...</div>
      <experience-details-card
        :iniciativaData="iniciativa"
        @closePreview="hidePreview"
        v-else
      ></experience-details-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import ExperienceDetailsCard from '../../ExperiencesUI/ExperienceDetailsCard.vue';

export default {
  components: {
    ExperienceDetailsCard,
  },
  data() {
    return {
      // Control de vista
      isUploading: false,
      isLoading: false,
      editMode: false,
      addNewFromUserMode: false,
      fechaEditMode: null,
      fechaFinEditMode: null,
      resetFecha: false,
      showPreview: false,

      // Opciones del form
      formTemas: [],
      formEnfoques: [],
      formCategorias: [],
      formModalidades: [],
      formCampus: [],
      formEscuelas: [],
      formEtapas: [],
      formPublicos: [],
      formODSs: [],
      formEstatuses: [],
      formRegiones: [],
      formAlcancesTRL: [],
      formAlcancesGeograficos: [],
      formInfraestructuras: [],
      formEstados: [],
      formCiudades: [],

      // Botones
      modalidad: null,
      alcance_trl: null,
      premio: null,
      precio: null,

      // Datos Form
      nombre: '',
      tema: null,
      temas: [],
      enfoques: [],
      categorias: [],
      descCorta: '',
      descLarga: '',
      linksExperiencia: [],
      url: '',
      registroLink: '',
      nombreResponsable: '',
      campus: 'null',
      estado: 'null',
      ciudad: 'null',
      fechaInicio: null,
      fechaFin: null,
      duracion: 1,
      escuelas: [],
      etapas: [],
      publico: [],
      prevPublico: [],
      ods: [],
      contacto: '',
      celContacto: '',
      estatus: null,
      palabrasClave: '',
      region: null,
      aliados: '',
      alcanceGeografico: null,
      infraestructuras: [],

      // Difusion
      difusionEmprendimiendoTec: false,

      // Aviso de privacidad
      avisoPrivacidad: false,

      // imagenes
      imgBackground: null,
      imgLogo: null,
      imgFlyer: null,
      oldImgBackground: null,
      oldImgLogo: null,

      // Validaciones
      isNombreValid: true,
      isImgBackgroundValid: true,
      isImgLogoValid: true,
      isTemaValid: true,
      isTemasValid: true,
      isEnfoquesValid: true,
      isCategoriasValid: true,
      isDescCortaValid: true,
      isDescLargaValid: true,
      isLinksExperienciaValid: true,
      isURLValid: true,
      isRegistroLinkValid: true,
      isModalidadValid: true,
      isNombreResponsableValid: true,
      isContactoValid: true,
      contactExists: false,
      isCelContactoValid: true,
      isCampusValid: true,
      isEstadoValid: true,
      isCiudadValid: true,
      isFechaInicioValid: true,
      isFechaFinValid: true,
      isDuracionValid: true,
      isEscuelasValid: true,
      isEtapasValid: true,
      isPublicoValid: true,
      isODSValid: true,
      isEstatusValid: true,
      isPalabrasClaveValid: true,
      isRegionValid: true,
      isAlcanceTRLValid: true,
      isAliadosValid: true,
      isPremioValid: true,
      isPrecioValid: true,
      isAlcanceGeograficoValid: true,
      isInfraestructurasValid: true,
      isImgFlyerValid: true,
      isAvisoPrivacidadValid: true,

      // Validación global
      isFormValid: true,
      formStatus: null,

      // Opcion periodicidad
      showOptPeriodicidad: true,
    };
  },
  computed: {
    iniciativa() {
      let premio;
      if (this.premio == 1) {
        premio = 'En especie';
      } else if (this.premio == 2) {
        premio = 'Monetario';
      } else {
        premio = 'Sin premio';
      }

      // TODO Mandar estado y ciudad a preview (esperar a que todas las experiencias tengan esado y ciudad)

      return {
        campus: this.formCampus[this.campus - 1].campus,
        contacto: this.contacto,
        desc_corta: this.descCorta,
        desc_larga: this.descLarga,
        duracion: this.duracion,
        estatus: this.formEstatuses[this.estatus - 1].estatus,
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
        id_estatus: this.estatus,
        img_background: this.imgBackground,
        img_logo: this.imgLogo,
        modalidad: this.formModalidades[this.modalidad - 1].modalidad,
        nombre: this.nombre,
        palabras_clave: this.palabrasClave,
        precio: this.precio,
        premio: premio,
        tema: this.formTemas[this.tema],
        url: this.url,
        link_registro: this.registroLink,
        preview: true,
      };
    },
    imgBackgroundPath() {
      let path = process.env.VUE_APP_IMAGES_ENDPOINT + '/backgrounds/';
      if (
        this.oldImgBackground &&
        this.oldImgBackground === this.imgBackground
      ) {
        path += this.oldImgBackground;
      } else {
        path += this.imgBackground.name;
      }
      return path;
    },
    imgLogoPath() {
      let path = process.env.VUE_APP_IMAGES_ENDPOINT + '/logos/';
      if (this.oldImgLogo && this.oldImgLogo === this.imgLogo) {
        path += this.oldImgLogo;
      } else {
        path += this.imgLogo.name;
      }
      return path;
    },
  },
  watch: {
    estatus: function(val) {
      if (val === 1 || val === 3) {
        this.showOptPeriodicidad = false;
        this.resetFecha = true;
        const diaDeHoy = new Date();
        const fechaHoy =
          diaDeHoy.getFullYear() +
          '-' +
          (diaDeHoy.getMonth() + 1) +
          '-' +
          diaDeHoy.getDate();
        this.fechaInicio = fechaHoy;
        this.duracion = 365;
      } else {
        this.showOptPeriodicidad = true;
        if (!this.editMode || this.resetFecha) {
          this.fechaInicio = null;
          this.duracion = 1;
        }
      }
    },
    fechaEditMode() {
      if (this.fechaEditMode) {
        let fecha = this.fechaEditMode.split('/');
        this.fechaInicio = fecha[2] + '-' + fecha[1] + '-' + fecha[0];
      }
    },
    fechaFinEditMode() {
      if (this.fechaFinEditMode) {
        let fecha = this.fechaFinEditMode.split('/');
        this.fechaFin = fecha[2] + '-' + fecha[1] + '-' + fecha[0];
      }
    },
  },
  methods: {
    obtenerCiudades(event) {
      const estadoId = event.target.value;

      fetch(process.env.VUE_APP_API_ENDPOINT + '/getCiudades/' + estadoId)
        .then(response => response.json())
        .then(data => {
          this.formCiudades = data;
        });
    },
    // Asignar valores de botones
    activate(option, input) {
      if (input === 'modalidad') {
        this.modalidad = option;
        this.validarModalidad();
      } else if (input === 'alcance_trl') {
        this.alcance_trl = option;
        this.validarAlcanceTRL();
      } else if (input === 'premio') {
        this.premio = option;
        this.validarPremio();
      } else if (input === 'precio') {
        this.precio = option;
        this.validarPrecio();
      }
    },
    // Asignar imagenes
    seleccionarBackground(event) {
      this.imgBackground = event.target.files[0];
      this.validarImgBackground();
    },
    seleccionarLogo(event) {
      this.imgLogo = event.target.files[0];
      this.validarImgLogo();
    },
    seleccionarFlyer(event) {
      this.imgFlyer = event.target.files[0];
      this.validarImgFlyer();
    },
    // Funciones de validación
    validarNombre() {
      if (!this.nombre) {
        this.isNombreValid = false;
      } else {
        this.isNombreValid = true;
      }
    },
    validarImgBackground() {
      if (!this.imgBackground) {
        this.isImgBackgroundValid = false;
      } else {
        if (this.imgBackground.name) {
          if (
            this.imgBackground.name.includes('.png') ||
            this.imgBackground.name.includes('.jpg') ||
            this.imgBackground.name.includes('.jpeg')
          ) {
            this.isImgBackgroundValid = true;
          } else {
            this.isImgBackgroundValid = false;
          }
        } else {
          this.isImgBackgroundValid = true;
        }
      }
    },
    validarImgLogo() {
      if (!this.imgLogo) {
        this.isImgLogoValid = false;
      } else {
        if (this.imgLogo.name) {
          if (
            this.imgLogo.name.includes('.png') ||
            this.imgLogo.name.includes('.jpg') ||
            this.imgLogo.name.includes('.jpeg')
          ) {
            this.isImgLogoValid = true;
          } else {
            this.isImgLogoValid = false;
          }
        } else {
          this.isImgLogoValid = true;
        }
      }
    },
    validarTema() {
      this.isTemaValid = true;
      // if (!this.tema) {
      //   this.isTemaValid = false;
      // } else {
      // }
    },
    validarTemas() {
      if (this.temas.length === 0) {
        this.isTemasValid = false;
      } else {
        this.isTemasValid = true;
      }
    },
    validarEnfoques() {
      if (this.enfoques.length === 0) {
        this.isEnfoquesValid = false;
      } else {
        this.isEnfoquesValid = true;
      }
    },
    validarCategorias() {
      if (this.categorias.length === 0) {
        this.isCategoriasValid = false;
      } else {
        this.isCategoriasValid = true;
      }
    },
    validarDescCorta() {
      if (!this.descCorta) {
        this.isDescCortaValid = false;
      } else {
        this.isDescCortaValid = true;
      }
    },
    validarDescLarga() {
      if (!this.descLarga) {
        this.isDescLargaValid = false;
      } else {
        this.isDescLargaValid = true;
      }
    },
    validarLinksExperiencia() {
      if (!this.linksExperiencia) {
        this.isLinksExperienciaValid = false;
      } else {
        if (this.linksExperiencia.length === 0) {
          this.isLinksExperienciaValid = false;
        } else {
          this.isLinksExperienciaValid = true;
        }
      }

      // Resetear variables si se deselecciona
      if (
        this.linksExperiencia[0] !== 'pagina' &&
        this.linksExperiencia[1] !== 'pagina'
      ) {
        this.isURLValid = true;
        this.url = '';
      }

      if (
        this.linksExperiencia[0] !== 'registro' &&
        this.linksExperiencia[1] !== 'registro'
      ) {
        this.isRegistroLinkValid = true;
        this.registroLink = '';
      }
    },
    validarURL() {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator

      if (!pattern.test(this.url)) {
        this.isURLValid = false;
      } else {
        this.isURLValid = true;
      }
    },
    validarRegistroLink() {
      var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator

      if (!pattern.test(this.registroLink)) {
        this.isRegistroLinkValid = false;
      } else {
        this.isRegistroLinkValid = true;
      }
    },
    validarModalidad() {
      if (!this.modalidad) {
        this.isModalidadValid = false;
      } else {
        this.isModalidadValid = true;
      }
    },
    validarNombreResponsable() {
      if (!this.nombreResponsable) {
        this.isNombreResponsableValid = false;
      } else {
        this.isNombreResponsableValid = true;
      }
    },
    validarContacto() {
      this.contacto = this.contacto.toLowerCase();
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.contacto)) {
        this.isContactoValid = false;
      } else {
        const dominiosAceptados = [
          'sorteostec.mx',
          'tec.mx',
          'itesm.mx',
          'tecsalud.mx',
          'tecmilenio.mx',
          'exatec.mx',
          'exatecmilenio.mx',
          'servicios.tec.mx',
          'gmail.com',
        ];
        const dominio = this.contacto.split('@')[1];

        if (dominiosAceptados.find(dom => dom === dominio)) {
          // Checar si ya existe un usuario con ese correo solo si es nuevo usuario
          if (!this.addNewFromUserMode && !this.editMode) {
            fetch(process.env.VUE_APP_API_ENDPOINT + '/userExists', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: this.contacto,
              }),
            })
              .then(res => res.json())
              .then(data => {
                if (data.exists) {
                  this.isContactoValid = false;
                  this.contactExists = true;
                } else {
                  this.isContactoValid = true;
                  this.contactExists = false;
                }
              });
          } else {
            this.isContactoValid = true;
            this.contactExists = false;
          }
        } else {
          this.isContactoValid = false;
        }
      }
    },
    validarCelContacto() {
      if (this.cel_contacto == 'null' || this.cel_contacto == null) {
        this.isCelContactoValid = true;
        return;
      }

      const re = /^(\d+-?)+\d+$/;
      if (!re.test(this.celContacto)) {
        if (!this.celContacto) {
          this.isCelContactoValid = true;
        } else {
          this.isCelContactoValid = false;
        }
      } else {
        this.isCelContactoValid = true;
      }
    },
    validarCampus() {
      if (!this.campus || this.campus === 'null') {
        this.isCampusValid = false;
      } else {
        this.isCampusValid = true;
      }
    },
    validarEstado() {
      if (!this.estado || this.estado === 'null') {
        this.isEstadoValid = false;
      } else {
        this.isEstadoValid = true;
      }
    },
    validarCiudad() {
      if (!this.ciudad || this.ciudad === 'null') {
        this.isCiudadValid = false;
      } else {
        this.isCiudadValid = true;
      }
    },
    validarFechaInicio() {
      if (!this.fechaInicio) {
        this.isFechaInicioValid = false;
      } else {
        this.isFechaInicioValid = true;
      }
    },
    validarFechaFin() {
      if (this.estatus != 4) {
        this.isFechaFinValid = true;
      } else {
        if (!this.fechaFin) {
          this.isFechaFinValid = false;
        } else {
          this.isFechaFinValid = true;
        }
      }
    },
    validarDuracion() {
      if (this.duracion < 1 || this.duracion > 365) {
        this.isDuracionValid = false;
      } else {
        this.isDuracionValid = true;
      }
    },
    validarEscuelas() {
      if (this.escuelas.length === 0) {
        this.isEscuelasValid = false;
      } else {
        this.isEscuelasValid = true;
      }
    },
    validarEtapas() {
      if (this.etapas.length === 0) {
        this.isEtapasValid = false;
      } else {
        this.isEtapasValid = true;
      }
    },
    validarPublico() {
      if (this.publico.length === 0) {
        this.isPublicoValid = false;
      } else {
        if (!this.prevPublico.includes(5) && this.publico.includes(5)) {
          this.publico = [1, 2, 3, 4, 5, 6, 7];
          this.prevPublico = this.publico;
          this.isPublicoValid = true;
          return;
        }

        if (
          this.prevPublico.includes(1) &&
          this.prevPublico.includes(2) &&
          this.prevPublico.includes(3) &&
          this.prevPublico.includes(4) &&
          this.prevPublico.includes(5) &&
          this.prevPublico.includes(6) &&
          this.prevPublico.includes(7) &&
          !this.publico.includes(5)
        ) {
          this.publico = [];
          this.prevPublico = this.publico;
          this.isPublicoValid = false;
          return;
        }

        if (
          this.publico.includes(1) &&
          this.publico.includes(2) &&
          this.publico.includes(3) &&
          this.publico.includes(4) &&
          this.publico.includes(6) &&
          this.publico.includes(7)
        ) {
          this.publico.push(5);
        } else if (
          this.prevPublico.includes(1) &&
          this.prevPublico.includes(2) &&
          this.prevPublico.includes(3) &&
          this.prevPublico.includes(4) &&
          this.prevPublico.includes(5) &&
          this.prevPublico.includes(6) &&
          this.prevPublico.includes(7)
        ) {
          this.publico = this.publico.filter(pub => pub !== 5);
        }

        this.isPublicoValid = true;
      }
      this.prevPublico = this.publico;
    },
    validarODS() {
      if (this.ods.length === 0) {
        this.isODSValid = false;
      } else {
        if (this.ods.length > 1 && this.ods.includes(18)) {
          this.isODSValid = false;
        } else {
          this.isODSValid = true;
        }
      }
    },
    validarEstatus() {
      if (!this.estatus) {
        this.isEstatusValid = false;
      } else {
        this.isEstatusValid = true;
      }
    },
    validarPalabrasClave() {
      if (!this.palabrasClave) {
        this.isPalabrasClaveValid = false;
      } else {
        this.isPalabrasClaveValid = true;
      }
    },
    validarRegion() {
      if (!this.region) {
        this.isRegionValid = false;
      } else {
        this.isRegionValid = true;
      }
    },
    validarAlcanceTRL() {
      if (!this.alcance_trl) {
        this.isAlcanceTRLValid = false;
      } else {
        this.isAlcanceTRLValid = true;
      }
    },
    validarAliados() {
      if (!this.aliados) {
        this.isAliadosValid = false;
      } else {
        this.isAliadosValid = true;
      }
    },
    validarPremio() {
      if (!this.premio) {
        this.isPremioValid = false;
      } else {
        this.isPremioValid = true;
      }
    },
    validarPrecio() {
      if (!this.precio) {
        this.isPrecioValid = false;
      } else {
        this.isPrecioValid = true;
      }
    },
    validarAlcanceGeografico() {
      if (!this.alcanceGeografico) {
        this.isAlcanceGeograficoValid = false;
      } else {
        this.isAlcanceGeograficoValid = true;
      }
    },
    validarInfraestructuras() {
      if (this.infraestructuras.length === 0) {
        this.isInfraestructurasValid = false;
      } else {
        if (
          this.infraestructuras.length > 1 &&
          this.infraestructuras.includes(3)
        ) {
          this.isInfraestructurasValid = false;
        } else {
          this.isInfraestructurasValid = true;
        }
      }
    },
    validarImgFlyer() {
      if (!this.imgFlyer) {
        this.isImgFlyerValid = false;
      } else {
        if (this.imgFlyer.name) {
          if (
            this.imgFlyer.name.includes('.png') ||
            this.imgFlyer.name.includes('.jpg') ||
            this.imgFlyer.name.includes('.jpeg')
          ) {
            this.isImgFlyerValid = true;
          } else {
            this.isImgFlyerValid = false;
          }
        } else {
          this.isImgFlyerValid = true;
        }
      }
    },
    validarAvisoPrivacidad() {
      this.isAvisoPrivacidadValid = this.avisoPrivacidad;
    },
    validarForm() {
      this.validarNombre();
      this.validarImgBackground();
      this.validarImgLogo();
      this.validarTema();
      this.validarTemas();
      this.validarEnfoques();
      this.validarCategorias();
      this.validarDescCorta();
      this.validarDescLarga();
      this.validarLinksExperiencia();
      // Solo validar url o linkRegistro si fue seleccionado el checkbox
      if (
        this.linksExperiencia[0] == 'pagina' ||
        this.linksExperiencia[1] == 'pagina'
      ) {
        this.validarURL();
      }
      if (
        this.linksExperiencia[0] == 'registro' ||
        this.linksExperiencia[1] == 'registro'
      ) {
        this.validarRegistroLink();
      }
      this.validarModalidad();
      this.validarNombreResponsable();
      this.validarContacto();
      this.validarCelContacto();
      this.validarCampus();
      this.validarEstado();
      this.validarCiudad();
      this.validarFechaInicio();
      this.validarFechaFin();
      this.validarDuracion();
      this.validarEscuelas();
      this.validarEtapas();
      this.validarPublico();
      this.validarODS();
      this.validarEstatus();
      this.validarPalabrasClave();
      this.validarRegion();
      this.validarAlcanceTRL();
      this.validarAliados();
      this.validarPremio();
      this.validarPrecio();
      this.validarAlcanceGeografico();
      this.validarInfraestructuras();
      if (this.difusionEmprendimiendoTec) {
        this.validarImgFlyer();
      }
      this.validarAvisoPrivacidad();
    },
    preview() {
      this.validarForm();

      if (
        !(
          this.isNombreValid &&
          this.isImgBackgroundValid &&
          this.isImgLogoValid &&
          this.isTemaValid &&
          this.isTemasValid &&
          this.isEnfoquesValid &&
          this.isCategoriasValid &&
          this.isDescCortaValid &&
          this.isDescLargaValid &&
          this.isLinksExperienciaValid &&
          this.isURLValid &&
          this.isRegistroLinkValid &&
          this.isModalidadValid &&
          this.isNombreResponsableValid &&
          this.isContactoValid &&
          this.isCelContactoValid &&
          this.isCampusValid &&
          this.isEstadoValid &&
          this.isCiudadValid &&
          this.isFechaInicioValid &&
          this.isFechaFinValid &&
          this.isDuracionValid &&
          this.isEscuelasValid &&
          this.isEtapasValid &&
          this.isPublicoValid &&
          this.isODSValid &&
          this.isEstatusValid &&
          this.isPalabrasClaveValid &&
          this.isRegionValid &&
          this.isAlcanceTRLValid &&
          this.isAliadosValid &&
          this.isPremioValid &&
          this.isPrecioValid &&
          this.isAlcanceGeograficoValid &&
          this.isInfraestructurasValid &&
          this.isImgFlyerValid &&
          this.isAvisoPrivacidadValid
        )
      ) {
        this.isFormValid = false;
        return;
      }

      this.isFormValid = true;

      this.showPreview = true;
      window.scrollTo(0, 0);
    },
    hidePreview() {
      this.showPreview = false;
      window.scrollTo(0, document.body.scrollHeight);
    },
    submitForm() {
      this.isUploading = true;

      this.validarForm();

      if (
        !(
          this.isNombreValid &&
          this.isImgBackgroundValid &&
          this.isImgLogoValid &&
          this.isTemaValid &&
          this.isTemasValid &&
          this.isEnfoquesValid &&
          this.isCategoriasValid &&
          this.isDescCortaValid &&
          this.isDescLargaValid &&
          this.isLinksExperienciaValid &&
          this.isURLValid &&
          this.isRegistroLinkValid &&
          this.isModalidadValid &&
          this.isNombreResponsableValid &&
          this.isContactoValid &&
          this.isCelContactoValid &&
          this.isCampusValid &&
          this.isEstadoValid &&
          this.isCiudadValid &&
          this.isFechaInicioValid &&
          this.isFechaFinValid &&
          this.isDuracionValid &&
          this.isEscuelasValid &&
          this.isEtapasValid &&
          this.isPublicoValid &&
          this.isODSValid &&
          this.isEstatusValid &&
          this.isPalabrasClaveValid &&
          this.isRegionValid &&
          this.isAlcanceTRLValid &&
          this.isAliadosValid &&
          this.isPremioValid &&
          this.isPrecioValid &&
          this.isAlcanceGeograficoValid &&
          this.isInfraestructurasValid &&
          this.isImgFlyerValid &&
          this.isAvisoPrivacidadValid
        )
      ) {
        this.isFormValid = false;
        this.isUploading = false;
        return;
      }

      this.isFormValid = true;

      const fd = new FormData();
      fd.append('nombre', this.nombre);
      fd.append('tema', this.tema);
      fd.append('temas', this.temas);
      fd.append('enfoques', this.enfoques);
      fd.append('categorias', this.categorias);
      fd.append('decCorta', this.descCorta);
      fd.append('descLarga', this.descLarga);
      fd.append('url', this.url);
      fd.append('linkRegistro', this.registroLink);
      fd.append('modalidad', this.modalidad);
      fd.append('nombreResponsable', this.nombreResponsable);
      fd.append('contacto', this.contacto);
      fd.append('celContacto', this.celContacto);
      // TODO eliminar campus (despues de que todas las experiencias tengan estado y ciudad)
      fd.append('campus', this.campus);
      fd.append('estado', this.estado);
      fd.append('ciudad', this.ciudad);
      fd.append('fechaInicio', this.fechaInicio);
      fd.append('fechaFin', this.fechaFin);
      fd.append('duracion', this.duracion);
      fd.append('escuelas', this.escuelas);
      fd.append('etapas', this.etapas);
      fd.append('publico', this.publico);
      fd.append('ods', this.ods);
      fd.append('estatus', this.estatus);
      fd.append('palabrasClave', this.palabrasClave);
      fd.append('region', this.region);
      fd.append('alcanceTRL', this.alcance_trl);
      fd.append('aliados', this.aliados);
      fd.append('premio', this.premio);
      fd.append('precio', this.precio);
      fd.append('alcanceGeografico', this.alcanceGeografico);
      fd.append('infraestructuras', this.infraestructuras);
      fd.append('difusionEmprendimiendoTec', this.difusionEmprendimiendoTec);

      if (this.editMode) {
        const params = this.$route.query;

        if (this.imgBackground !== this.oldImgBackground) {
          fd.append(
            'background',
            this.imgBackground,
            new Date().getTime() + '-' + this.imgBackground.name
          );
        }

        if (this.imgLogo !== this.oldImgLogo) {
          fd.append(
            'logo',
            this.imgLogo,
            new Date().getTime() + '-' + this.imgLogo.name
          );
        }

        fd.append('oldLogo', this.oldImgLogo);
        fd.append('oldBackground', this.oldImgBackground);

        if (this.difusionEmprendimiendoTec) {
          fd.append(
            'flyer',
            this.imgFlyer,
            new Date().getTime() + '-' + this.imgFlyer.name
          );
        }

        axios
          .put(
            process.env.VUE_APP_API_ENDPOINT +
              '/modificarIniciativa/' +
              params.id +
              '/' +
              params.token,
            fd
          )
          .then(res => {
            console.log(res);
            this.formStatus = res.data.status;
            this.isUploading = false;
            fetch(
              process.env.VUE_APP_API_ENDPOINT + '/registroNuevaExperiencia',
              {
                method: 'POST',
                body: JSON.stringify({
                  lider: this.nombreResponsable,
                  experiencia: this.nombre,
                  correo: this.contacto,
                  mode: 'modify',
                }),
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );
          });
      } else {
        fd.append(
          'background',
          this.imgBackground,
          new Date().getTime() + '-' + this.imgBackground.name
        );
        fd.append(
          'logo',
          this.imgLogo,
          new Date().getTime() + '-' + this.imgLogo.name
        );

        if (this.difusionEmprendimiendoTec) {
          fd.append(
            'flyer',
            this.imgFlyer,
            new Date().getTime() + '-' + this.imgFlyer.name
          );
        }

        if (this.addNewFromUserMode) {
          const params = this.$route.query;
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT +
                '/addIniciativa/' +
                params.uId +
                '/' +
                params.token,
              fd
            )
            .then(res => {
              this.formStatus = res.data.status;
              this.isUploading = false;
              this.$gtag.event('usuario', {
                event_category: 'registro de experiencia',
                event_label: 'usuario existente, nueva experiencia',
              });
              fetch(
                process.env.VUE_APP_API_ENDPOINT + '/registroNuevaExperiencia',
                {
                  method: 'POST',
                  body: JSON.stringify({
                    lider: this.nombreResponsable,
                    experiencia: this.nombre,
                    correo: this.contacto,
                    mode: 'add',
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
            });
        } else {
          // Registrar nuevo usuario
          fetch(process.env.VUE_APP_API_ENDPOINT + '/signupUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: this.nombreResponsable,
              email: this.contacto,
              cel_contacto: this.celContacto,
            }),
          })
            .then(res => res.json())
            .then(resUser => {
              if (resUser.error) {
                this.formStatus = resUser.error;
                this.isUploading = false;
                return;
              } else {
                const userId = resUser.idUsuario;
                axios
                  .post(
                    process.env.VUE_APP_API_ENDPOINT +
                      '/addIniciativa/' +
                      userId,
                    fd
                  )
                  .then(res => {
                    this.formStatus = res.data.status;
                    this.isUploading = false;
                    this.$gtag.event('usuario', {
                      event_category: 'registro de experiencia',
                      event_label: 'usuario nuevo, nueva experiencia',
                    });
                    fetch(
                      process.env.VUE_APP_API_ENDPOINT +
                        '/registroNuevaExperiencia',
                      {
                        method: 'POST',
                        body: JSON.stringify({
                          lider: this.nombreResponsable,
                          experiencia: this.nombre,
                          correo: this.contacto,
                          mode: 'add',
                        }),
                        headers: {
                          'Content-Type': 'application/json',
                        },
                      }
                    );
                  });
              }
            });
        }
      }
    },
    volver() {
      this.$router.go(-1);
    },
  },
  created() {
    const temas = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getTemas'
    ).then(response => response.json());
    const enfoques = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getEnfoques'
    ).then(response => response.json());
    const categorias = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getCategorias'
    ).then(response => response.json());
    const modalidades = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getModalidades'
    ).then(response => response.json());
    const campus = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getCampus'
    ).then(response => response.json());
    const estados = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getEstados'
    ).then(response => response.json());
    const escuelas = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getEscuelas'
    ).then(response => response.json());
    const etapas = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getEtapas'
    ).then(response => response.json());
    const publicos = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getPublicos'
    ).then(response => response.json());
    const ods = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getODSs'
    ).then(response => response.json());
    const estatuses = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getEstatuses'
    ).then(response => response.json());
    const regiones = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getRegiones'
    ).then(response => response.json());
    const alcancesTRL = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getAlcancesTRL'
    ).then(response => response.json());
    const alcancesGeograficos = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getAlcancesGeograficos'
    ).then(response => response.json());
    const infraestructuras = fetch(
      process.env.VUE_APP_API_ENDPOINT + '/getInfraestructuras'
    ).then(response => response.json());

    Promise.all([
      temas,
      categorias,
      modalidades,
      campus,
      escuelas,
      etapas,
      publicos,
      ods,
      estatuses,
      regiones,
      alcancesTRL,
      alcancesGeograficos,
      infraestructuras,
      enfoques,
      estados,
    ]).then(datos => {
      this.formTemas = datos[0];
      this.formCategorias = datos[1];
      this.formModalidades = datos[2];
      this.formCampus = datos[3];
      this.formEscuelas = datos[4];
      this.formEtapas = datos[5];
      this.formPublicos = datos[6];
      this.formODSs = datos[7];
      this.formEstatuses = datos[8];
      this.formRegiones = datos[9];
      this.formAlcancesTRL = datos[10];
      this.formAlcancesGeograficos = datos[11];
      this.formInfraestructuras = datos[12];
      this.formEnfoques = datos[13];
      this.formEstados = datos[14];

      const params = this.$route.query;
      if (params.id && params.token) {
        this.editMode = true;
        this.isLoading = true;
        fetch(
          process.env.VUE_APP_API_ENDPOINT +
            '/getIniciativaEdit/' +
            params.id +
            '/' +
            params.token
        )
          .then(response => response.json())
          .then(data => {
            this.nombre = data.data.nombre;
            this.tema = data.data.id_tema;
            this.descCorta = data.data.desc_corta;
            this.descLarga = data.data.desc_larga;
            this.nombreResponsable = data.data.nombre_responsable;
            this.campus = data.data.id_campus;
            this.estado = data.data.id_estado;
            this.ciudad = data.data.id_ciudad;

            fetch(
              process.env.VUE_APP_API_ENDPOINT + '/getCiudades/' + this.estado
            )
              .then(response => response.json())
              .then(dataCiudades => {
                this.formCiudades = dataCiudades;
              });

            // Revisar si tiene pagina o registro o los dos
            this.url = data.data.url;
            this.registroLink = data.data.link_registro;
            if (
              this.url !== '' &&
              this.url !== null &&
              this.url !== undefined
            ) {
              this.linksExperiencia.push('pagina');
            }
            if (
              this.registroLink !== '' &&
              this.registroLink !== null &&
              this.registroLink !== undefined
            ) {
              this.linksExperiencia.push('registro');
            }

            let fechaSinFormato = data.data.fecha_inicio;
            fechaSinFormato = fechaSinFormato.split('T')[0];
            fechaSinFormato = fechaSinFormato.split('-');
            let fechaEdit =
              fechaSinFormato[2] +
              '/' +
              fechaSinFormato[1] +
              '/' +
              fechaSinFormato[0];
            this.fechaInicio = data.data.fecha_inicio;
            this.fechaEditMode = fechaEdit;

            if (data.data.id_estatus == 4) {
              let fechaFinSinFormato = data.data.fecha_fin;
              fechaFinSinFormato = fechaFinSinFormato.split('T')[0];
              fechaFinSinFormato = fechaFinSinFormato.split('-');
              let fechaFinEdit =
                fechaFinSinFormato[2] +
                '/' +
                fechaFinSinFormato[1] +
                '/' +
                fechaFinSinFormato[0];
              this.fechaFin = data.data.fecha_fin;
              this.fechaFinEditMode = fechaFinEdit;
            }

            this.duracion = data.data.duracion;
            this.contacto = data.data.contacto;
            this.celContacto = data.data.cel_contacto;
            this.estatus = data.data.id_estatus;
            this.palabrasClave = data.data.palabras_clave;
            this.region = data.data.id_region;
            this.alcanceGeografico = data.data.id_alcance_geografico;

            let temArray = [];
            data.temas.forEach(tema => {
              temArray.push(tema.id_tema);
            });
            this.temas = temArray;
            let catArray = [];
            data.categorias.forEach(categoria => {
              catArray.push(categoria.id_categoria);
            });
            this.categorias = catArray;
            let enfArray = [];
            data.enfoques.forEach(enfoque => {
              enfArray.push(enfoque.id_enfoque);
            });
            this.enfoques = enfArray;
            let escArray = [];
            data.escuelas.forEach(escuela => {
              escArray.push(escuela.id_escuela);
            });
            this.escuelas = escArray;
            let etaArray = [];
            data.etapas.forEach(etapa => {
              etaArray.push(etapa.id_etapa);
            });
            this.etapas = etaArray;
            let pubArray = [];
            data.publicos.forEach(publico => {
              pubArray.push(publico.id_publico);
            });
            this.publico = pubArray;
            let odsArray = [];
            data.odss.forEach(ods => {
              odsArray.push(ods.id_ods);
            });
            this.ods = odsArray;
            let infArray = [];
            data.infraestructuras.forEach(infraestructura => {
              infArray.push(infraestructura.id_infraestructura);
            });
            this.infraestructuras = infArray;
            this.aliados = data.aliados[0].nombre;

            this.modalidad = data.data.id_modalidad;
            this.alcance_trl = data.data.id_alcance_trl_irl;
            this.premio = data.data.id_premio;
            this.precio = data.data.precio;

            (this.imgBackground = data.data.img_background),
              (this.imgLogo = data.data.img_logo),
              (this.oldImgBackground = data.data.img_background),
              (this.oldImgLogo = data.data.img_logo),
              (this.isLoading = false);
          });
      } else if (params.uId && params.token) {
        // Obtener info del usuario y asignarla a las variables (contacto, nombreResponsable, celContacto)
        fetch(
          process.env.VUE_APP_API_ENDPOINT +
            '/getUser/' +
            params.uId +
            '/' +
            params.token
        )
          .then(response => response.json())
          .then(data => {
            this.nombreResponsable = data.nombre;
            this.contacto = data.correo;
            this.celContacto = data.cel_contacto;
            this.addNewFromUserMode = true;
          });
      }
    });
  },
};
</script>

<style scoped>
.download {
  color: #c31bcc;
}

.invalid {
  color: rgb(231, 1, 1);
}

.error-message {
  margin: 15px 0 5px 0;
  color: rgb(231, 1, 1);
}

.field-wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.field-wrapper label b {
  font-size: 1rem;
  font-weight: 500;
}

.field-wrapper input {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
}

.field-wrapper textarea {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
  resize: none;
  outline: none;
}

.field-wrapper input:focus {
  outline: none;
}

#submit-button {
  background-size: 200%;
  background-image: linear-gradient(
    to right,
    rgba(195, 27, 204, 1) 30%,
    rgba(51, 51, 153, 1) 70%
  );
  transition: all ease 1s;
  border: none;
  padding: 1.5rem;
  font-weight: bold;
  font-size: 2rem;
  color: white;
  background-position: center;
}

#submit-button:hover {
  background-size: 250%;
  background-position: left;
  cursor: pointer;
}

#cancel-button,
#preview-button {
  width: 200px;
  color: white;
  background: black;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border: none;
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  cursor: pointer;
  margin-right: 30px;
}

#cancel-button:hover,
#preview-button:hover {
  background: white;
  color: black;
}

.details {
  color: #717171;
  margin: 5px 0;
}

.file-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
  /* margin-bottom: 2.5rem; */
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
}

.file-button:hover {
  cursor: pointer;
  background: #c31bcc;
}

.file-button:hover .file-icon,
.file-button:hover .file-text {
  color: white;
}

.file-icon {
  color: #c31bcc;
  margin-bottom: 0px;
}

.file-text {
  margin: 10px;
  color: black;
}

.file-input {
  display: none;
}

.field-title {
  font-size: 1rem;
  font-weight: 500;
  display: block;
  margin: 5px 0;
}

.bottom-space {
  margin-bottom: 2.5rem;
}

.radio-form {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 250px;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c31bcc;
  border-radius: 8px;
}

.radio-form-3 {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c31bcc;
  border-radius: 8px;
}

.hint {
  color: #c31bcc;
}

.hint-link {
  color: #c31bcc;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.checkbox-form {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c31bcc;
  border-radius: 8px;
}

.checkbox-form-2 {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 320px;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c31bcc;
  border-radius: 8px;
}
.checkbox-form-3 {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 200px;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #c31bcc;
  border-radius: 8px;
}

#ods {
  max-height: 500px;
}

#publico {
  max-height: 250px;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 1rem 1rem;
}

.active {
  background-color: #c31bcc;
  color: white;
  border-radius: 8px;
  outline: none;
}

.active button {
  background-color: #c31bcc;
  color: white;
  border-radius: 8px;
  outline: none;
}

ul {
  list-style: none;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

li {
  width: 25%;
  border: 1px solid #afafaf;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.two-buttons li {
  width: 45%;
}

.trl-buttons li {
  width: 19%;
}

li:hover {
  border: 1px solid #c31bcc;
}

button {
  font: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  padding: 1rem 0;
}

select {
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
  border: 1px solid #c31bcc;
  border-radius: 8px;
  padding: 0.7rem;
  cursor: pointer;
}

select:focus {
  outline: none;
}

select#estado {
  margin-bottom: 0.5rem;
}

.loading {
  color: #333399;
  font-size: 2rem;
  font-weight: 600;
}

#privacy-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@media (max-width: 1000px) {
  #ods {
    max-height: 1000px;
  }
}

.preview-container {
  display: flex;
  justify-content: center;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(141, 141, 141, 0.219);
  z-index: 100;
  width: 100vw;
  height: 100vh;
  /* background: rgba(0, 0, 0, 0.2); */
  backdrop-filter: blur(2px);
}

.backdrop:hover {
  cursor: pointer;
  /* background: rgba(0, 0, 0, 0.15); */
}

.details-container {
  z-index: 101;
  position: absolute;
  top: 100px;
  width: 60%;
  background: whitesmoke;
  border-radius: 13px;
  overflow: visible;
}

@media (max-width: 75rem) {
  .details-container {
    width: 80%;
  }
}

@media (max-width: 50rem) {
  .details-container {
    width: 100%;
    border-radius: 0;
    margin: 0;
    top: 0;
    left: 0;
  }
}
</style>
