<template>
  <div class="filters-container">
    <h3 class="help-title">¿Cómo te gustaría visualizar las experiencias?</h3>

    <div class="dropdown-container">
      <ul>
        <li :class="{ active: filter == 'tipo' }">
          <button type="button" @click="setDynamicRow('tipo')">
            Por tipo de experiencia
          </button>
        </li>
      </ul>
      <ul>
        <li :class="{ active: filter == 'tema' }">
          <button type="button" @click="setDynamicRow('tema')">
            Por tema en específico
          </button>
        </li>
      </ul>
      <ul>
        <li :class="{ active: filter == 'etapa' }">
          <button type="button" @click="setDynamicRow('etapa')">
            Por etapa de emprendimiento
          </button>
        </li>
      </ul>
      <ul>
        <li :class="{ active: filter == 'estado' }">
          <button type="button" @click="setDynamicRow('estado')">
            Por estado donde se vive
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['updateFilter'],
  data() {
    return {
      filter: 'tipo',
    };
  },
  watch: {
    filter: {
      handler(val) {
        this.$emit('updateFilter', val);
      },
      deep: true,
    },
  },
  methods: {
    triggerEventCampus(id) {
      this.$gtag.event('filtros', {
        event_category: 'filtrado de experiencia',
        event_label: 'campus',
        value: id,
      });
    },
    triggerEventCategoria(id) {
      this.$gtag.event('filtros', {
        event_category: 'filtrado de experiencia',
        event_label: 'tipo de experiencia',
        value: id,
      });
    },
    triggerEventTema(id) {
      this.$gtag.event('filtros', {
        event_category: 'filtrado de experiencia',
        event_label: 'tema',
        value: id,
      });
    },
    triggerEventEtapa(id) {
      this.$gtag.event('filtros', {
        event_category: 'filtrado de experiencia',
        event_label: 'etapa',
        value: id,
      });
    },
    setDynamicRow(option) {
      this.filter = option;
    },
  },
};
</script>

<style scoped>
.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters-container {
  margin: 1.25rem 0;
  padding: 0 5.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: relative;
}

.help-title {
  color: #333399;
}

.content-title-container {
  display: flex;
  align-items: center;
}

.content-title {
  margin: 0 0.5rem 0 0;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
}

.content-title-profile {
  color: #c31bbc;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
}

.filters-profile-selector {
  margin-right: 0;
  padding: 0;
}

.dropdown-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0.5 0 0 0;
  display: flex;
  justify-content: space-between;
}

li {
  /* width: 25%; */
  border: 1px solid #afafaf;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

li:hover {
  border: 1px solid #c31bcc;
}

button {
  font: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  padding: 1rem 1rem;
}

.active {
  background-color: #c31bcc;
}
.active button {
  color: white;
  border-radius: 8px;
  outline: none;
}

/* .dropdown {
  background-color: white;
  max-width: 500px;
  margin-top: 0.2rem;
  box-shadow: 2px 2px 5px 2px rgba(27, 26, 26, 0.158);
  border: none;
  outline: none;
  border-radius: 8px;
  padding: 0.7rem;
} */

@media (max-width: 50rem) {
  .dropdown-container {
    gap: 15px;
  }

  .top-row {
    margin-top: 20px;
  }

  .filters-container {
    margin-top: 0.5rem;
    padding: 0 3rem;
  }

  .help-title {
    margin-top: 30px;
    font-size: 1.2rem;
  }

  select {
    width: 100%;
  }

  .content-title {
    font-size: 1.2rem;
  }

  .content-title-profile {
    font-size: 1.2rem;
    display: block;
  }
}

@media (max-width: 30rem) {
  .filters-container {
    padding: 0 1rem;
  }

  .dropdown-container {
    gap: 20px;
  }

  .help-title {
    margin-top: 30px;
    text-align: center;
    font-size: 1rem;
  }
}
</style>
