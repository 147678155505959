<template>
    <div class="page-wrapper">
        <div>
        <img class="navi" src="../../assets/branding/NAVi_RGB-01.png" alt="">
        <ul class="keywords">
            <li class="keyword">Conecta</li>
            <li class="keyword">Explora</li>
            <li class="keyword">Emprende</li>
        </ul>
        </div>
        <div v-if="mode === 'change'">
            <form @submit.prevent="changePassword" class="login-container">
                <h3 class="login-title">Cambio de contraseña</h3>
                <label for="psw"><b>Nueva contraseña</b></label>
                <input type="password" placeholder="Escribe tu contraseña" name="psw" v-model="newPass" required>

                <label for="psw2"><b>Repetir contraseña</b></label>
                <input type="password" placeholder="Repite tu contraseña" name="psw2" v-model="newPassConfirm" required>

                <p class="success-msg" v-if="success">{{success}}</p>
                <p class="error-msg" v-if="error">{{error}}</p>

                <button type="submit">Cambiar contraseña</button>
            </form>
        </div>
        <div v-if="mode === 'request'">
            <form @submit.prevent="requestChangePassword" class="login-container">
                <h3 class="login-title">Cambio de contraseña</h3>
                
                <label for="email"><b>Correo</b></label>
                <input type="email" placeholder="Escribe tu correo" name="email" v-model="email" required>

                <p class="success-msg" v-if="success">{{success}}</p>
                <p class="error-msg" v-if="error">{{error}}</p>
                <router-link to="/experience-form" class="register-link" v-if="showRegisterLink">Registra tu primera experiencia</router-link>


                <button type="submit">Enviar enlace de recuperación</button>
            </form>
        </div>
        <router-link to="/sign-in" class="login-link">Volver a inicio de sesión</router-link>
        <p class="instructions" v-if="mode === 'change'">
            Tu contraseña debe tener por lo menos:
            <ul class="requirements">
                <li>8 caracteres</li>
                <li>Un número</li>
                <li>Una letra mayúscula</li>
            </ul>
        </p>
    </div>    
      
</template>

<script>
export default {
    data() {
        return {
            mode: 'request',
            newPass: '',
            newPassConfirm: '',
            email: '',
            error: '',
            success: '',
            showRegisterLink: false,
        }
    },
    methods: {
        changePassword() {
            if(this.newPass === this.newPassConfirm) {
                //Revisar que la contraseña cumpla con los requisitos
                if(this.newPass.length >= 8 && this.newPass.match(/[A-Z]/) && this.newPass.match(/[a-z]/) && this.newPass.match(/[0-9]/)) {
                    this.loading = true;
                    const email = this.$route.query.e;
                    const hash = this.$route.query.h;

                    fetch(process.env.VUE_APP_API_ENDPOINT + "/resetPassword", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: email,
                            hash: hash,
                            newPass: this.newPass
                        })
                    })
                    .then(response => response.json())
                    .then(response => {
                        this.loading = false;
                        if(response.success) {
                            this.error = null;
                            this.success = response.success;
                        } else {
                            this.error = response.error;
                        }
                    })
                    
                } else {
                    this.error = 'La contraseña debe tener por lo menos 8 caracteres, 1 mayúscula, 1 minúscula y un número.';
                }
            } else {
                this.error = 'Las contraseñas no coinciden';
            }
        },
        requestChangePassword() {
            // Revisar que sea un email valido
            if (this.email.indexOf('@') === -1) {
                this.error = 'Correo inválido'
                return
            }
            fetch(process.env.VUE_APP_API_ENDPOINT + '/userExists', {
                method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  email: this.email,
              }),
            })
              .then(res => res.json())
              .then(data => {
                if (data.exists) {
                    fetch(process.env.VUE_APP_API_ENDPOINT + "/requestChangePassword", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: this.email
                        })
                    })
                    .then(res => res.json())
                    .then(res => {
                        if (res.success) {
                            this.success = 'Se ha enviado un correo a tu cuenta'
                        } else {
                            this.error = res.error
                        }
                    })
                  
                } else {
                    this.error = 'El correo ingresado no tiene una cuenta asociada, favor de registrar al menos una experiencia haciendo click en el siguiente enlace.'
                    this.showRegisterLink = true;
                }
              });
        }
    },
    mounted() {
        if(this.$route.query.e) {
            this.mode = 'change'
        }
    }
}
</script>

<style scoped>
.page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 4rem;
}

.welcome-page__phrase {
  font-size: 6rem;
  font-weight: bold;
  padding-top: 2.5rem;
  padding-bottom: 0.25rem;
  margin: 0.75rem;
}

.keywords{
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  list-style-position: inside;
}

.keyword {
  color: #414141;
  margin: 0 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.keyword::marker {
  color: #c31bcc;
}

.keywords .keyword:first-child {
  list-style-type: none;
}

.navi {
  height: 120px;
  margin-top: 60px;
} 

.login-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-content: center;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    padding: 1rem 3rem;
    border-radius: 13px;
    background: #333399;
    box-shadow: 1px 2px 3px 2px rgba(27, 26, 26, 0.425);
    max-width: 500px;
}

.login-title{
    color: white;
    font-size: 1.8rem;
    text-align: center;
    align-self: center;
}

.login-container label {
    color: white;
    margin-top: 1rem;
}

.login-container input {
    margin-top: 0.3rem;
    border-radius: 3px;
    border: none;
    padding: 0.5rem;
}

.login-container input:focus {
    outline: none;
}

.login-container button {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-radius: 8px;
    padding: 0.7rem;
    color: white;
    background: #c31bcc;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    width: 100%;
}

.login-container button:hover,
.login-container button:active{
    color: #c31bcc;
    background: white;
    cursor: pointer;
}

.button-router{
    width: 100%;
}

.instructions{
    color: #333399;
    text-align: left;
}

.requirements{
    color: #333399;
    text-align: left;
}

.login-link {
    color: #333399;
    text-align: left;
    margin-top: 1rem;
}

.register-link {
    color: white;
    text-align: center;
    margin-top: 1rem;
}

.error-msg,
.success-msg {
  color: white;
  margin-bottom: 0;
  text-align: center;

}

</style>