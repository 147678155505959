<template>
  <div class="page-wrapper">
    <div class="imagen">
      <img class="banner" src="../assets/portada-rectoria.jpg" alt="" />
    </div>
    <div class="texto">
      <div class="main-title">
        <h2>Registra tu experiencia de emprendimiento e innovación</h2>
        <router-link class="button-router" to="experience-form">
          <button>Comenzar a registrar tu experiencia</button>
        </router-link>
      </div>
      <div class="requirements">
        <h3>Requisitos de la experiencia a registrar</h3>
        <p>
          Una experiencia emprendedora es toda aquella actividad que se genera
          con el objetivo de conectar a nuestros emprendedores con diferentes
          recursos para lograr el desarrollo de su emprendimiento y de su perfil
          emprendedor.
        </p>
        <p>
          Las experiencia emprendedoras deben ser eventos liderados o
          co-liderados por colaboradores del Tec y se pueden enfocar en
          diferentes etapas y temáticas de emprendimiento sin importar su
          público. Pueden ser de cualquier extensión de tiempo, siempre y cuando
          estas iniciativas sean o tengan planes de ser recurrentes y/o que
          cuenten con un seguimiento.
        </p>
        <!-- <p>Bootcamps, hackathon, concurso, programas de incubación.</p> -->
      </div>
      <div class="warning">
        <h5>Aviso</h5>
        <p>
          La información que nos proporciones será revisada por los
          administradores de la plataforma y será publicada después de su
          aprobación.
        </p>
        <p>
          Confirmaremos la aprobación de tu registro a través del correo
          electrónico de contacto.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.imagen {
  width: 100%;
  margin-bottom: 40px;
}

.banner {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}

.texto {
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  padding-bottom: 70px;
}

.main-title {
  margin: 0 40px;
}

.main-title h2 {
  font-size: 2.3rem;
  font-weight: bold;
  margin-top: 0;
}

.main-title button {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  color: white;
  background-image: linear-gradient(
    to right,
    rgba(195, 27, 204, 1) 30%,
    rgba(51, 51, 153, 1) 70%
  );
  background-size: 200%;
  background-position: center;
  transition: all ease 0.5s;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.main-title button:hover,
.main-title button:active {
  color: white;
  background-size: 250%;
  background-position: left;
  cursor: pointer;
}

.button-router {
  width: 100%;
}

.requirements {
  margin: 0 40px;
  max-width: 800px;
}

.requirements h3 {
  font-size: 1.5rem;
  margin-top: 0;
}

.warning {
  margin: 0 40px 0 20px;
  border-left: solid 1px #333399;
  padding-left: 1rem;
  width: 25%;
}

.warning h5 {
  font-size: 1rem;
  color: #c31bcc;
  margin-top: 0;
}

.warning p {
  margin-bottom: 0;
  font-size: 0.9rem;
}
</style>