<template>
  <div class="admin-panel__wrapper" v-if="!isLoading">
    <div class="admin-panel__side-bar">
      <ul class="admin-panel__tabs" ref="actions">
        <li class="admin-panel__tab">
          <router-link class="admin-panel__tab-link" to="iniciativas"
            >Mis Experiencias</router-link
          >
        </li>
        <li class="admin-panel__tab">
          <router-link class="admin-panel__tab-link" to="mi-perfil"
            >Mi Perfil</router-link
          >
        </li>
        <li class="admin-panel__tab">
          <router-link class="admin-panel__tab-link" to="constelaciones"
            >Constelaciones de Valor</router-link
          >
        </li>
        <li class="admin-panel__tab">
          <router-link class="admin-panel__tab-link" to="estadisticas"
            >Estadísticas</router-link
          >
        </li>
        <li class="admin-panel__tab" v-if="canUserSeeReport">
          <router-link class="admin-panel__tab-link" to="reporte"
            >Reporte</router-link
          >
        </li>
        <li class="admin-panel__tab">
          <router-link class="admin-panel__tab-link" to="manuales"
            >Manuales de Experiencias</router-link
          >
        </li>
        <!-- <li class="admin-panel__tab" v-if="user.tipo == 1">
          <router-link class="admin-panel__tab-link" to="usuarios"
            >Usuarios</router-link
          >
        </li> -->
        <li class="admin-panel__tab" v-if="user.tipo == 1 || user.tipo == 2">
          <router-link
            class="admin-panel__tab-link"
            to="administrar-iniciativas"
            >Administrar Experiencias</router-link
          >
        </li>
        <!-- <li class="admin-panel__tab">
          <a class="admin-panel__tab-link" to="administrar-experiencias">Cerrar Sesión</a>
        </li> -->
      </ul>
      <a class="admin-panel__homepage-router" @click="logout">
        <div class="admin-panel__homepage-link">
          <span class="material-icons md-36">logout</span>
          <h3 class="admin-panel__homepage-inicio">Cerrar Sesión</h3>
        </div>
      </a>
      <!-- <router-link class="admin-panel__homepage-router" to="/">
       <div class="admin-panel__homepage-link">
       <span class="material-icons md-36">home</span>
       <h3 class="admin-panel__homepage-inicio">Inicio</h3>
       </div>
     </router-link> -->
    </div>
    <div class="admin-panel__content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      user: null,
      actions: null,
      userId: null,
      usersToSeeReport: [129, 93, 150, 105],
    };
  },
  computed: {
    canUserSeeReport() {
      return (
        this.user.tipo == 1 || this.usersToSeeReport.includes(+this.userId)
      );
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
  },
  watch: {
    actions(val) {
      var element = document.getElementById('navBar');
      window.addEventListener('scroll', function() {
        if (this.window.scrollY > element.offsetHeight) {
          val.classList.add('stuck');
        } else {
          val.classList.remove('stuck');
        }
      });
    },
  },
  created() {
    this.isLoading = true;

    const userId = this.$store.getters.userId;
    this.userId = userId;
    const token = this.$store.getters.token;

    fetch(process.env.VUE_APP_API_ENDPOINT + '/getUser/' + userId + '/' + token)
      .then(response => response.json())
      .then(data => {
        this.user = data;
        this.isLoading = false;
      });
  },
  mounted() {
    const interval = setInterval(() => {
      if (this.$refs.actions) {
        this.actions = this.$refs.actions;
        clearInterval(interval);
      }
    }, 50);
  },
};
</script>

<style scoped>
.stuck {
  position: fixed;
  top: 0;
}

body {
  height: 100%;
}

.admin-panel__wrapper {
  flex-grow: 1;
  position: relative;
  display: grid;
  grid-template-columns: 20vw auto;
  height: 100%;
  width: 100%;
}

.admin-panel__side-bar {
  position: relative;
  width: 20vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: start; */
  text-align: left;
  align-items: middle;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 5px 3px rgba(77, 77, 77, 0.226);
  top: 0;
}

.admin-panel__tabs {
  width: inherit;
  margin: 0;
  padding-left: 0;
}

.admin-panel__tab {
  list-style: none;
  width: 100%;
}

.admin-panel__tab-link {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 600;
  color: #414141;
  width: 100%;
  padding: 30px 30px;
  word-wrap: break-word;
}

.admin-panel__tab-link:hover {
  box-shadow: -3px 3px 3px 3px rgba(150, 149, 149, 0.226);
}

.admin-panel__tab-link:active {
  color: #c31bcc;
}

.router-link-active,
.router-link-exact-active {
  color: #c31bcc;
}

.admin-panel__homepage-router {
  color: #414141;
  display: flex;
  justify-content: center;
}

.admin-panel__homepage-link {
  border-top: 1px solid #dbdbdb;
  position: fixed;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.admin-panel__homepage-link:hover {
  color: #c31bcc;
}

.admin-panel__homepage-inicio {
  font-size: 1.5rem;
  margin-left: 10px;
}

.admin-panel__content {
  grid-column-start: 2;
  height: 100%;
  background: whitesmoke;
}

@media (max-width: 1230px) {
  .admin-panel__tab-link {
    font-size: 1.5rem;
    padding: 30px 20px;
  }
}
</style>
