<template>
    <div class="page-wrapper">
        
        <div class="verify-wrapper">
        <p v-if="!isLoading && !verificado">No pudimos verificar tu correo</p>
        <span class="material-icons md-light md-48" v-if="!isLoading && !verificado">sentiment_dissatisfied</span>
        <p v-if="!isLoading && verificado">¡Correo verificado!</p>
        <span class="material-icons md-light md-48" v-if="!isLoading && verificado">check_circle</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: true,
            verificado: null
        }
    },
    created() {
        const email = this.$route.query.e
        const hash = this.$route.query.h
        let hashsinplus = hash.replace('+', '%2B')
        this.isLoading = true;

        fetch(process.env.VUE_APP_API_ENDPOINT + "/verify?e=" + email + "&h=" + hashsinplus)
              .then((res) => res.json())
              .then((response) => {
                this.verificado = response.verificado
                this.isLoading = false;
            });
    }
}
</script>

<style scoped>

.page-wrapper{
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
}

.verify-wrapper{
    max-width: 300px;
    width: 100%;
    min-height: 300px;
    display: grid;
    place-items: center;
    background-color: #333399;
    border-radius: 15px;
}

.verify-wrapper p {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    text-align: center;
}


</style>