<template>
  <div class="page-container">
    <h2>Reporte Experiencias</h2>
    <p>
      En esta pestaña encontrarás el enlace al reporte de datos recopilados por
      la plataforma.
    </p>
    <button class="btn" @click="downloadFile">
      Descargar Excel
    </button>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      isLoading: false,
      json_data: [],
    };
  },
  computed: {
    nombreReporte() {
      return `Reporte de experiencias - ${moment().format('YYYY-MM-DD')}`;
    },
  },
  methods: {
    downloadFile() {
      fetch(
        process.env.VUE_APP_API_ENDPOINT + '/descargarReporteExperiencias'
      ).then(response => {
        response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = `Reporte de experiencias - ${moment().format(
            'YYYY-MM-DD'
          )}.xlsx`;
          a.click();
        });
      });
    },
  },
  created() {
    this.isLoading = true;

    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');

    fetch(
      process.env.VUE_APP_API_ENDPOINT +
        '/getIniciativasReporte/' +
        userId +
        '/' +
        token
    )
      .then(response => response.json())
      .then(data => {
        this.json_data = data;
      });
  },
};
</script>

<style scoped>
.page-container {
  margin: 40px;
  padding: 0;
}

.morado {
  color: #c31bcc;
}

.btn {
  margin-bottom: 20px;
  width: 200px;
  border-radius: 8px;
  padding: 0.7rem 2rem;
  color: white;
  background-image: linear-gradient(
    to right,
    rgba(195, 27, 204, 1) 30%,
    rgba(51, 51, 153, 1) 70%
  );
  background-size: 200%;
  background-position: center;
  transition: all ease 0.5s;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.btn:hover,
.btn:active {
  color: white;
  background-size: 250%;
  background-position: left;
  cursor: pointer;
}
</style>
